import React, { useEffect } from 'react';

import { Box, Button, Text, Heading } from '@chakra-ui/react';

import CustomLink from '../../CustomLink/CustomLink';

const StepCampaignSuccess = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js';
    script.async = false;
    document.body.appendChild(script);
    let JSConfetti = null;
    script.onload = () => {
      if (window.JSConfetti) {
        JSConfetti = window.JSConfetti;
        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti({
          emojiSize: 100,
          confettiNumber: 100,
        });
      }
    };
  }, []);

  return (
    <Box>
      <Heading
        maxWidth="600px"
        m="0 auto 30px auto"
        textAlign="left"
        as="h3"
        size="lg"
      >
        ¡Briefing listo!
      </Heading>

      <Box m={'0 auto'} maxWidth="600px" mb="50px">
        <Text textAlign="left" fontSize="20px" mb={5}>
          ¿Cuáles son los siguientes pasos?
        </Text>
        <Text mb={5}>
          <strong>1️⃣ Revisión del briefing y comunicación.</strong> En las
          próximas 24h, revisaremos tu briefing personalmente. Si tenemos alguna
          pregunta, te la haremos llegar por correo. Si todo está bien,
          comunicaremos y publicaremos el reto a los creadores.
        </Text>
        <Text mb={5}>
          <strong>2️⃣ Los creadores aplicarán a la oferta. </strong>
          Los creadores comenzarán a aplicar y te enviarán de 1 a 3 vídeos de
          muestra para que puedas ver su trabajo.
        </Text>
        <Text mb={5}>
          <strong>3️⃣ Selección del creador.</strong>
          Tendrás que seleccionar qué creador te gusta más y realizar el pago a
          CRUWI (en ese momento el creador no cobra aún, cobrará cuando te envíe
          las creatividades terminadas).
        </Text>
        <Text mb={4}>
          <strong>4️⃣ Envío del producto. </strong>
          Te facilitaremos la dirección y los datos necesarios del creador para
          que puedas enviarle el producto (si es el caso). Una vez realizado el
          envío, podrás compartir en la plataforma el enlace de seguimiento del
          paquete para que le llegue al creador.
        </Text>
        <Text mb={10}>
          <strong>5️⃣ El creador te envía los contenidos. </strong>
          Una vez recibido el producto recibido, el creador dispone de 7 días
          como máximo para elaborar y enviarte el contenido. Lo recibirás con
          una marca de agua. Podrá solicitar una modificación. Una vez que des
          el visto bueno final y apruebes el contenido, pagaremos al creador y
          éste le enviará la pieza final de contenido sin marca de agua.
        </Text>
        <Text>
          Si tienes cualquier consulta, no dudes en agendar una reunión desde el
          panel o escribirnos al correo <u>hello@cruwi.com</u>
        </Text>
        <Box mt={10}>
          <CustomLink to="/dashboard/ugc/briefings">
            <Button size="xs" variant="link">
              Ir a mis briefings
            </Button>
          </CustomLink>
        </Box>
      </Box>
    </Box>
  );
};

export default StepCampaignSuccess;
