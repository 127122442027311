import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Box,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PopoverCloseButton,
  IconButton,
  useToast,
  Step,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  StepIcon,
} from '@chakra-ui/react';

import {
  InfoOutlineIcon,
  ArrowForwardIcon,
  ArrowBackIcon,
} from '@chakra-ui/icons';

import { FiMinusCircle } from 'react-icons/fi';

import CreatableSelect from 'react-select/creatable';

import ChallengePreviewerV2 from '../../ChallengePreviewerV2/ChallengePreviewerV2';
import TextEditor from '../../TextEditor/TextEditor';

import { useGetBrandDataQuery } from '../../../Redux/features/auth/authApiSlice';
import { useCreateChallengeMutation } from '../../../Redux/features/challenges/challengesApiSlice';

import ChallengeCreationImage from '../../../Assets/Images/nuevo-reto-banner.png';

import validateNewMissionForm from '../../../Helpers/validateNewMissionForm';
import { formatDate } from '../../../Utils/date';

const DEFAULT_FIXED_REWARD = 5;

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

const steps = [
  { title: 'Segmentación y presupuesto' },
  { title: 'Detalles de la misión' },
  { title: 'Información de pago' },
];

// Función que calcula las views estimadas
function calculateViews(totalCreators, views) {
  return totalCreators * views;
}

// Función que calcula el número máximo de creadores
function calculateCreators(budget, fixed, variable, paidViews) {
  return (budget * 0.6) / (fixed + variable * ((paidViews - 1000) / 1000));
}

// Función que calcula el CPM final
function calculateCPM(budget, estimatedViews) {
  return ((budget / estimatedViews) * 1000).toPrecision(3);
}

// Function to format numbers
function formatNumber(number) {
  if (number >= 1000 && number < 1000000) {
    return `${Math.round(number / 1000)} mil`; // Thousands
  } else if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}M`; // Millions
  } else {
    return number.toString(); // Handle numbers less than 1000
  }
}

export default function DashboardMissionsNew() {
  // Steps
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  // Router
  const navigate = useNavigate();

  // Get brand data request
  const { data: brandData } = useGetBrandDataQuery();

  // Redux
  const [createChallenge, { isLoading }] = useCreateChallengeMutation();

  // Constantes
  const views = 10000;
  const paidViews = 8500;
  const [variableReward, setVariableReward] = useState(2); // el variable empieza en 2€

  // Basic text form data
  const [formData, setFormData] = useState({
    name: '',
    launchDate: '',
    finalizationDate: '',
    budget: 3000,
  });
  const handleChange = event => {
    const { name, value } = event.target;
    if (name !== 'launchDate' || isNaN(new Date(value))) {
      setFormData(prevData => ({ ...prevData, [name]: value }));
      return;
    }

    let finalizationDate = formData.finalizationDate;
    const defaultFinalizationDate = new Date(value);
    defaultFinalizationDate.setDate(defaultFinalizationDate.getDate() + 30);
    finalizationDate = formatDate(defaultFinalizationDate);
    setFormData(prevData => ({ ...prevData, [name]: value, finalizationDate }));
  };

  // Min age and max age
  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(40);
  const handleMinAgeChange = event => {
    setMinAge(event.target.value);
  };
  const handleMaxAgeChange = event => {
    setMaxAge(event.target.value);
  };

  // Gender
  const [gender, setGender] = useState('all');

  // Requiere compra de producto
  const [requiresProductPurchase, setRequiresProductPurchase] = useState(false);
  const [productPrice, setProductPrice] = useState('0');
  const handleChangeProductPrice = useCallback(valueString => {
    setProductPrice(valueString);
  }, []);

  const additionalProductReward = requiresProductPurchase ? Number(productPrice) : 0;
  const fixedReward = DEFAULT_FIXED_REWARD + additionalProductReward;

  // Description
  const [descriptionHTML, setDescriptionHTML] = useState('');

  // Banner marca
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBannerToSend, setSelectedBannerToSend] = useState(null);
  const handleImageBanner = e => {
    const file = e.target.files[0];
    setSelectedBannerToSend(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedBanner(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Links
  const [links, setLinks] = useState([{ text: '', url: '' }]);
  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };
  const addLink = () => {
    setLinks([...links, { text: '', url: '' }]);
  };
  const removeLink = index => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  // Hashtags
  const [inputHashtagValue, setInputHashtagValue] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const handleKeyDownHashtags = event => {
    if (!inputHashtagValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setHashtags(prev => [...prev, createOption(inputHashtagValue)]);
        setInputHashtagValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Mentions
  const [inputMentionValue, setInputMentionValue] = useState('');
  const [mentions, setMentions] = useState([]);
  const handleKeyDownMentions = event => {
    if (!inputMentionValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setMentions(prev => [...prev, createOption(inputMentionValue)]);
        setInputMentionValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Requirements
  const [inputRequirementValue, setInputRequirementValue] = useState('');
  const [requirements, setRequirements] = useState([]);
  const handleKeyDownRequirements = event => {
    if (!inputRequirementValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setRequirements(prev => [...prev, createOption(inputRequirementValue)]);
        setInputRequirementValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Video uploaded
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoToSend, setSelectedVideoToSend] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleVideoUpload = e => {
    const file = e.target.files[0];
    setSelectedVideoToSend(file);
    if (file) {
      setSelectedVideo(URL.createObjectURL(file));
    }
  };

  // Notifications
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  // Enviamos los datos del nuevo challenge
  const handleSubmit = async event => {
    const isValid = validateNewMissionForm(
      showToast,
      formData,
      descriptionHTML,
      selectedVideo,
      hashtags,
      mentions,
      fixedReward,
      variableReward,
    );

    let segmentation = {};
    if (Number(minAge) !== 18 || Number(maxAge) !== 40) {
      segmentation.minAge = Number(minAge);
      segmentation.maxAge = Number(maxAge);
    }
    if (gender !== 'all') {
      segmentation.gender = gender;
    }

    // Check if segmentation has any properties
    const hasSegmentation = Object.keys(segmentation).length > 0;

    if (isValid) {
      let newChallengeData = {
        isMission: true,
        title: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
        launchDate: formData.launchDate,
        finalizationDate: formData.finalizationDate,
        description: descriptionHTML,
        budget: Number(formData.budget) * 100,
        reward: {
          fixed: fixedReward * 100,
          variable: variableReward * 100,
        },
        hashtags: hashtags
          .map(item => {
            if (typeof item.value === 'string') {
              item.value = item.value.trim();
              if (item.value.startsWith('#')) {
                item.value = item.value.slice(1);
              }
            }
            return item.value;
          })
          .concat('CRUWI'),
        mentions: mentions.map(item => {
          if (typeof item.value === 'string') {
            item.value = item.value.trim();
            if (item.value.startsWith('@')) {
              item.value = item.value.slice(1);
            }
          }
          return item.value;
        }),
        requirements: requirements.map(item => {
          if (typeof item.value === 'string') {
            item.value = item.value.trim();
            item.value =
              item.value.charAt(0).toUpperCase() + item.value.slice(1);
          }
          return item.value;
        }),
        necessaryLinks: links,
      };

      // Add segmentation to newChallengeData only if it has properties
      if (hasSegmentation) {
        console.log('tiene segmentación');
        newChallengeData.segmentation = segmentation;
      }

      const challengeFormData = new FormData();
      challengeFormData.append('data', JSON.stringify(newChallengeData));
      challengeFormData.append('banner', selectedBannerToSend);
      challengeFormData.append('video', selectedVideoToSend);

      try {
        await createChallenge(challengeFormData).unwrap();
        showToast('Se ha creado la misión correctamente', 'success', 3000);
        navigate('/dashboard/missions');
      } catch (error) {
        console.log(error);
        showToast('Hubo un problema creando la misión', 'error');
      }
    }
  };

  // Function to calculate the variable reward dynamic
  useEffect(() => {
    const calculateVariableReward = () => {
      let newVariableReward = 2;

      if (gender !== 'all') {
        newVariableReward += 0.5;
      }

      if (Number(minAge) !== 18 || Number(maxAge) !== 40) {
        newVariableReward += 0.5;
      }

      setVariableReward(newVariableReward);
    };
    calculateVariableReward();
  }, [gender, minAge, maxAge]);

  // Cálculos para el preview con el budget (Calculadora)
  const estimatedCreators = calculateCreators(
    formData.budget,
    fixedReward,
    variableReward,
    paidViews,
  );
  const estimatedViews = calculateViews(estimatedCreators, views);
  const estimatedCPM = calculateCPM(formData.budget, estimatedViews);

  // Format views and numberOfCreators
  const formattedViews = formatNumber(estimatedViews);
  const roundedNumberOfCreators = Math.floor(estimatedCreators);
  const totalCPMCalculated = Number(estimatedCPM);

  // Validate 1 step and go to next
  const goToChallengeDetails = () => {
    if (formData.budget < 300) {
      showToast('El presupuesto mínimo es de 300€', 'error');
      return;
    }
    setActiveStep(1);
  };

  // Validate 1 step and go to next
  const goToPayment = () => {
    const isValid = validateNewMissionForm(
      showToast,
      formData,
      descriptionHTML,
      selectedVideo,
      hashtags,
      mentions,
      3,
      3,
    );

    if (isValid) {
      setActiveStep(2);
    } else {
      return;
    }
  };

  const generateOptions = (min, max) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      if (i === 40) {
        options.push(
          <option key={i} value={i}>
            +{i}
          </option>,
        );
        break;
      }
      options.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return options;
  };

  return (
    <>
      {/* Cabecera página */}
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Lanza un nueva misión
          </Text>
        </Flex>
      </Box>

      {/* Stepper */}
      <Box bg="#e2e8f0" borderBottom="1px solid #e2e8f0" p={5}>
        <Box maxW="900px">
          <Stepper
            color="#1a202c"
            size="md"
            orientation="horizontal"
            index={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator className="stepper_custom_indicator">
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                </Box>
                <StepSeparator className="stepper_custom_separator" />
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>

      {/* Pasos de la creación del reto */}
      {activeStep === 0 && (
        <Box p={5}>
          <Flex maxW="900px">
            {/* Calculadora */}
            <Box width="60%" mr="80px">
              {/* Segmentación */}
              <Box mb={8}>
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Segmentación
                </Text>
                <Text mb={5}>
                  Selecciona los segmentos de creadores que quieres que realicen
                  tu misión. Sólo podrán participar creadores que hagan match
                  con tus condiciones. En misiones sólo pueden participar
                  creadores con mínimo 5000 seguidores.
                </Text>
                <Box>
                  <FormControl mb={2}>
                    <FormLabel>
                      <Flex alignItems="center">
                        <Text marginRight={1}>Género</Text>
                      </Flex>
                    </FormLabel>
                    <Flex>
                      <Box
                        height="40px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mr={2}
                        px={4}
                        py={2}
                        bg={gender === 'all' ? '#e2e8f0' : 'white'}
                        color={gender === 'all' ? 'black' : '#718096'}
                        fontWeight="500"
                        borderRadius="6px"
                        fontSize="12px"
                        border="1px solid #e2e8f0"
                        _hover={{
                          bg: '#e2e8f0',
                          color: 'black',
                        }}
                        cursor="pointer"
                        onClick={() => setGender('all')}
                      >
                        Cualquiera
                      </Box>
                      <Box
                        height="40px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mr={2}
                        px={4}
                        py={2}
                        bg={gender === 'female' ? '#e2e8f0' : 'white'}
                        color={gender === 'female' ? 'black' : '#718096'}
                        fontWeight="500"
                        borderRadius="6px"
                        fontSize="12px"
                        border="1px solid #e2e8f0"
                        _hover={{
                          bg: '#e2e8f0',
                          color: 'black',
                        }}
                        cursor="pointer"
                        onClick={() => setGender('female')}
                      >
                        Chica
                      </Box>
                      <Box
                        height="40px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mr={2}
                        px={4}
                        py={2}
                        bg={gender === 'male' ? '#e2e8f0' : 'white'}
                        color={gender === 'male' ? 'black' : '#718096'}
                        fontWeight="500"
                        borderRadius="6px"
                        fontSize="12px"
                        border="1px solid #e2e8f0"
                        _hover={{
                          bg: '#e2e8f0',
                          color: 'black',
                        }}
                        cursor="pointer"
                        onClick={() => setGender('male')}
                      >
                        Chico
                      </Box>
                    </Flex>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel>
                      <Flex alignItems="center">
                        <Text marginRight={1}>Edad</Text>
                      </Flex>
                    </FormLabel>
                    <Flex alignItems="center">
                      <Select
                        mr={3}
                        value={minAge}
                        onChange={handleMinAgeChange}
                      >
                        {generateOptions(18, 40)}
                      </Select>
                      <Text fontSize="20px" mr={3}>
                        a
                      </Text>
                      <Select value={maxAge} onChange={handleMaxAgeChange}>
                        {generateOptions(18, 40)}
                      </Select>
                    </Flex>
                  </FormControl>
                </Box>
              </Box>

              {/* Necesita producto */}
              <Box mb={8}>
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  ¿Requiere compra de producto?
                </Text>
                <Text mb={5}>
                  Si el creador necesita comprar algún producto para realizar tu
                  misión, marque "sí" e indica el precio de venta. Esta cantidad
                  se incluirá en su recompensa para que pueda ir a un punto de
                  venta y comprarlo.
                </Text>
                <Box>
                  <Flex>
                    <Box
                      height="40px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mr={2}
                      px={4}
                      py={2}
                      bg={
                        requiresProductPurchase ? 'white' : '#e2e8f0'
                      }
                      color={
                        requiresProductPurchase ? '#718096' : 'black'
                      }
                      fontWeight="500"
                      borderRadius="6px"
                      fontSize="12px"
                      border="1px solid #e2e8f0"
                      _hover={{
                        bg: '#e2e8f0',
                        color: 'black',
                      }}
                      cursor="pointer"
                      onClick={() => setRequiresProductPurchase(false)}
                    >
                      No
                    </Box>
                    <Box
                      height="40px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mr={2}
                      px={4}
                      py={2}
                      bg={
                        requiresProductPurchase ? '#e2e8f0' : 'white'
                      }
                      color={
                        requiresProductPurchase ? 'black' : '#718096'
                      }
                      fontWeight="500"
                      borderRadius="6px"
                      fontSize="12px"
                      border="1px solid #e2e8f0"
                      _hover={{
                        bg: '#e2e8f0',
                        color: 'black',
                      }}
                      cursor="pointer"
                      onClick={() => setRequiresProductPurchase(true)}
                    >
                      Sí
                    </Box>
                  </Flex>
                  {requiresProductPurchase && (
                    <FormControl mt={2}>
                      <FormLabel>
                        Introduce el precio en €{' '}
                        <span style={{ fontSize: '12px', fontWeight: 'light' }}>
                          (permite 2 decimales)
                        </span>
                      </FormLabel>
                      <NumberInput
                        value={productPrice}
                        onChange={handleChangeProductPrice}
                        defaultValue={0}
                        max={100}
                        step={0.01}
                        precision={2}
                      >
                        <NumberInputField
                          inputMode="decimal"
                          placeholder="5€"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                </Box>
              </Box>

              {/* Presupuesto */}
              <Box>
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Presupuesto y recompensa para creadores
                </Text>
                <Text mb={5}>
                  Establece el presupuesto total y cuánto pagarás a los
                  creadores. Cuanto mayores sean las recompensas, más atractivo
                  será la misión para nuestros creadores.
                </Text>
                {/* Budget */}
                <FormControl mb={2}>
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Presupuesto total</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Presupuesto
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Este será el presupuesto máximo que se distribuirá
                              según las recompensas que indiques más abajo para
                              pagar a los creadores.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <NumberInput
                    value={formData.budget === 0 ? '' : formData.budget}
                  >
                    <NumberInputField
                      name="budget"
                      value={formData.budget}
                      onChange={handleChange}
                      placeholder="1000€"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                {/* Budgets recomendados */}
                <Flex mb={3}>
                  <Box
                    height="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr={2}
                    px={4}
                    py={2}
                    bg={Number(formData.budget) === 1000 ? '#e2e8f0' : 'white'}
                    color={
                      Number(formData.budget) === 1000 ? 'black' : '#718096'
                    }
                    fontWeight="500"
                    borderRadius="6px"
                    fontSize="12px"
                    border="1px solid #e2e8f0"
                    _hover={{
                      bg: '#e2e8f0',
                      color: 'black',
                    }}
                    cursor="pointer"
                    onClick={() =>
                      setFormData(prevData => ({ ...prevData, budget: 1000 }))
                    }
                  >
                    1.000€
                  </Box>
                  <Box
                    height="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr={2}
                    px={4}
                    py={2}
                    bg={Number(formData.budget) === 3000 ? '#e2e8f0' : 'white'}
                    color={
                      Number(formData.budget) === 3000 ? 'black' : '#718096'
                    }
                    fontWeight="500"
                    borderRadius="6px"
                    fontSize="12px"
                    border="1px solid #e2e8f0"
                    _hover={{
                      bg: '#e2e8f0',
                      color: 'black',
                    }}
                    cursor="pointer"
                    onClick={() =>
                      setFormData(prevData => ({ ...prevData, budget: 3000 }))
                    }
                  >
                    3.000€
                  </Box>
                  <Box
                    height="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mr={2}
                    px={4}
                    py={2}
                    bg={Number(formData.budget) === 5000 ? '#e2e8f0' : 'white'}
                    color={
                      Number(formData.budget) === 5000 ? 'black' : '#718096'
                    }
                    fontWeight="500"
                    borderRadius="6px"
                    fontSize="12px"
                    border="1px solid #e2e8f0"
                    _hover={{
                      bg: '#e2e8f0',
                      color: 'black',
                    }}
                    cursor="pointer"
                    onClick={() =>
                      setFormData(prevData => ({ ...prevData, budget: 5000 }))
                    }
                  >
                    5.000€
                  </Box>
                  <Box
                    height="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={4}
                    py={2}
                    bg={Number(formData.budget) === 10000 ? '#e2e8f0' : 'white'}
                    color={
                      Number(formData.budget) === 10000 ? 'black' : '#718096'
                    }
                    fontWeight="500"
                    borderRadius="6px"
                    fontSize="12px"
                    border="1px solid #e2e8f0"
                    _hover={{
                      bg: '#e2e8f0',
                      color: 'black',
                    }}
                    cursor="pointer"
                    onClick={() =>
                      setFormData(prevData => ({ ...prevData, budget: 10000 }))
                    }
                  >
                    10.000€
                  </Box>
                </Flex>
              </Box>

              {/* Botón siguiente */}
              <Box mt="50px" textAlign="right">
                <Button
                  bg="black"
                  color="white"
                  _hover={{ bg: '#000', opacity: 0.75 }}
                  size="lg"
                  onClick={() => goToChallengeDetails()}
                  rightIcon={<ArrowForwardIcon />}
                >
                  Ir a detalles de la misión
                </Button>
              </Box>
            </Box>

            {/* Resultados */}
            <Box w="40%">
              <Text fontSize="16px" fontWeight="bold" mb={2}>
                Previsualiza los resultados
              </Text>
              <Text mb={5}>
                Los resultados pueden cambiar en función de cómo establezcas tu
                presupuesto y cómo lo distribuyas entre las recompensas.
              </Text>
              {/* Resultados */}
              <Flex flexDir="column" bg="#e2e8f0" p={5} borderRadius="6px">
                <Box
                  bg="white"
                  p={3}
                  textAlign="center"
                  borderRadius="6px"
                  border="1px solid #e2e8f0"
                  mb={5}
                >
                  <Text>Views estimadas (mín)</Text>
                  <Text fontWeight="bold">{formattedViews}</Text>
                </Box>
                <Box
                  bg="white"
                  p={3}
                  textAlign="center"
                  borderRadius="6px"
                  border="1px solid #e2e8f0"
                  mb={5}
                >
                  <Text>Número de creadores (máx)</Text>
                  <Text fontWeight="bold">{roundedNumberOfCreators}</Text>
                </Box>
                <Box
                  bg="white"
                  p={3}
                  textAlign="center"
                  borderRadius="6px"
                  border="1px solid #e2e8f0"
                >
                  <Text>CPM (estimado)</Text>
                  <Text fontWeight="bold">{totalCPMCalculated}</Text>
                </Box>
              </Flex>
              <Text mt={1} fontSize="10px" opacity={0.75}>
                Estos resultados son estimaciones y pueden variar según la
                misión
              </Text>
            </Box>
          </Flex>
        </Box>
      )}

      {activeStep === 1 && (
        <Box p={5} mb={20}>
          <Box>
            <Flex
              flexDirection={['column-reverse', 'column-reverse', 'row', 'row']}
            >
              {/* Campos del reto */}
              <Box
                mb={5}
                mr={['0', '0', '50px', '80px']}
                width={['100%', '100%', '100%', '600px']}
              >
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Detalles de la misión
                </Text>
                <Text fontSize="16px" mb={5}>
                  En los siguientes campos debes explicar a los creadores qué
                  quieres que hagan y qué requisitos mínimos deben incluir en
                  los vídeos. ¡Sé lo más conciso y breve que puedas!
                </Text>

                {/* Name */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Nombre de la misión</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Nombre de la Misión
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Escribe un título para esta misión. Será lo
                              primero que vean los creadores
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="E.g: Nueva McDalena de Chocolate Negro"
                  />
                </FormControl>

                {/* Description rich text */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Descripción</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Breve descripción o briefing
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Escribe de forma concisa lo que quieres que los
                              creadores hagan o muestren en esta misión
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <TextEditor
                    placeholderContent="Ve a tu McDonalds más cercano y grábate probando la nueva McDalena de Chocolate. Asegúrate de mencionar lo jugosa que está y la cantidad de chocolate negro que lleva. ¡Anímate a hacer la misión con tus amigos!"
                    value={descriptionHTML}
                    onChange={setDescriptionHTML}
                  />
                </FormControl>

                {/* Requirements */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Requisitos mínimos (opcional)</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Requisitos del vídeo
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px" mb={2}>
                              Elige qué cosas son imprescindibles para ti o qué
                              no quieres que aparezca
                            </Text>
                            <Text
                              textDecor="underline"
                              fontFamily="DM Sans"
                              fontSize="12px"
                            >
                              Ejemplos:
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Muestra tu cara</strong>
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>No uses voces robóticas</strong>
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Grábate en tu cuarto o similar</strong>
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputRequirementValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setRequirements(newValue)}
                    onInputChange={newValue =>
                      setInputRequirementValue(newValue)
                    }
                    onKeyDown={handleKeyDownRequirements}
                    placeholder="Escribe y pulsa enter. Ej: muestra tu cara"
                    value={requirements}
                  />
                </FormControl>

                {/* Links */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Enlaces (opcional)</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Enlaces
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px" mb={2}>
                              Aquí puedes publicar algunos enlaces que
                              consideres importantes para la consecución de la
                              misión. Enlaces a tu web, a alguna canción..
                            </Text>
                            <Text
                              textDecor="underline"
                              fontFamily="DM Sans"
                              fontSize="12px"
                            >
                              Por ejemplo:
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Texto del enlace:</strong> Web CRUWI
                            </Text>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              <strong>Link URL:</strong> https://www.cruwi.com
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  {links.map((link, index) => (
                    <Flex flexDirection="row" mb={2} key={index} width="100%">
                      <Input
                        mr={3}
                        value={link.text}
                        placeholder={`Texto del enlace ${index + 1}`}
                        onChange={e =>
                          handleLinkChange(index, 'text', e.target.value)
                        }
                      />
                      <Input
                        value={link.url}
                        placeholder={`Link URL ${index + 1}`}
                        onChange={e =>
                          handleLinkChange(index, 'url', e.target.value)
                        }
                      />
                      {index > 0 && (
                        <IconButton
                          aria-label="Remove Link"
                          icon={<FiMinusCircle />}
                          ml={2}
                          onClick={() => removeLink(index)}
                        />
                      )}
                    </Flex>
                  ))}
                  <Button mt={1} onClick={addLink}>
                    Añadir otro
                  </Button>
                </FormControl>

                {/* Release date */}
                <FormControl mb="50px">
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Fecha de lanzamiento deseada</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Fecha de lanzamiento
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Las misiones tendrán una duración de un mes en la
                              plataforma, es decir, estarán 1 mes publicados en
                              la plataforma públicamente para los creadores.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Input
                    name="launchDate"
                    value={formData.launchDate}
                    onChange={handleChange}
                    type="date"
                    placeholder="End Date"
                  />
                </FormControl>

                <FormControl mb="50px">
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Fecha de finalización</Text>
                    </Flex>
                  </FormLabel>
                  <Input
                    name="finalizationDate"
                    value={formData.finalizationDate}
                    onChange={handleChange}
                    type="date"
                    placeholder="End Date"
                  />
                </FormControl>

                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Hashtags y menciones
                </Text>

                <Text fontSize="16px" mb={5}>
                  Escribe los hashtags y menciones que quieres que pongan los
                  creadores en la descripción de sus vídeos. Aprovecha para
                  posicionar los hashtags que más te convengan en tu estrategia.
                </Text>

                {/* Hashtags */}
                <FormControl mb={5}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Hashtags</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Hashtags
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Elige qué hashtags quieres poner como obligatorios
                              en el vídeo que los creadores subirán a TikTok.
                              Los que quieras posicionar. Recomendamos poner 3 o
                              4.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputHashtagValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setHashtags(newValue)}
                    onInputChange={newValue => setInputHashtagValue(newValue)}
                    onKeyDown={handleKeyDownHashtags}
                    placeholder="Escribe y pulsa enter"
                    value={hashtags}
                  />
                </FormControl>

                {/* Mentions */}
                <FormControl mb="50px">
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Menciones</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Mentions
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Elige qué menciones quieres poner como
                              obligatorias en el vídeo que los creadores subirán
                              a TikTok. Normalmente pondrás la mención a tu
                              marca.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <CreatableSelect
                    className="react-select-cruwi"
                    components={components}
                    inputValue={inputMentionValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={newValue => setMentions(newValue)}
                    onInputChange={newValue => setInputMentionValue(newValue)}
                    onKeyDown={handleKeyDownMentions}
                    placeholder="Escribe y pulsa enter"
                    value={mentions}
                  />
                </FormControl>

                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  Material de apoyo (recomendado)
                </Text>
                <Text mb={5}>
                  Puedes subir un banner para la cabecera de tu misión y un
                  vídeo de ejemplo que sirva de inspiración a los creadores.
                  Esto añadirá contexto a la misión y facilitará que los
                  creadores participen.
                </Text>

                {/* Banner */}
                <FormControl mb={8}>
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Imagen de cabecera (400x150)</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Imagen cabecera misión
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Puedes utilizar tu propia imagen para la misión.
                              Puede ser una imagen específica de la misión o una
                              imagen imagen más genérica de su marca. Si no
                              añades ninguna, se pondrá la que ves por defecto.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Box
                    borderWidth={2}
                    borderColor="rgb(226, 232, 240)"
                    borderStyle="dashed"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    position="relative"
                    maxHeight="200px"
                  >
                    {selectedBanner ? (
                      <Box
                        width="400px"
                        height="150px"
                        backgroundPosition="center"
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundImage={selectedBanner}
                      />
                    ) : (
                      <Text>Arrastra y suelta el archivo aquí, o</Text>
                    )}
                    <Input
                      type="file"
                      onChange={handleImageBanner}
                      display="none"
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Text
                        as="span"
                        fontWeight="bold"
                        color="blue.500"
                        cursor="pointer"
                        textDecoration="underline"
                      >
                        haz clic para subir una imagen
                      </Text>
                    </label>
                  </Box>
                </FormControl>

                {/* Video example */}
                <FormControl mb="50px">
                  <FormLabel>
                    <Flex alignItems="center">
                      <Text marginRight={1}>Vídeo de ejemplo</Text>
                      <Popover placement="right">
                        <PopoverTrigger>
                          <InfoOutlineIcon marginTop="2px" width="12px" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            fontFamily="DM Sans"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Vídeo de ejemplo
                          </PopoverHeader>
                          <PopoverBody>
                            <Text fontFamily="DM Sans" fontSize="12px">
                              Sube un vídeo que pueda servir de ejemplo o
                              inspiración para realizar la misión.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </FormLabel>
                  <Box
                    borderWidth={2}
                    borderColor="rgb(226, 232, 240)"
                    borderStyle="dashed"
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                    position="relative"
                    maxHeight="200px"
                  >
                    {selectedVideo ? (
                      <>
                        <Button
                          onClick={() => setIsModalOpen(true)}
                          colorScheme="teal"
                          mt={4}
                          size="sm"
                        >
                          Abrir vídeo de ejemplo
                        </Button>
                        <br />
                      </>
                    ) : (
                      <Text>Arrastra y suelta el archivo aquí, o</Text>
                    )}
                    <Input
                      type="file"
                      display="none"
                      accept=".mp4, .webm, .ogg"
                      onChange={handleVideoUpload}
                      mt={4}
                      id="file-input-video"
                    />

                    <label htmlFor="file-input-video">
                      <Text
                        as="span"
                        fontWeight="bold"
                        color="blue.500"
                        cursor="pointer"
                        textDecoration="underline"
                      >
                        haz clic para subir un vídeo
                      </Text>
                    </label>

                    <Modal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      size="sm"
                      isCentered
                    >
                      <ModalOverlay />
                      <ModalContent color="black">
                        <ModalHeader fontSize="16px">Vídeo ejemplo</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody p={0}>
                          <video
                            controls
                            autoPlay
                            muted
                            style={{ width: '100%', height: '100%' }}
                          >
                            <source src={selectedVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            bg="black"
                            color="white"
                            _hover={{ bg: '#000', opacity: 0.75 }}
                            size="sm"
                            onClick={() => setIsModalOpen(false)}
                          >
                            Cerrar
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Box>
                </FormControl>

                {/* Botones */}
                <Box mt="50px" textAlign="right">
                  <Button
                    colorScheme="gray"
                    mr={4}
                    size="lg"
                    onClick={() => setActiveStep(0)}
                    leftIcon={<ArrowBackIcon />}
                  >
                    Volver al presupuesto
                  </Button>
                  <Button
                    bg="black"
                    color="white"
                    _hover={{ bg: '#000', opacity: 0.75 }}
                    size="lg"
                    onClick={() => goToPayment()}
                    rightIcon={<ArrowForwardIcon />}
                    minW="250px"
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>

              {/* Preview del reto */}
              <Box
                position="sticky"
                top="20px"
                alignSelf="flex-start"
                height="fit-content"
                width={['100%', '100%', '100%', '500px']}
              >
                <Text fontSize="16px" fontWeight="bold" mb={3}>
                  Así verán la misión los creadores
                </Text>
                <ChallengePreviewerV2
                  brand={brandData?.data}
                  challengeName={formData.name}
                  participants={estimatedCreators}
                  fixedReward={fixedReward}
                  description={descriptionHTML}
                  banner={selectedBanner}
                  exampleVideo={selectedVideo}
                  setIsModalOpen={setIsModalOpen}
                  hashtags={hashtags}
                  mentions={mentions}
                  requirements={requirements}
                  links={links}
                  launchDate={formData.launchDate}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      )}

      {activeStep === 2 && (
        <Box p={5} mb={10}>
          {/* Textos e imagen */}
          <Flex>
            <Box mr="50px" width="600px">
              <Text fontSize="20px" fontWeight="bold" mb={5}>
                Información sobre el pago
              </Text>
              <Text fontSize="16px" mb={3}>
                Estás a un paso de crear tu misión. Cuando pulses en <br />{' '}
                'Crear misión', lo revisaremos personalmente durante las
                próximas 24 horas laborables.
              </Text>
              <Text fontSize="16px" mb={3}>
                Si todo está perfecto,{' '}
                <strong>
                  te enviaremos un email con la información para realizar el
                  pago del presupuesto
                </strong>{' '}
                que indicaste.
              </Text>
              <Text fontSize="16px" mb={3}>
                Una vez realizado el pago; publicamos y comunicamos la nueva
                misión a los más de 12.000 creadores registrados en CRUWI.
              </Text>
              <Text fontSize="16px">
                ¡Lo siguiente ya será aprobar o denegar los vídeos que van
                subiendo los creadores!
              </Text>

              {/* Botón */}
              <Box mt="80px" textAlign="right" width="100%">
                <Button
                  colorScheme="gray"
                  mr={4}
                  size="lg"
                  onClick={() => setActiveStep(1)}
                  leftIcon={<ArrowBackIcon />}
                >
                  Volver a los detalles
                </Button>
                <Button
                  bg="black"
                  color="white"
                  _hover={{ bg: '#000', opacity: 0.75 }}
                  size="lg"
                  onClick={() => handleSubmit()}
                  isLoading={isLoading}
                  minW="250px"
                >
                  Crear misión
                </Button>
              </Box>
            </Box>

            <Box
              maxW="300px"
              backgroundPosition="top"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundImage={ChallengeCreationImage}
              height="430px"
              w="100%"
            ></Box>
          </Flex>
        </Box>
      )}
    </>
  );
}
