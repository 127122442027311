import apiSlice from '../../api/apiSlice';

export const challengesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createChallenge: builder.mutation({
      query: (challengeData) => ({
        url: 'brands/challenges',
        method: 'POST',
        body: challengeData,
      }),
      invalidatesTags: ['BrandData', 'Challenges'],
    }),
    getBrandChallenges: builder.query({
      query: ({ isMission }) => {
        const params = new URLSearchParams();
        if (isMission) {
          params.append('isMission', 'true');
        } else {
          params.append('isMission', 'false');
        }
        return `brands/challenges?${params.toString()}`;
      },
      providesTags: ['Challenges'],
    }),
    getBrandChallengesAndVideos: builder.query({
      query: ({ isMission }) => {
        const params = new URLSearchParams();
        if (isMission) {
          params.append('isMission', 'true');
        } else {
          params.append('isMission', 'false');
        }
        return `brands/user-challenges?${params.toString()}`;
      },
      providesTags: ['Challenges'],
    }),
    getBrandChallengeOverview: builder.query({
      query: (challengeId) => `brands/challenges/${challengeId}/overview`,
    }),
    approveUserChallenge: builder.mutation({
      query: ({ userId, challengeId, feedback }) => ({
        url: `brands/users/${userId}/challenges/${challengeId}/approve`,
        method: 'POST',
        body: feedback
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    declineUserChallenge: builder.mutation({
      query: ({ userId, challengeId, feedback }) => ({
        url: `brands/users/${userId}/challenges/${challengeId}/decline`,
        method: 'POST',
        body: feedback
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    markAsFavouriteUserChallenge: builder.mutation({
      query: ({ userId, challengeId }) => ({
        url: `brands/users/${userId}/challenges/${challengeId}/mark-as-favourite`,
        method: 'POST',
      }),
      invalidatesTags: ['UserData', 'Challenges'],
    }),
    unMarkAsFavouriteUserChallenge: builder.mutation({
      query: ({ userId, challengeId }) => ({
        url: `brands/users/${userId}/challenges/${challengeId}/unmark-as-favourite`,
        method: 'POST',
      }),
      invalidatesTags: ['Challenges'],
    }),
    banUserForFutureChallenges: builder.mutation({
      query: ({ userId }) => ({
        url: `brands/users/${userId}/ban`,
        method: 'POST',
      }),
      invalidatesTags: ['Challenges'],
    }),
    requestSparkCodeUserChallenge: builder.mutation({
      query: ({ userId, challengeId }) => ({
        url: `brands/users/${userId}/challenges/${challengeId}/request-spark-code`,
        method: 'POST',
      }),
      invalidatesTags: ['Challenges'],
    }),
    finishChallenge: builder.mutation({
      query: ({ challengeId }) => ({
        url: `brands/challenges/${challengeId}/finish`,
        method: 'POST',
      }),
      invalidatesTags: ['Challenges'],
    }),
    getBrandMissionsAndVideos: builder.query({
      query: () => 'brands/user-challenges?isMission=true',
      providesTags: ["Challenges"],
    }),
  }),
});

export const {
  useCreateChallengeMutation,
  useGetBrandChallengesQuery,
  useGetBrandChallengesAndVideosQuery,
  useGetBrandChallengeOverviewQuery,
  useApproveUserChallengeMutation,
  useDeclineUserChallengeMutation,
  useMarkAsFavouriteUserChallengeMutation,
  useBanUserForFutureChallengesMutation,
  useUnMarkAsFavouriteUserChallengeMutation,
  useRequestSparkCodeUserChallengeMutation,
  useFinishChallengeMutation,

  useGetBrandMissionsAndVideosQuery
} = challengesApiSlice;
