import React from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';

// Custom Checkbox para las categorías
const CustomCheckbox = ({ value, isChecked, onChange }) => {
  return (
    <Box
      as="button"
      onClick={() => onChange(value)}
      borderWidth="1px"
      borderColor={isChecked ? 'black' : '#e2e8f0'}
      borderRadius="lg"
      bg={isChecked ? 'black' : 'white'}
      color={isChecked ? 'white' : 'black'}
      boxShadow={isChecked ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : ''}
      p={2}
      m={1}
    >
      {value}
    </Box>
  );
};

const StepCampaignData = ({ formData, setFormData, errors }) => {
  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const options = [
    'Bienestar',
    'Alimentación',
    'Bebidas',
    'Moda',
    'Belleza',
    'Hogar',
    'Fitness',
    'Mascotas',
    'Tecnología',
    'Viajes',
    'Otro',
  ];

  const handleCheckboxChange = value => {
    const isAlreadySelected = formData.productCategories.includes(value);

    const newProductCategories = isAlreadySelected
      ? formData.productCategories.filter(ageRange => ageRange !== value)
      : [...formData.productCategories, value];

    setFormData({ ...formData, productCategories: newProductCategories });
  };

  return (
    <Box>
      <Heading textAlign="center" as="h3" size="lg" mb={4}>
        Detalles del briefing
      </Heading>

      <Box m={'0 auto'} maxWidth="600px">
        {/* NOMBRE DE LA CAMPAÑA */}
        <FormControl isInvalid={errors.campaignName} mb={4}>
          <FormLabel htmlFor="campaignName">Título</FormLabel>
          <Input
            id="campaignName"
            type="text"
            value={formData.campaignName || ''}
            onChange={e => handleChange('campaignName', e.target.value)}
            placeholder="Unboxing nuevas zapatillas"
          />
          <FormErrorMessage>{errors.campaignName}</FormErrorMessage>
        </FormControl>

        {/* LINK AL PRODUCTO */}
        <FormControl isInvalid={errors.productLink} mb={4}>
          <FormLabel htmlFor="productLink">Enlace del producto</FormLabel>
          <Input
            id="productLink"
            type="text"
            value={formData.productLink || ''}
            onChange={e => handleChange('productLink', e.target.value)}
            placeholder="https://www.zapasguapas.com"
          />
          <FormErrorMessage>{errors.productLink}</FormErrorMessage>
        </FormControl>

        {/* VALOR DEL PRODUCTO */}
        <FormControl isInvalid={errors.productValue} mb={4}>
          <FormLabel htmlFor="productValue">Valor del producto</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              €
            </InputLeftElement>
            <Input
              id="productValue"
              type="text"
              defaultValue={0}
              value={formData.productValue || ''}
              onChange={e =>
                handleChange('productValue', Number(e.target.value))
              }
              placeholder="100"
            />
          </InputGroup>

          <FormErrorMessage>{errors.productValue}</FormErrorMessage>
        </FormControl>

        {/* CATEGORÍA DEL PRODUCTO */}
        <Box mb={5}>
          <Text fontWeight="medium" fontSize="16px" mb={2}>
            Categoría del producto
          </Text>
          <Box>
            {options.map(range => (
              <CustomCheckbox
                key={range}
                value={range}
                isChecked={formData.productCategories.includes(range)}
                onChange={handleCheckboxChange}
              />
            ))}
          </Box>
        </Box>

        {errors.productCategories && (
          <Text color="red" textAlign="center" fontSize="14px">
            {errors.productCategories}
          </Text>
        )}
      </Box>
    </Box>
  );
};

StepCampaignData.validate = data => {
  const errors = {};
  if (!data.campaignName) {
    errors.campaignName = 'Debes poner un título a la campaña';
  }
  if (!data.productLink) {
    errors.productLink = 'El enlace del producto es obligatorio';
  }
  if (data.productCategories.length === 0) {
    errors.productCategories = 'Selecciona al menos una categoría';
  }
  return errors;
};

export default StepCampaignData;
