import React from 'react';

import { Box, Flex, Text, Button } from '@chakra-ui/react';

import CustomLink from '../../CustomLink/CustomLink';
import BrandBriefingItem from '../../BrandBriefingItem/BrandBriefingItem';
import EmptyBriefings from '../../EmptyBriefings/EmptyBriefings';

import { useGetBrandBriefingsQuery } from '../../../Redux/features/briefings/briefingsApiSlice';
import { useGetBrandCollabsQuery } from '../../../Redux/features/collabs/collabsApiSlice';

export default function DashboardUGC() {
  const { data: brandBriefings, isLoadingBrandBriefings } =
    useGetBrandBriefingsQuery();
  const { data: brandCollabs, isLoading: isLoadingBrandCollabs } =
    useGetBrandCollabsQuery();

  if (isLoadingBrandBriefings || isLoadingBrandCollabs) {
    return <p>Loading Briefings..</p>;
  }

  // Si la marca no tiene briefings es que acaba de entrar o no tiene un plan aún, le mostramos un CTA
  if (brandBriefings?.data.length === 0) {
    return (
      <>
        <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="bold" fontSize="24px">
              Briefings UGC
            </Text>
            <CustomLink to="/dashboard/ugc/new">
              <Button
                bg="black"
                color="white"
                _hover={{ bg: '#000', opacity: 0.75 }}
                size="sm"
              >
                Crear briefing
              </Button>
            </CustomLink>
          </Flex>
        </Box>
        <EmptyBriefings />
      </>
    );
  }

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Briefings UGC
          </Text>
          <CustomLink to="/dashboard/ugc/new">
            <Button
              bg="black"
              color="white"
              _hover={{ bg: '#000', opacity: 0.75 }}
              size="sm"
            >
              Crear briefing
            </Button>
          </CustomLink>
        </Flex>
      </Box>
      {/* AQUÍ LOS BRIEFINGS */}
      <Box p="5">
        {brandBriefings &&
          brandBriefings?.data.length > 0 &&
          brandBriefings?.data.map(briefing => (
            <BrandBriefingItem
              key={briefing._id}
              briefingData={briefing}
              collabData={brandCollabs}
            />
          ))}
      </Box>
    </>
  );
}
