import React from 'react';

import { Flex, Button } from '@chakra-ui/react';

const Paginator = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
      onPageChange(newPage);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          size="sm"
          mr={1}
          bg={i === currentPage ? 'black' : '#edf2f7'}
          color={i === currentPage ? 'white' : 'black'}
          onClick={() => handlePageChange(i)}
          _hover={{ bg: 'black', color: 'white' }}
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <Flex mt={4}>
      {currentPage > 1 && (
        <Button
          _hover={{ bg: 'black', color: 'white' }}
          mr={2}
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </Button>
      )}

      {renderPaginationButtons()}

      {currentPage < totalPages && (
        <Button
          _hover={{ bg: 'black', color: 'white' }}
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Button>
      )}
    </Flex>
  );
};

export default Paginator;
