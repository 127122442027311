export default function getPlanCounts(plans, availablePlans) {
  // Create an object to store the counts for each plan
  const counts = {};

  if (!Array.isArray(plans)) return;

  // Initialize the counts object with each plan's name and a count of 0
  plans.forEach(plan => {
    counts[plan._id] = {
      name: plan.name,
      count: 0,
      hasCredit: false, // Initialize hasCredit as false
    };
  });

  // Increment the count for each plan the brand has purchased
  availablePlans.forEach(planId => {
    if (counts[planId]) {
      counts[planId].count++;
      counts[planId].hasCredit = true; // Set hasCredit to true if the count is incremented
    }
  });

  // Convert the counts object back into an array of objects
  const result = Object.values(counts);

  return result;
}
