import React from 'react';

import {
  Box,
  Flex,
  Text,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

import { HamburgerIcon } from '@chakra-ui/icons';

import { FiUsers, FiFileText, FiUserCheck } from 'react-icons/fi';

import BriefingIcon from '../../Assets/Images/briefing.png';
import ViewBriefingModal from '../ViewBriefingModal/ViewBriefingModal';

const getTextFromStatus = status => {
  let finalText = 'Waiting';
  if (status === 'waiting') {
    finalText = 'Waiting for applicants';
  }
  if (status === 'selection') {
    finalText = 'Select the candidate';
  }
  return finalText;
};

export default function BrandBriefingItem({ briefingData, collabData }) {
  // Recibimos los datos del briefing
  const { _id: briefingId, title, status, applicants } = briefingData;

  // Recibimos todas las collabs de la marca
  const { data: collabs = [] } = collabData;

  // Para enviar al usuario
  const navigate = useNavigate();

  // Modal de ver datos del briefing
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Función para mostrar el briefing completo en un modal
  const handleOpenViewBriefingModal = () => {
    onOpen();
  };

  // Función que lleva a ver los participantes del briefing
  const handleBriefingClick = () => {
    navigate(`/dashboard/ugc/briefings/${briefingId}`, {
      state: { applicants, title },
    });
  };

  // Function to check if the briefing has a collaboration
  const hasCollab = collabs.some(collab =>
    collab.briefing.some(b => b._id === briefingId)
  );

  // Buscamos las collabs de este briefing
  const matchingCollabs = collabs.filter(collab =>
    collab.briefing.some(b => b._id === briefingId)
  );

  // Si tiene una collab o varias
  // modificar esto a > 2 para hacer pruebas con solo 1
  const hasMultipleCollabs = matchingCollabs.length > 1;

  // Si es una sola, cogemos el id de la primera collab siempre (lógicamente) (nos aseguramos que haya al menos 1)
  const mainCollabId =
    matchingCollabs.length > 0 ? matchingCollabs[0]._id : null;

  // Función que llevan a las collabs del briefing o a la collab si solo es una
  const handleBriefingApplicantClick = () => {
    if (hasMultipleCollabs) {
      navigate(`/dashboard/ugc/briefings/${briefingId}/collabs`);
    } else {
      navigate(
        `/dashboard/ugc/briefings/${briefingId}/collabs/${mainCollabId}`
      );
    }
  };

  return (
    <Flex
      border="1px solid #e2e8f0"
      p={5}
      borderRadius="8px"
      justifyContent="space-between"
      _hover={{
        background: '#e2e8f03d',
        cursor: 'pointer',
      }}
      mb={3}
    >
      <Flex>
        <Box
          mr={5}
          height="45px"
          width="45px"
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundImage={BriefingIcon}
        ></Box>
        <Flex alignItems="center">
          <Text fontSize="16px" fontWeight="bold">
            {title}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Flex alignItems="center" flexDirection="column" mr="20px">
          <Text color="#374957" mb="2px" fontSize="12px">
            Estado
          </Text>
          <Tooltip label={getTextFromStatus(status)}>
            <Badge>{status}</Badge>
          </Tooltip>
        </Flex>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={handleOpenViewBriefingModal}
                icon={<FiFileText />}
              >
                Ver briefing
              </MenuItem>
              {/* if there are applicants */}
              {applicants.length > 0 && (
                <MenuItem onClick={handleBriefingClick} icon={<FiUsers />}>
                  Ver candidatos
                </MenuItem>
              )}
              {/* if applicant is already selected */}
              {hasCollab && (
                <MenuItem
                  onClick={handleBriefingApplicantClick}
                  icon={<FiUserCheck />}
                >
                  Tu/s elegido/s
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      <ViewBriefingModal
        isOpen={isOpen}
        onClose={onClose}
        briefingData={briefingData}
      />
    </Flex>
  );
}
