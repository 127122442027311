export const reasons = [
  {
    id: 1,
    text: 'Poor sound',
  },
  {
    id: 2,
    text: 'Poor video quality',
  },
  {
    id: 3,
    text: 'Bad light',
  },
  {
    id: 4,
    text: 'Does not show its face',
  },
  {
    id: 5,
    text: 'We want to hear your real voice',
  },
  {
    id: 6,
    text: 'Photos are not allowed',
  },
  {
    id: 7,
    text: 'Does not follow the guidelines of the example video',
  },
  {
    id: 8,
    text: 'Video not available',
  },
];