import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  FormControl,
  Textarea,
  FormLabel,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';

import { setBrandData } from '../../Redux/features/auth/authSlice';
import { useUpdateMutation } from '../../Redux/features/auth/authApiSlice';

export default function BrandForm({ brandData }) {
  // Redux
  const dispatch = useDispatch();
  const [updateBrand, { isLoading }] = useUpdateMutation();

  // Brand data
  const [brandName, setBrandName] = useState(brandData.name || '');
  const handleChangeBrandName = event => setBrandName(event.target.value);

  const [link, setLink] = useState(brandData.link || '');
  const handleChangeLink = event => setLink(event.target.value);

  const [brandDescription, setBrandDescription] = useState(
    brandData.description || ''
  );
  const handleChangeBrandDescription = e => {
    const inputValue = e.target.value;
    setBrandDescription(inputValue);
  };

  const [tiktokHandle, setTiktokHandle] = useState(
    brandData.tiktokHandle || ''
  );
  const handleChangeTiktokHandle = event => setTiktokHandle(event.target.value);

  // Logo marca
  const [selectedLogo, setSelectedLogo] = useState(brandData.logoUrl || null);
  const [selectedLogoToSend, setSelectedLogoToSend] = useState(null);
  const handleChangeLogo = e => {
    const file = e.target.files[0];
    setSelectedLogoToSend(file);
    if (file) {
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Envio de los datos
  const handleSubmitUpdate = async () => {
    try {
      const brandData = new FormData();
      brandData.append('name', brandName);
      brandData.append('link', link);
      brandData.append('tiktokHandle', tiktokHandle);
      brandData.append('description', brandDescription);
      brandData.append('image', selectedLogoToSend);

      const brandUpdatedData = await updateBrand(brandData).unwrap();
      dispatch(setBrandData(brandUpdatedData));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box maxWidth="500px">
        <FormControl mb={4}>
          <FormLabel mb={1}>Nombre</FormLabel>
          <Input
            onChange={handleChangeBrandName}
            value={brandName}
            name="name"
            placeholder="CRUWI"
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel mb={1}>Enlace</FormLabel>
          <Input
            onChange={handleChangeLink}
            value={link}
            name="link"
            placeholder="https://www.cruwi.com"
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel mb={1}>Breve descripción</FormLabel>
          <Textarea
            value={brandDescription}
            onChange={handleChangeBrandDescription}
            placeholder="Brief description of the brand"
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel mb={1}>Cuenta de TikTok</FormLabel>
          <Input
            onChange={handleChangeTiktokHandle}
            value={tiktokHandle}
            name="tiktokHandle"
            placeholder="@cruwi.com"
          />
        </FormControl>

        <FormControl mb={8}>
          <FormLabel mb={1}>Logo (500x500 con espacio)</FormLabel>
          <Box
            borderWidth={2}
            borderColor="rgb(226, 232, 240)"
            borderStyle="dashed"
            borderRadius="md"
            p={4}
            textAlign="center"
            position="relative"
            maxHeight="200px"
          >
            {selectedLogo ? (
              <Box
                width="50px"
                height="50px"
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundImage={selectedLogo}
              />
            ) : (
              <Text>Drag & drop files here, or</Text>
            )}
            <Input
              type="file"
              onChange={handleChangeLogo}
              display="none"
              id="file-input"
            />
            <label htmlFor="file-input">
              <Text
                as="span"
                fontWeight="bold"
                color="blue.500"
                cursor="pointer"
                textDecoration="underline"
              >
                click to select other picture
              </Text>
            </label>
          </Box>
        </FormControl>

        <Button
          isLoading={isLoading}
          onClick={handleSubmitUpdate}
          color="white"
          bg="black"
          _hover={{ bg: 'black', color: 'white' }}
          size="sm"
        >
          Guardar cambios
        </Button>

      </Box>
    </>
  );
}
