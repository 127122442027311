import {
  Box,
  Flex,
  Text,
  Button,
  Grid,
  GridItem,
  VStack,
  HStack,
  AspectRatio,
  Image,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import {
  FaEye,
  FaHeart,
  FaShareAlt,
  FaComment,
  FaThumbtack,
} from 'react-icons/fa';

import formatNumbersStandard from '../../../Helpers/formatNumbersStandard';
import formatCompactNumber from '../../../Helpers/formatNumbers';

import { useGetReportByIdQuery } from '../../../Redux/features/reports/reportsApiSlice';

import LogoLoader from '../../LogoLoader/LogoLoader';

import './DashboardReport.css';

const IPhoneVideo = ({ src, metrics }) => (
  <Flex direction="column" alignItems="center" width="100%">
    <Box
      position="relative"
      width="100%"
      maxWidth="250px"
      height={{ base: '400px', md: '500px' }}
      borderRadius="3rem"
      borderWidth="14px"
      borderColor="gray.800"
      bg="gray.800"
      boxShadow="xl"
      mx="auto"
      my={4}
    >
      <Box
        position="absolute"
        top="0"
        left="50%"
        transform="translateX(-50%)"
        width="33%"
        height="20px"
        bg="gray.800"
        borderBottomRadius="3xl"
      />
      <Box
        height="100%"
        width="100%"
        bg="white"
        borderRadius="2.5rem"
        overflow="hidden"
      >
        <AspectRatio ratio={9 / 19.5} height="100%">
          <video controls style={{ objectFit: 'cover' }}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </AspectRatio>
      </Box>
      <Box
        position="absolute"
        bottom="4px"
        left="50%"
        transform="translateX(-50%)"
        width="20%"
        height="4px"
        bg="gray.800"
        borderRadius="full"
      />
    </Box>
    <VStack spacing={2} mt={4} alignItems="flex-start" width="100%">
      <HStack>
        <FaEye />
        <Text>{metrics.views} views</Text>
      </HStack>
      <HStack>
        <FaHeart />
        <Text>{metrics.likes} likes</Text>
      </HStack>
      <HStack>
        <FaShareAlt />
        <Text>{metrics.shares} shares</Text>
      </HStack>
      <HStack>
        <FaComment />
        <Text>{metrics.comments} comments</Text>
      </HStack>
    </VStack>
    <Box mt={5} width="100%">
      <Button
        onClick={() => {
          window.open(metrics.tiktokVideoUrl);
        }}
        w="100%"
      >
        Ver en TikTok
      </Button>
    </Box>
  </Flex>
);

const CommentCard = ({ src, top, left, zIndex }) => (
  <Box
    position="absolute"
    top={top}
    left={left}
    zIndex={zIndex}
    width={{ base: '90%', md: '100%' }}
    maxWidth={{ base: '300px', md: '600px' }}
    boxShadow="lg"
  >
    <Box position="relative">
      <Image src={src} alt="Comment screenshot" width="100%" height="auto" />
      <Box
        position="absolute"
        top={1}
        right={1}
        color="red.500"
        fontSize={{ base: '14px', md: '18px' }}
      >
        <FaThumbtack />
      </Box>
    </Box>
  </Box>
);

export default function DashboardReport() {
  const { reportId } = useParams();
  // Pedimos datos de los briefings que hay disponibles
  const {
    data: reportData,
    isLoading: isLoadingReportData,
    isError: isErrorReportData,
  } = useGetReportByIdQuery(reportId);

  if (isLoadingReportData) {
    return <LogoLoader />;
  }

  if (isErrorReportData) {
    return (
      <Flex minH="100dvh" justifyContent="center" alignItems="center">
        <Text fontSize="20px" fontWeight="bold">
          Este reporte no existe :(
        </Text>
      </Flex>
    );
  }

  // Estimaciones
  const estimatedMetrics = {
    videos: reportData?.data?.estimatedResults?.videos,
    views: formatCompactNumber(reportData?.data?.estimatedResults?.views),
    budget: `${formatNumbersStandard(
      reportData?.data?.estimatedResults?.budget / 100
    )} €`,
  };

  // Métricas
  const metrics = [
    {
      label: 'Vídeos conseguidos',
      value: reportData?.data?.overview?.totalApprovedVideos,
    },
    {
      label: 'Impactos totales',
      value: formatCompactNumber(reportData?.data?.overview?.totalViews),
    },
    {
      label: 'Presupuesto invertido',
      value: `${formatNumbersStandard(
        reportData?.data?.challenge?.isFerran
          ? reportData?.data?.estimatedResults?.budget / 100
          : reportData?.data?.overview?.totalReward / 100
      )} €`,
    },
    {
      label: 'CPM',
      value: `${formatCompactNumber(
        reportData?.data?.overview?.totalVariableReward /
          100 /
          (reportData?.data?.overview?.totalViews / 1000)
      )} €`,
    },
    {
      label: 'Seguidores alcanzados',
      value: formatCompactNumber(reportData?.data?.overview?.totalFollowers),
    },
    {
      label: 'Likes totales',
      value: formatCompactNumber(reportData?.data?.overview?.totalLikes),
    },
    {
      label: 'Comentarios totales',
      value: formatCompactNumber(reportData?.data?.overview?.totalComments),
    },
    {
      label: 'Compartidos totales',
      value: formatCompactNumber(reportData?.data?.overview?.totalShares),
    },
  ];

  // Vídeos
  const topVideos = [
    {
      src: reportData?.data?.userVideos[0]?.video?.downloadUrl,
      views: formatCompactNumber(
        reportData?.data?.userVideos[0]?.video?.viewTotalCount
      ),
      likes: formatCompactNumber(
        reportData?.data?.userVideos[0]?.video?.likeTotalCount
      ),
      comments: formatCompactNumber(
        reportData?.data?.userVideos[0]?.video?.commentTotalCount
      ),
      shares: formatCompactNumber(
        reportData?.data?.userVideos[0]?.video?.shareTotalCount
      ),
      tiktokVideoUrl: reportData?.data?.userVideos[0]?.video?.shareUrl,
    },
    {
      src: reportData?.data?.userVideos[1]?.video?.downloadUrl,
      views: formatCompactNumber(
        reportData?.data?.userVideos[1]?.video?.viewTotalCount
      ),
      likes: formatCompactNumber(
        reportData?.data?.userVideos[1]?.video?.likeTotalCount
      ),
      comments: formatCompactNumber(
        reportData?.data?.userVideos[1]?.video?.commentTotalCount
      ),
      shares: formatCompactNumber(
        reportData?.data?.userVideos[1]?.video?.shareTotalCount
      ),
      tiktokVideoUrl: reportData?.data?.userVideos[1]?.video?.shareUrl,
    },
    {
      src: reportData?.data?.userVideos[2]?.video?.downloadUrl,
      views: formatCompactNumber(
        reportData?.data?.userVideos[2]?.video?.viewTotalCount
      ),
      likes: formatCompactNumber(
        reportData?.data?.userVideos[2]?.video?.likeTotalCount
      ),
      comments: formatCompactNumber(
        reportData?.data?.userVideos[2]?.video?.commentTotalCount
      ),
      shares: formatCompactNumber(
        reportData?.data?.userVideos[2]?.video?.shareTotalCount
      ),
      tiktokVideoUrl: reportData?.data?.userVideos[2]?.video?.shareUrl,
    },
  ];

  // Comentarios destacados
  const commentCards = [
    {
      src: reportData?.data?.commentImagesUrls[0],
      top: '0px',
      left: '10%',
      zIndex: 3,
    },
    {
      src: reportData?.data?.commentImagesUrls[1],
      top: '120px',
      left: '30%',
      zIndex: 2,
    },
    {
      src: reportData?.data?.commentImagesUrls[2],
      top: '220px',
      left: '10%',
      zIndex: 1,
    },
  ];

  return (
    <Box
      boxShadow="2px 1px 10px rgba(0, 0, 0, 0.1)"
      minH="100dvh"
      margin="0 auto"
      maxW="990px"
      bg="#fffff2"
      marginBottom="30px"
    >
      {/* Header */}
      <Flex
        position="relative"
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundImage={reportData?.data?.challenge?.bannerUrl}
        alignContent="center"
        justifyContent={['center', 'center', 'left']}
        width="100%"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
        p={{ base: '20px', md: '40px' }}
      >
        <Flex
          position="relative"
          zIndex={2}
          maxW="1000"
          alignItems="center"
          flexDirection={{ base: 'column', md: 'row' }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Box
            borderRadius="50%"
            height={{ base: '80px', md: '100px' }}
            width={{ base: '80px', md: '100px' }}
            mr={{ base: 0, md: '50px' }}
            mb={{ base: '20px', md: 0 }}
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundImage={reportData?.data?.challenge?.brand?.logoUrl}
          ></Box>
          <Box>
            <Text
              lineHeight={1}
              textShadow="1px 1px 20px #00000061"
              fontSize={{ base: '14px', md: '16px' }}
              color="#ffffffb0"
            >
              Reporte del reto
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: '24px', md: '30px' }}
              textShadow="1px 1px 20px #00000061"
              color="white"
            >
              {reportData?.data?.challenge?.title}
            </Text>
          </Box>
        </Flex>
      </Flex>

      {/* Descripción del reto */}
      <Box p="40px">
        <Box mb="10px">
          <Text
            fontFamily="DM Sans"
            fontWeight="bold"
            fontSize="30px"
            letterSpacing={-1}
          >
            Descripción del reto
          </Text>
        </Box>
        <Box className="description-html">
          <Box
            fontFamily="DM Sans"
            fontSize="16px"
            dangerouslySetInnerHTML={{
              __html: reportData?.data?.challenge?.description,
            }}
          />
        </Box>
      </Box>

      {/* Estimaciones pre-reto */}
      <Box p="0px 40px 60px 40px">
        <Box mb="20px">
          <Text
            fontFamily="DM Sans"
            fontWeight="bold"
            fontSize={{ base: '24px', md: '30px' }}
            letterSpacing={-1}
          >
            Estimaciones pre-reto
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={{ base: 4, md: 6 }}
        >
          <GridItem
            bg="white"
            p={{ base: 3, md: 4 }}
            borderRadius="md"
            boxShadow="md"
          >
            <Text
              fontFamily="DM Sans"
              fontSize={{ base: 'xs', md: 'sm' }}
              color="gray.500"
            >
              Vídeos
            </Text>
            <Text
              fontFamily="DM Sans"
              fontSize={{ base: 'lg', md: '2xl' }}
              fontWeight="bold"
            >
              {estimatedMetrics.videos}
            </Text>
          </GridItem>

          <GridItem
            bg="white"
            p={{ base: 3, md: 4 }}
            borderRadius="md"
            boxShadow="md"
          >
            <Text
              fontFamily="DM Sans"
              fontSize={{ base: 'xs', md: 'sm' }}
              color="gray.500"
            >
              Impactos
            </Text>
            <Text
              fontFamily="DM Sans"
              fontSize={{ base: 'lg', md: '2xl' }}
              fontWeight="bold"
            >
              {estimatedMetrics.views}
            </Text>
          </GridItem>

          <GridItem
            bg="white"
            p={{ base: 3, md: 4 }}
            borderRadius="md"
            boxShadow="md"
          >
            <Text
              fontFamily="DM Sans"
              fontSize={{ base: 'xs', md: 'sm' }}
              color="gray.500"
            >
              Presupuesto
            </Text>
            <Text
              fontFamily="DM Sans"
              fontSize={{ base: 'lg', md: '2xl' }}
              fontWeight="bold"
            >
              {estimatedMetrics.budget}
            </Text>
          </GridItem>
        </Grid>
      </Box>

      {/* Métricas del reto */}
      <Box p="0px 40px 60px 40px">
        <Box mb="20px">
          <Text
            fontFamily="DM Sans"
            fontWeight="bold"
            fontSize={{ base: '24px', md: '30px' }}
            letterSpacing={-1}
          >
            Métricas principales
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={{ base: 4, md: 6 }}
        >
          {metrics.map((metric, index) => (
            <GridItem
              key={index}
              bg="white"
              p={{ base: 3, md: 4 }}
              borderRadius="md"
              boxShadow="md"
            >
              <Text
                fontFamily="DM Sans"
                fontSize={{ base: 'xs', md: 'sm' }}
                color="gray.500"
              >
                {metric.label}
              </Text>
              <Text
                fontFamily="DM Sans"
                fontSize={{ base: 'lg', md: '2xl' }}
                fontWeight="bold"
              >
                {metric.value}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </Box>

      {/* Top Videos Section */}
      <Box p="0px 40px 40px 40px">
        <Box mb="20px">
          <Text
            fontFamily="DM Sans"
            fontWeight="bold"
            fontSize="30px"
            letterSpacing={-1}
          >
            Top Videos
          </Text>
        </Box>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ base: 'center', md: 'flex-start' }}
          flexWrap="wrap"
        >
          {topVideos.map((video, index) => (
            <Box
              key={index}
              width={{ base: '100%', md: '30%' }}
              mb={{ base: 8, md: 0 }}
              maxWidth="250px"
            >
              <IPhoneVideo src={video.src} metrics={video} />
            </Box>
          ))}
        </Flex>
      </Box>

      {/* Datos a destacar */}
      <Box p="40px">
        <Box mb="10px">
          <Text
            fontFamily="DM Sans"
            fontWeight="bold"
            fontSize="30px"
            letterSpacing={-1}
          >
            Datos a destacar
          </Text>
        </Box>
        <Box className="description-html">
          <Box
            fontFamily="DM Sans"
            fontSize="16px"
            dangerouslySetInnerHTML={{ __html: reportData?.data?.feedback }}
          />
        </Box>
      </Box>

      {/* Comentarios destacados */}
      <Box p={{ base: '20px', md: '0px 40px 40px 40px' }}>
        <Box mb={{ base: '15px', md: '20px' }}>
          <Text
            fontFamily="DM Sans"
            letterSpacing={-1}
            fontWeight="bold"
            fontSize={{ base: '24px', md: '30px' }}
          >
            Comentarios Destacados
          </Text>
        </Box>
        <Box
          position="relative"
          height={{ base: '500px', md: '400px' }}
          width="100%"
        >
          {commentCards.map((card, index) => (
            <CommentCard
              key={index}
              {...card}
              top={{ base: `${index * 150}px`, md: card.top }}
              left={{ base: '5%', md: card.left }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
