import React from 'react';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Textarea,
  Heading,
  Input,
  Text,
  Divider,
  Image,
  Flex,
} from '@chakra-ui/react';

import PDFImage from '../../../Assets/Images/pdf-file.png';

const StepCampaignBriefing = ({
  formData,
  setFormData,
  errors,
  handleDocumentUpload,
}) => {
  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleImageBanner = e => {
    const file = e.target.files[0];
    handleDocumentUpload(file);
  };

  return (
    <Box>
      <Heading textAlign="center" as="h3" size="lg" mb={8}>
        Detalles del briefing
      </Heading>

      <Box m={'0 auto'} maxWidth="600px">
        {/* BENEFICIOS */}
        <FormControl isInvalid={errors.campaignBenefits} mb={6}>
          <FormLabel htmlFor="campaignBenefits">
            Beneficios que quieres destacar
          </FormLabel>
          <Textarea
            id="campaignBenefits"
            type="text"
            value={formData.campaignBenefits || ''}
            onChange={e => handleChange('campaignBenefits', e.target.value)}
            placeholder="Los creadores mencionarán estas ventajas en el vídeo"
          />
          <FormErrorMessage>{errors.campaignBenefits}</FormErrorMessage>
        </FormControl>

        {/* TONO Y ESTILO */}
        <FormControl isInvalid={errors.campaignToneAndStyle} mb={6}>
          <FormLabel htmlFor="campaignToneAndStyle">
            ¿Tienes un estilo y tono deseado? (opcional)
          </FormLabel>
          <Textarea
            id="campaignToneAndStyle"
            type="text"
            value={formData.campaignToneAndStyle || ''}
            onChange={e => handleChange('campaignToneAndStyle', e.target.value)}
            placeholder="El tono del vídeo debe ser cercano, amistoso y motivador"
          />
          <FormErrorMessage>{errors.campaignToneAndStyle}</FormErrorMessage>
        </FormControl>

        {/* ESCENARIO */}
        <FormControl isInvalid={errors.campaignEnvironment} mb={6}>
          <FormLabel htmlFor="campaignEnvironment">
            ¿Quieres que el creador grabe en un entorno específico? (opcional)
          </FormLabel>
          <Textarea
            id="campaignEnvironment"
            type="text"
            value={formData.campaignEnvironment || ''}
            onChange={e => handleChange('campaignEnvironment', e.target.value)}
            placeholder="Idealmente debe grabarse en.."
          />
          <FormErrorMessage>{errors.campaignEnvironment}</FormErrorMessage>
        </FormControl>

        {/* CTA */}
        <FormControl isInvalid={errors.campaignCTA} mb={6}>
          <FormLabel htmlFor="campaignCTA">
          Quieres que el creador utilice una llamada a la acción específica? (opcional)
          </FormLabel>
          <Textarea
            id="campaignCTA"
            type="text"
            value={formData.campaignCTA || ''}
            onChange={e => handleChange('campaignCTA', e.target.value)}
            placeholder="El vídeo debe concluir con una clara llamada a la acción que invite a los usuarios a visitar el sitio web de zapas.com para comprar su kit con el código de descuento 'TIKTOK10'"
          />
          <FormErrorMessage>{errors.campaignCTA}</FormErrorMessage>
        </FormControl>

        {/* CTA */}

        <Box mb={5} mt={10}>
          <Divider />
        </Box>

        <Box mb={5}>
          <Text fontSize="22px" fontWeight="bold" textAlign="left">
            ¿Ya cuentas con un briefing?
          </Text>
        </Box>

        {/* BRIEFING DEL PRODUCTO */}
        <FormControl isInvalid={errors.productBriefing} mb={4}>
          <FormLabel htmlFor="productBriefing">
            Texto libre (opcional)
          </FormLabel>
          <Textarea
            id="productBriefing"
            type="text"
            value={formData.productBriefing || ''}
            onChange={e => handleChange('productBriefing', e.target.value)}
            placeholder="Escribe el briefing aquí si ya tienes uno"
            minH="200px"
          />
          <FormErrorMessage>{errors.productValue}</FormErrorMessage>
        </FormControl>

        {/* DOCUMENT */}
        <FormControl mb={8}>
          <FormLabel>
            <Flex alignItems="center">
              <Text marginRight={1}>Sube un documento PDF (opcional)</Text>
            </Flex>
          </FormLabel>
          <Box
            borderWidth={2}
            borderColor="rgb(226, 232, 240)"
            borderStyle="dashed"
            borderRadius="md"
            p={4}
            textAlign="center"
            position="relative"
            maxHeight="200px"
          >
            {formData.briefingDocument ? (
              <Box>
                <Text fontSize="14px">
                  Documento seleccionado:{' '}
                  <strong>{formData.briefingDocument.name}</strong>
                </Text>
              </Box>
            ) : (
              <>
                <Image
                  m={'0 auto 5px auto'}
                  width="35px"
                  src={PDFImage}
                  alt="Your pdf"
                />
                <Text>Arrastra y suelta aquí, o</Text>
              </>
            )}
            <Input
              type="file"
              onChange={handleImageBanner}
              display="none"
              id="file-input"
            />
            <label htmlFor="file-input">
              <Text
                as="span"
                fontWeight="bold"
                color="blue.500"
                cursor="pointer"
                textDecoration="underline"
              >
                haz clic para elegir un documento
              </Text>
            </label>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

StepCampaignBriefing.validate = data => {
  const errors = {};
  if (!data.campaignBenefits) {
    errors.campaignBenefits = 'Debes escribir algunos beneficios';
  }
  return errors;
};

export default StepCampaignBriefing;
