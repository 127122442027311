import {
  Box,
  Image,
  Flex,
  Text,
  UnorderedList,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';

import { CloseIcon, CheckIcon } from '@chakra-ui/icons';

import ChallengeDefaultHeaderImage from '../../Assets/Images/challenge-modal_header.png';
import BrandExampleVideoCoverImage from '../../Assets/Images/brand-example-video-cover.png';

import './ChallengePreviewerV2.css';

import calculateRelativeDateChallengeFinalization from '../../Utils/calculateRelativeDateChallengeFinalization';

export default function ChallengePreviewerV2({
  brand,
  challengeName,
  participants,
  fixedReward,
  description,
  banner,
  setIsModalOpen,
  mentions,
  hashtags,
  requirements,
  links,
  launchDate,
}) {
  return (
    <Box
      w="390px"
      border="1px solid  #e2e8f0"
      borderRadius="6px"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 10px 15px -3px"
    >
      {/* Cabecera */}
      <Box
        height="150px"
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundImage={banner ? banner : ChallengeDefaultHeaderImage}
        position="relative"
        borderTopLeftRadius="6px"
        borderTopRadius="6px"
      >
        <Box
          height="150px"
          display="flex"
          justifyContent="initial"
          alignItems="center"
        >
          <Image
            marginLeft="30px"
            borderRadius="50%"
            width="80px"
            height="80px"
            border="1px solid white"
            bg="white"
            src={brand.logoUrl}
          />
        </Box>
        <Box
          backgroundColor="white"
          height="40px"
          width="40px"
          border="1px solid"
          borderRadius="50%"
          position="absolute"
          top={4}
          right={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CloseIcon _hover={{ cursor: 'pointer' }} width={5} />
        </Box>
      </Box>
      {/* Participantes y finalización */}
      <Flex height="50px" backgroundColor="#fffff2">
        <Flex
          p={5}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box textAlign="left">
            <Text
              lineHeight={1.2}
              fontFamily="DM Sans"
              fontSize="12px"
              fontWeight="normal"
            >
              Participantes
            </Text>
            <Box fontFamily="DM Sans" fontSize="12px" fontWeight="semibold">
              10/{participants}
            </Box>
          </Box>
          <Box textAlign="left">
            <Text
              lineHeight={1.2}
              fontFamily="DM Sans"
              fontSize="12px"
              fontWeight="normal"
            >
              Fecha finalización
            </Text>
            <Text fontFamily="DM Sans" fontSize="12px" fontWeight="semibold">
              {calculateRelativeDateChallengeFinalization(launchDate)}
            </Text>
          </Box>
        </Flex>
      </Flex>
      {/* Cartelito recompensas nuevo */}
      <Box p={4}>
        <Box
          position="relative"
          bg="radial-gradient(50% 1097.43% at 50% 50.68%, #EBD0FF 0%, #E4E4D9 100%)"
          p={3}
          borderRadius="8px"
        >
          <Box width="100%">
            <Text textAlign="center" fontSize="16px" fontWeight="bold">
              💸 Recompensas 💸
            </Text>
          </Box>
          <Flex mt={1} justifyContent="center">
            <Box mr={4} textAlign="center">
              <Text fontSize="12px">Fija (vídeo aprobado)</Text>
              <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                {fixedReward}€
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Text fontSize="20px" fontWeight="black">
                +
              </Text>
            </Box>
            <Box ml={4} mr={7} textAlign="center">
              <Text fontSize="12px">Variable</Text>
              <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                x views
              </Text>
            </Box>
          </Flex>
          <Box
            opacity={0.8}
            top={2}
            right={2}
            position="absolute"
            px={4}
            py="1px"
            display="inline-block"
            borderRadius="4px"
            border="1px solid"
          >
            <Text fontSize="9px">+Info</Text>
          </Box>
        </Box>
      </Box>
      {/* Texto del reto */}
      <Box p="0px 20px 20px 20px">
        <Text fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={2}>
          {challengeName}
        </Text>
        <Text fontFamily="DM Sans" mb={2} fontWeight="bold">
          Descripción del reto
        </Text>
        <div className="description-html">
          {description.length !== 0 ? (
            <Box
              fontFamily="DM Sans"
              fontSize="14px"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <Box fontFamily="DM Sans" fontSize="14px">
              Write a description of the challenge and it will be displayed
              here
            </Box>
          )}
        </div>
      </Box>
      {/* Video de ejemplo */}
      <Box pb={5} pl={5} pr={5}>
        <Text fontFamily="DM Sans" mb={3} fontWeight="bold">
          Vídeo(s) de ejemplo
        </Text>
        <Image
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
          height="150px"
          width="100px"
          borderRadius="8px"
          src={BrandExampleVideoCoverImage}
          alt="Brand video example"
          onClick={() => setIsModalOpen(true)}
        />
      </Box>
      {/* Hashtags y menciones */}
      <Box pb={5} pl={5} pr={5}>
        <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
          Menciones y hashtags
        </Text>
        <Box>
          <UnorderedList>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="14px">
                Etiqueta:{' '}
                <span style={{ color: 'black' }}>
                  {mentions.map(m => `@${m.value}`)}
                </span>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="14px">
                Incluye en la descripción:{' '}
                <span style={{ color: 'black' }}>
                  {hashtags.map(h => `#${h.value} `)}
                </span>
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
      {/* Requisitos */}
      <Box pb={5} pl={5} pr={5}>
        <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
          Requisitos
        </Text>
        <Box>
          {requirements.length !== 0 ? (
            <List>
              {requirements &&
                requirements.map((r, index) => (
                  <ListItem
                    display="flex"
                    alignItems="center"
                    key={index}
                    fontFamily="DM Sans"
                    fontSize="14px"
                  >
                    <ListIcon as={CheckIcon} color="black" />
                    {r.value}
                  </ListItem>
                ))}
            </List>
          ) : (
            <Box fontFamily="DM Sans" fontSize="14px">
              Type in some requirements and they will appear here
            </Box>
          )}
        </Box>
      </Box>
      {/* Link */}
      <Box pb={5} pl={5} pr={5}>
        <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
          Enlaces
        </Text>
        <Box>
          {links && links[0].url !== '' ? (
            <List>
              {links.map(r => (
                <ListItem
                  alignItems="center"
                  key={r.url}
                  fontFamily="DM Sans"
                  fontSize="14px"
                >
                  <Text>
                    {r.text}:{' '}
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={r.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {r.url}
                    </a>
                  </Text>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box fontFamily="DM Sans" fontSize="14px">
              Type in some links and they will appear here
            </Box>
          )}
        </Box>
      </Box>
      {/* Info de la marca */}
      <Box pb={5} pl={5} pr={5}>
        <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
          Información de la marca
        </Text>
        <Box>
          <Text fontFamily="DM Sans" fontSize="14px">
            {brand.description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
