import React from 'react';
import { Box, Flex, Button, Text } from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

export default function EmptyBriefings({ type }) {
  return (
    <Box p={5}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="300px"
        p={5}
        borderRadius="8px"
        border="1px solid #e2e8f0"
      >
        <Text fontWeight="bold" fontSize="20px">
          No tienes ningún briefing
        </Text>
        <Text
          opacity={0.7}
          fontSize="14px"
          my={2}
          maxWidth="500px"
          lineHeight={1.1}
          textAlign="center"
        >
          Crea tu primer briefing y encuentra al creador de UGC que necesitas
        </Text>
        <Flex mt={5} alignItems="center">
          <CustomLink to="/dashboard/faq">
            <Button mr={5} color="black" variant="link">
              Saber más
            </Button>
          </CustomLink>
          <CustomLink to="/dashboard/ugc/new">
            <Button
              color="white"
              bg="black"
              _hover={{ bg: 'black', color: 'white' }}
            >
              Crear briefing
            </Button>
          </CustomLink>
        </Flex>
      </Box>
    </Box>
  );
}
