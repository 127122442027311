import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentToken, selectCurrentBrand } from '../../Redux/features/auth/authSlice';

export function GuestRoute({ children }) {
  const token = useSelector(selectCurrentToken);
  const brand = useSelector(selectCurrentBrand);
  if (token && brand) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}