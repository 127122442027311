import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Divider,
  Select,
  Image,
  useClipboard,
  useToast,
  Box,
  Flex,
  Card,
  CardBody,
  Text,
  FormControl,
  FormLabel,
  CardHeader,
  Heading,
  Input,
  Button,
  Container,
  VStack,
  IconButton,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  ButtonGroup,
  PopoverCloseButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import { FiDownload } from 'react-icons/fi';
import { RiSendPlaneFill } from 'react-icons/ri';
import { RiFileCopyLine } from 'react-icons/ri';

import {
  useGetBrandCollabsQuery,
  useUpdateCollabMutation,
  useSendMessageToCollabMutation,
  useCloseCollabMutation,
} from '../../../Redux/features/collabs/collabsApiSlice';

import DefaultImageProfile from '../../../Assets/Images/default-profile-image.png';

function ChatMessage({ msg }) {
  const getInitials = name => name.match(/\b(\w)/g).join(''); // Get initials from name
  return (
    <Box
      display="flex"
      flexDirection={msg.sender === 'brand' ? 'row-reverse' : 'row'}
      alignItems="flex-end"
      width="100%"
    >
      <Avatar
        name={msg.sender}
        size="sm"
        bg={msg.sender === 'user' ? 'blue.500' : 'gray.500'}
        color="white"
        ml={msg.sender === 'user' ? 0 : 3}
        mr={msg.sender === 'user' ? 3 : 0}
      >
        {getInitials(msg.sender)}
      </Avatar>
      <Box
        bg={msg.sender === 'user' ? '#ff79dd2b' : '#f9f9f9'}
        color={msg.sender === 'user' ? 'black' : 'black'}
        padding={3}
        borderRadius="lg"
        flexGrow={1}
        maxWidth="100%"
      >
        {msg.type === 'revision' && (
          <Box mb={2} fontWeight="bold" fontSize="14px">
            {' '}
            📹 Mensaje de revisión 📹
          </Box>
        )}
        <Text fontSize="sm">{msg.content}</Text>
        <Text mt={2} fontSize="xs" color={msg.isUser ? 'gray.500' : 'gray.500'}>
          {new Date(msg.sentAt).toLocaleTimeString([], { timeStyle: 'short' })}{' '}
        </Text>
      </Box>
    </Box>
  );
}

function ChatBox({ messages, creatorName }) {
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <VStack
      spacing={4}
      align="stretch"
      overflowY="auto"
      maxH="600px"
      width="100%"
      p={4}
    >
      {messages.length === 0 && (
        <Flex
          flexDir="column"
          minH="300px"
          justifyContent="center"
          alignItems="center"
        >
          <Text>
            No tienes mensajes con {creatorName ? creatorName : 'este creador'}
          </Text>
          <Text>¡Escríbele uno!</Text>
        </Flex>
      )}
      {messages.map(msg => (
        <ChatMessage key={msg._id} msg={msg} />
      ))}
      <div ref={bottomRef} />
      <Box height="30px" mb={6} />
    </VStack>
  );
}

function InputBox({ onSendMessage, currentCollab, showToast }) {
  const [sendMessage, { isLoading: isLoadingSendMessage }] =
    useSendMessageToCollabMutation();

  const [message, setMessage] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    if (message.trim() !== '') {
      try {
        await sendMessage({
          collabId: currentCollab._id,
          content: message,
          type: 'default',
        });
      } catch (error) {
        showToast('No se ha podido enviar el mensaje', 'error');
      }
      setMessage('');
    } else {
      showToast('Message can no be empty', 'error');
    }
  };

  return (
    <Flex p="16px 32px" position="absolute" bottom={0} zIndex="1" width="100%">
      <Input
        placeholder="Escribe un mensaje..."
        value={message}
        onChange={e => setMessage(e.target.value)}
        size="md"
        flexGrow={1}
        bg="white"
        mr={2}
        fontSize="sm"
        border="1px solid black"
        borderRadius="20px"
        _focus={{ outline: 'none' }}
        _hover={{ outline: 'none' }}
        _focusVisible={{ outline: 'none' }}
      />
      <IconButton
        borderRadius="50%"
        border="1px solid black"
        boxShadow="1px 1px 0px 0px #000"
        onClick={handleSubmit}
        aria-label="Search database"
        icon={<RiSendPlaneFill />}
        bg="black"
        color="white"
        fontSize="20px"
        isLoading={isLoadingSendMessage}
        _hover={{
          bg: 'black',
          opacity: 0.7,
        }}
      />
    </Flex>
  );
}

// Carga 1 o varias collabs
export default function DashboardUGCBriefingApplicant() {
  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Cogemos el id de la collab y del briefing por parámetros
  const { collabId, briefingId } = useParams();

  // ID de la collab del creador seleccionado (selector de creadores si hay varios)
  // (aunque ponga creatorId es el id de la collab del creator seleccionadio por nombre, mirar el selector)
  const [creatorId, setCreatorId] = useState('');
  const handleSelectCreatorId = event => setCreatorId(event.target.value);

  // Obtenemos todas las collab por si acaso no viene de la pestaña anterior
  const { data: brandCollabs, isLoading: isLoadingBrandCollabs } =
    useGetBrandCollabsQuery();

  // Buscamos las collabs de este briefing
  const matchingCollabs =
    brandCollabs &&
    brandCollabs.data &&
    brandCollabs.data.filter(collab =>
      collab.briefing.some(b => b._id === briefingId)
    );

  // Actualizar la collab
  const [updateCollab, { isLoading: isLoadingUpdateCollab }] =
    useUpdateCollabMutation();

  // Actualizar la collab
  const [closeCollab, { isLoading: isLoadingCloseCollab }] =
    useCloseCollabMutation();

  // Enviar un mensaje
  const [sendMessage, { isLoading: isLoadingSendMessage }] =
    useSendMessageToCollabMutation();

  // De todas las collabs, cogemos la que necesitamos por param
  let currentCollab;
  currentCollab =
    matchingCollabs && matchingCollabs.find(collab => collab._id === creatorId);

  // si hay en la ruta, no mostramos selector y significa que solo hay una
  if (collabId) {
    currentCollab =
      matchingCollabs &&
      matchingCollabs.find(collab => collab._id === collabId);
  }

  // Modal para enviar una revision
  const {
    isOpen: isOpenRevisionModal,
    onOpen: onOpenRevisionModal,
    onClose: onCloseRevisionModal,
  } = useDisclosure();

  // PopOver de confirmación de finalización de la collab
  const {
    isOpen: isOpenConfirmContent,
    onToggle: onToggleConfirmContent,
    onClose: onCloseConfirmContent,
  } = useDisclosure();

  // Mensaje de revision
  const [revisionMessage, setRevisionMessage] = useState('');
  const handleChangeRevisionMessage = event =>
    setRevisionMessage(event.target.value);

  // Enlace de seguimiento
  const [trackingLink, setTrackingLink] = useState('');
  const handleChangeTrackingLink = event => setTrackingLink(event.target.value);

  // Función para guardar y enviar el link tracking a la collab
  const handleSaveLinkTracking = async () => {
    try {
      await updateCollab({ collabId: currentCollab._id, trackingLink });
      showToast(
        'Se ha compartido el enlace de seguimiento con el creador',
        'success'
      );
    } catch (error) {
      showToast('No se ha podido guardar el enlace de seguimiento', 'error');
    }
  };

  // Función para ir a los contenidos de we transfer del creador
  const handleGoToDownloadLink = () => {
    window.open(currentCollab?.contentLink, '_blank');
  };

  // Función para enviar una revisión al creador
  const handleSendRevisionMessage = async () => {
    try {
      await sendMessage({
        collabId: currentCollab._id,
        content: revisionMessage,
        type: 'revision',
      });
      showToast('Tu mensaje de revisión se ha mandado con éxito', 'success');
      setRevisionMessage('');
      onCloseRevisionModal();
    } catch (error) {
      showToast('No se ha podido enviar el mensaje de revision', 'error');
    }
  };

  // Función para aprobar el contenido
  const handleApproveContent = async () => {
    try {
      await closeCollab({
        collabId: currentCollab._id,
      });
      showToast('¡La colaboración ha finalizado con éxito!', 'success');
      onCloseConfirmContent();
    } catch (error) {
      showToast(
        'No se ha podido aprobar la colaboración, contacte con hello@cruwi.com',
        'error'
      );
    }
  };

  // Datos del creador
  const creatorName = currentCollab ? currentCollab.user[0].name : '';
  const creatorLastname = currentCollab ? currentCollab.user[0].lastName : '';
  const creatorPhone = currentCollab ? currentCollab.user[0].phone : '';
  const creatorDeliveryAddress = currentCollab
    ? currentCollab.user[0].deliveryAddress
    : '';
  const creatorImage = currentCollab
    ? currentCollab.user[0].profilePicture
    : DefaultImageProfile;

  const creatorMessages = currentCollab ? currentCollab.messages : [];

  // Copy paste creator direction
  const { hasCopied, onCopy } = useClipboard(creatorDeliveryAddress);
  const handleCopy = () => {
    onCopy();
    toast({
      title: 'Copiado!',
      status: 'success',
      duration: 1000,
    });
  };

  // Copy paste creator name
  const { onCopy: onCopyName } = useClipboard(creatorName);
  const handleCopyName = () => {
    onCopyName();
    toast({
      title: 'Copiado!',
      status: 'success',
      duration: 1000,
    });
  };

  // Copy paste creator lastname
  const { onCopy: onCopyLastname } = useClipboard(creatorLastname);
  const handleCopyLastname = () => {
    onCopyLastname();
    toast({
      title: 'Copiado!',
      status: 'success',
      duration: 1000,
    });
  };

  // Copy paste creator phone
  const { onCopy: onCopyPhone } = useClipboard(creatorPhone);
  const handleCopyPhone = () => {
    onCopyPhone();
    toast({
      title: 'Copiado!',
      status: 'success',
      duration: 1000,
    });
  };

  // Loading
  if (isLoadingBrandCollabs) {
    return <p>Loading collab..</p>;
  }

  return (
    <Box>
      <Box p={5} borderRadius="8px">
        {/* Resto del panel */}
        <Flex flexDir={['column', 'column', 'column', 'row', 'row']}>
          {/* Interacciones */}
          <Flex mr={5} mb={5} flexDir="column" width={['100%']}>
            {/* Selector de creadores si hay varios */}
            {!collabId && (
              <Card width="100%" mb={5}>
                <CardHeader
                  borderTopLeftRadius="6px"
                  borderTopRadius="6px"
                  bg="rgb(226, 232, 240)"
                >
                  <Heading size="md">Elige al creador</Heading>
                </CardHeader>

                <CardBody>
                  <Text>
                    Selecciona al creador con el que deseas comunicarte
                  </Text>
                  <Box mt={2}>
                    <Select
                      onChange={handleSelectCreatorId}
                      placeholder="Selecciona un creador"
                    >
                      {matchingCollabs &&
                        matchingCollabs.length > 0 &&
                        matchingCollabs.map(collab => {
                          return (
                            <option key={collab._id} value={collab._id}>
                              {collab.user[0].name}
                            </option>
                          );
                        })}
                    </Select>
                  </Box>
                </CardBody>
              </Card>
            )}

            {/* Sobre el creador */}
            <Card mb={5}>
              <CardHeader paddingBottom={0}>
                <Heading size="md">Sobre el creador</Heading>
              </CardHeader>

              <CardBody pt={3}>
                <Box mb={5}>
                  <Text>Datos principales del creador</Text>

                  <Box mt={5}>
                    <Flex
                      mt={2}
                      flexDir={['column', 'column', 'column', 'column', 'row']}
                    >
                      <FormControl mr={2} mb={[3, 3, 3, 3, 0]}>
                        <FormLabel mb={1}>Nombre</FormLabel>
                        <InputGroup size="md">
                          <Input
                            pr="4.5rem"
                            defaultValue={creatorName}
                            readOnly
                          />
                          <InputRightElement width="3.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={handleCopyName}
                              bg="white"
                              color="black"
                              border="1px solid #e2e8f0"
                              _hover={{ bg: 'white', opacity: 0.75 }}
                            >
                              <RiFileCopyLine />
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <FormControl mr={2} mb={[3, 3, 3, 3, 0]}>
                        <FormLabel mb={1}>Apellido</FormLabel>
                        <InputGroup size="md">
                          <Input
                            pr="4.5rem"
                            defaultValue={creatorLastname}
                            readOnly
                          />
                          <InputRightElement width="3.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={handleCopyLastname}
                              bg="white"
                              color="black"
                              border="1px solid #e2e8f0"
                              _hover={{ bg: 'white', opacity: 0.75 }}
                            >
                              <RiFileCopyLine />
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <FormControl mb={[3, 3, 3, 3, 0]}>
                        <FormLabel mb={1}>Teléfono</FormLabel>
                        <InputGroup size="md">
                          <Input
                            pr="4.5rem"
                            defaultValue={creatorPhone}
                            readOnly
                          />
                          <InputRightElement width="3.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={handleCopyPhone}
                              bg="white"
                              color="black"
                              border="1px solid #e2e8f0"
                              _hover={{ bg: 'white', opacity: 0.75 }}
                            >
                              <RiFileCopyLine />
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    </Flex>
                  </Box>
                </Box>
              </CardBody>
            </Card>

            {/* Enviar el link de seguimiento */}
            <Card mb={5}>
              <CardHeader paddingBottom={0}>
                <Heading size="md">Envía el producto</Heading>
              </CardHeader>

              <CardBody pt={3}>
                <Box mb={5}>
                  <Text>Esta es la dirección del creador</Text>
                  {Boolean(creatorDeliveryAddress) === true ? (
                    <Flex mt={2}>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          defaultValue={creatorDeliveryAddress}
                          readOnly
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleCopy}
                            mr={4.5}
                            bg="black"
                            color="white"
                            _hover={{ bg: '#000', opacity: 0.75 }}
                          >
                            {hasCopied ? 'Copiado!' : 'Copiar'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </Flex>
                  ) : (
                    <Box
                      mt={2}
                      textAlign="center"
                      width="100%"
                      borderRadius="6px"
                      p={2}
                      border="1px dashed"
                    >
                      The creator has not yet shared his location
                    </Box>
                  )}
                </Box>
                <Divider />
                <Box mt={5}>
                  <Text>
                    Cuando hayas enviado el producto al creador, introduce el
                    enlace de seguimiento aquí
                  </Text>
                  <Flex mt={5}>
                    <Input
                      value={currentCollab?.trackingLink || trackingLink}
                      onChange={handleChangeTrackingLink}
                      placeholder="https://www.ups.com/12345678"
                      size="md"
                      mr={2}
                    />
                    <Button
                      bg="black"
                      color="white"
                      _hover={{ bg: '#000', opacity: 0.75 }}
                      size="md"
                      w="100px"
                      onClick={handleSaveLinkTracking}
                      isLoading={isLoadingUpdateCollab}
                    >
                      Guardar
                    </Button>
                  </Flex>
                </Box>
              </CardBody>
            </Card>

            {/* Enlace del creador si lo ha subido ya todo */}
            <Card>
              <CardHeader paddingBottom={0}>
                <Heading size="md">Contenido</Heading>
              </CardHeader>

              <CardBody pt={3}>
                <Text>
                  Cuando el creador suba el contenido, aparecerá el enlace de
                  descarga aquí
                </Text>
                <Flex mt={10} mb={5}>
                  {/* si hay o no link de contenidos ya */}
                  {currentCollab && currentCollab.contentLink ? (
                    <Box
                      textAlign="center"
                      width="100%"
                      borderRadius="6px"
                      p={2}
                      border="1px solid #e2e8f0"
                      bg="#e2e8f0"
                      cursor="pointer"
                      fontWeight="bold"
                      _hover={{
                        bg: ' #e2e8f9',
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      onClick={handleGoToDownloadLink}
                    >
                      <Text mr={2}>
                        ¡Tu contenido está listo! Haz click para descargarlo
                      </Text>{' '}
                      <FiDownload size="16px" />
                    </Box>
                  ) : (
                    <Box
                      textAlign="center"
                      width="100%"
                      borderRadius="6px"
                      p={2}
                      border="1px dashed"
                    >
                      No hay contenido para descargar aún
                    </Box>
                  )}
                </Flex>
                {currentCollab && currentCollab.contentLink && (
                  <Flex justifyContent="flex-end">
                    <Button
                      bg="white"
                      color="black"
                      border="1px solid"
                      _hover={{ bg: 'white', opacity: 0.75 }}
                      size="md"
                      mr={2}
                      onClick={onOpenRevisionModal}
                    >
                      Enviar revisión
                    </Button>
                    <Popover
                      returnFocusOnClose={false}
                      isOpen={isOpenConfirmContent}
                      onClose={onCloseConfirmContent}
                      placement="right"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <Button
                          bg="black"
                          color="white"
                          _hover={{ bg: '#000', opacity: 0.75 }}
                          size="md"
                          onClick={onToggleConfirmContent}
                        >
                          Aprobar contenido
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverHeader fontSize="14px" fontWeight="semibold">
                          Confirma tu acción
                        </PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody fontSize="14px">
                          If you confirm, you end the collaboration with the
                          creator and the content created will be considered as
                          good.
                        </PopoverBody>
                        <PopoverFooter display="flex" justifyContent="flex-end">
                          <ButtonGroup size="sm">
                            <Button
                              variant="outline"
                              onClick={onCloseConfirmContent}
                            >
                              Cancelar
                            </Button>
                            <Button
                              bg="black"
                              color="white"
                              _hover={{ bg: '#000', opacity: 0.75 }}
                              size="sm"
                              onClick={handleApproveContent}
                              isLoading={isLoadingCloseCollab}
                            >
                              Confirmar
                            </Button>
                          </ButtonGroup>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                )}
              </CardBody>
            </Card>
          </Flex>

          {/* Chat con el creador  */}
          <Box width={['100%']}>
            <Card minH="80%">
              <Box
                p="17px"
                bg="rgb(226, 232, 240)"
                borderTopLeftRadius="6px"
                borderTopRightRadius="6px"
              >
                <Flex alignItems="center">
                  <strong>Chat con:</strong>{' '}
                  <Image
                    ml={2}
                    mr={2}
                    height="30px"
                    width="30px"
                    borderRadius="50%"
                    src={creatorImage}
                    alt=""
                  />{' '}
                  {creatorName}
                </Flex>
              </Box>
              <Container>
                <VStack spacing={4}>
                  <ChatBox
                    creatorName={creatorName}
                    messages={creatorMessages}
                  />
                  <InputBox
                    showToast={showToast}
                    currentCollab={currentCollab}
                  />
                </VStack>
              </Container>
            </Card>
          </Box>
        </Flex>
      </Box>

      {/* Modal de revisión */}
      <Modal
        isCentered
        isOpen={isOpenRevisionModal}
        onClose={onCloseRevisionModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>Envía una revisión</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="14px" mb={5}>
              Escribe un mensaje de revisión al creador si no estás satisfecho
              con el contenido generado o si deseas modificarlo de algún modo.
            </Text>
            <Textarea
              onChange={handleChangeRevisionMessage}
              value={revisionMessage}
              placeholder="Write what you want to say to the creator about the content.."
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseRevisionModal}>
              Cancel
            </Button>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: '#000', opacity: 0.75 }}
              size="md"
              onClick={handleSendRevisionMessage}
              isLoading={isLoadingSendMessage}
            >
              Enviar mensaje
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
