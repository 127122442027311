import React from 'react';
import { Box, Flex, Button, Text } from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

export default function EmptyChallenges({ type, isMission }) {
  if (type === 'noChallengesButCredits') {
    return (
      <Box p={5}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
          p={5}
          borderRadius="8px"
          border="1px solid #e2e8f0"
        >
          <Text fontWeight="bold" fontSize="20px">
            No tienes {isMission ? 'misiones' : 'retos'}
          </Text>
          <Text
            opacity={0.7}
            fontSize="14px"
            my={2}
            maxWidth="500px"
            lineHeight={1.1}
            textAlign="center"
          >
            {isMission
              ? 'Crear tu primera misión y empezar a generar vídeos'
              : 'Crear el primer reto y empezar a generar vídeos'}
          </Text>
          <Flex mt={5} alignItems="center">
            <CustomLink to="/dashboard/faq">
              <Button mr={5} color="black" variant="link">
                Saber más
              </Button>
            </CustomLink>
            <CustomLink to={isMission ? "/dashboard/missions/new" : "/dashboard/challenges/new"}>
              <Button
                color="white"
                bg="black"
                _hover={{ bg: 'black', color: 'white' }}
              >
                Crear {isMission ? 'misión' : 'reto'}
              </Button>
            </CustomLink>
          </Flex>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box p={5}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
          p={5}
          borderRadius="8px"
          border="1px solid #e2e8f0"
        >
          <Text fontWeight="bold" fontSize="20px">
            No tienes {isMission ? 'ninguna misión' : 'ningún reto'}
          </Text>
          <Text
            opacity={0.7}
            fontSize="14px"
            my={2}
            maxWidth="500px"
            lineHeight={1.1}
            textAlign="center"
          >
            {isMission
              ? 'Crea una misión y empieza a generar vídeos en TikTok'
              : 'Crea un reto y empieza a generar vídeos en TikTok'}
          </Text>
          <Flex mt={5} alignItems="center">
            <CustomLink to="/dashboard/faq">
              <Button mr={5} color="black" variant="link">
                Saber más
              </Button>
            </CustomLink>
            <CustomLink to={isMission ? "/dashboard/missions/new" : "/dashboard/challenges/new"}>
              <Button
                color="white"
                bg="black"
                _hover={{ bg: 'black', color: 'white' }}
              >
                Crear {isMission ? 'misión' : 'reto'}
              </Button>
            </CustomLink>
          </Flex>
        </Box>
      </Box>
    );
  }
}
