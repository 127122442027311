import { useEffect } from 'react';

const TidioChat = () => {
  useEffect(() => {
    // Only load Tidio script if it's not already loaded
    if (!window.tidioChatApi) {
      const script = document.createElement('script');
      script.src = '//code.tidio.co/t23uizmrm7nbdphug7xj6bxoscubeq7o.js';
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      // Cleanup the script when the component is unmounted
      const tidioScript = document.querySelector(
        "script[src^='//code.tidio.co']"
      );
      if (tidioScript) {
        document.body.removeChild(tidioScript);
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

export default TidioChat;
