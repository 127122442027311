import React from 'react';
import { useSelector } from "react-redux";

import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

import AccountForm from '../AccountForm/AccountForm';
import BrandForm from '../BrandForm/BrandForm';

import { selectCurrentBrand } from '../../Redux/features/auth/authSlice';
import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';

export default function DashboardSettings() {

  const brand = useSelector(selectCurrentBrand);
  const { data: user } =
    useGetUserDataQuery();

  return (
    <>

      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize='24px'>Ajustes</Text>
        </Flex>
      </Box>

      <Box p="5">
        <Tabs variant='soft-rounded'>
          <TabList>
            <Tab _selected={{ bg: '#e2e8f0'}} borderRadius={12} mr={2}>Cuenta</Tab>
            <Tab _selected={{ bg: '#e2e8f0'}} borderRadius={12}>Marca</Tab>
          </TabList>

          <TabPanels>
            <TabPanel paddingLeft={2}>
              {user && <AccountForm userData={user} />}
            </TabPanel>
            <TabPanel paddingLeft={2}>
              <BrandForm brandData={brand} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

    </>
  )
}
