import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentToken, selectCurrentBrand } from '../../Redux/features/auth/authSlice';

export default function ProtectedRoute({ children, redirectTo = 'register' }) {
  const token = useSelector(selectCurrentToken);
  const brand = useSelector(selectCurrentBrand);
  if (!token || !brand) {
    return <Navigate to={`/${redirectTo}`} replace />;
  }

  return children;
}
