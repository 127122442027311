import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import PricingTable from './PricingTable/PricingTable';

export default function DashboardCredits() {
  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Challenge credits
          </Text>
        </Flex>
      </Box>

      {/* Mostramos las cards de pricing */}
      <Box p="5">
        <PricingTable />
      </Box>
    </>
  );
}
