
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';

import ProductAction from '../../../Assets/Images/shopping-bag.png';
import ProductUnboxing from '../../../Assets/Images/box.png';
import ProductReview from '../../../Assets/Images/review.png';

// Opciones de vídeo
const videoOptionsData = [
  {
    title: 'Producto en Acción',
    key: 'productActionVideo',
    description:
      'Estos vídeos responden a cualquier pregunta que un cliente pueda tener sobre qué es su producto y cómo funciona.',
    image: ProductAction,
  },
  {
    title: 'Vídeo Unboxing',
    key: 'unboxingVideo',
    description:
      'El creador se graba mostrando exactamente los contenidos que trae, el empaquetado, las instrucciones y cualquier otra sorpresa.',
    image: ProductUnboxing,
  },
  {
    title: 'Vídeo Testimonial',
    key: 'testimonialVideo',
    description:
      'En estos vídeos el creador comparte su experiencia de primera mano y respalda tu producto con su propia imagen y voz.',
    image: ProductReview,
  },
];

// Custom card para los vídeos requeridos
const CustomCard = ({ videoType, formData, setFormData }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      borderWidth="1px"
      borderColor={
        formData.selectedVideoNeeds === videoType.key ? 'black' : '#e2e8f0'
      }
      borderRadius="lg"
      overflow="hidden"
      p={4}
      align="stretch"
      boxShadow={
        formData.selectedVideoNeeds === videoType.key
          ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
          : 'none'
      }
      onClick={() =>
        setFormData({ ...formData, selectedVideoNeeds: videoType.key })
      }
      cursor="pointer"
    >
      <Image width="50px" src={videoType.image} alt={videoType.title} />
      <Box pt={4}>
        <Text fontWeight="bold" mb={2}>
          {videoType.title}
        </Text>
        <Text mb={3} fontSize="sm">
          {videoType.description}
        </Text>
      </Box>
    </Flex>
  );
};

// Vista qué contenido quieres
const StepContent = ({ formData, setFormData, errors }) => {
  return (
    <Box>
      {/* Explicación */}
      <Heading textAlign="center" as="h3" size="lg" mb={4}>
        ¿Qué tipo de vídeo necesitas?
      </Heading>

      <Box maxWidth="500px" m="20px auto 40px auto" textAlign="center">
        <Text textAlign="center">
          Selecciona el tipo de vídeo que necesitas. Cada briefing se traducirá
          en 1 pieza de contenido que hará el creador que tú selecciones. <br />{' '}
          La pieza tendrá una duración de 15 a 60 segundos según necesites.
        </Text>
      </Box>

      {/* Selector de contenido */}
      <Box m={'0 auto'} maxWidth="900px">
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
          {videoOptionsData.map(videoType => {
            return (
              <CustomCard
                key={videoType.key}
                videoType={videoType}
                setFormData={setFormData}
                formData={formData}
              />
            );
          })}
        </SimpleGrid>
      </Box>

      <Box m="10px 0 20px 0">
        <Text color="red" textAlign="center" fontSize="14px">
          {errors.selectedVideoNeeds}
        </Text>
      </Box>
    </Box>
  );
};

// Validaciones paso 1
StepContent.validate = data => {
  const errors = {};
  if (data.selectedVideoNeeds === '') {
    errors.selectedVideoNeeds = 'Debes seleccionar un tipo de vídeo';
  }
  return errors;
};

export default StepContent;
