import apiSlice from '../../api/apiSlice';

export const collabsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrandCollabs: builder.query({
      query: () => 'brands/collabs',
      providesTags: ["Collabs"],
    }),
    updateCollab: builder.mutation({
      query: ({ collabId, trackingLink }) => ({
        url: `brands/collabs/${collabId}`,
        method: 'PATCH',
        body: {
          trackingLink
        },
      }),
      invalidatesTags: ['Collabs'],
    }),
    sendMessageToCollab: builder.mutation({
      query: ({ collabId, content, type }) => ({
        url: `collabs/${collabId}/messages`,
        method: 'POST',
        body: {
          content,
          type,
        },
      }),
      invalidatesTags: ['Collabs'],
    }),
    closeCollab: builder.mutation({
      query: ({ collabId }) => ({
        url: `brands/collabs/${collabId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['Collabs'],
    }), 
  }),
});

export const {
  useGetBrandCollabsQuery,
  useUpdateCollabMutation,
  useSendMessageToCollabMutation,
  useCloseCollabMutation,
} = collabsApiSlice;
