import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';

export default function HelpModal({ isOpen, onClose }) {
  const goToCalendly = () => {
    window.open('https://calendly.com/cruwi/info');
  };
  
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>¿Necesitas ayuda?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Agenda un reunión con el equipo de CRUWI y despeja cualquier duda
            que tengas, estamos encantados de ayudarte. Haz clic en el botón y
            agenda una reunión en nuestro calendly.
          </Text>
          <Box mt={5} maxW="300px">
            <Button onClick={() => goToCalendly()}>Agendar reunión</Button>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
