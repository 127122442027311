import { createSlice } from '@reduxjs/toolkit';

// Get user from localstorage
const brand = JSON.parse(localStorage.getItem('brand'));

// Get token from localstorage
const token = JSON.parse(localStorage.getItem('token'));

const initialState = {
  brand: brand || null,
  token: token || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { brand: newBrand, token: newToken } = action.payload;
      state.brand = newBrand;
      state.token = newToken;
      localStorage.setItem('brand', JSON.stringify(newBrand));
      localStorage.setItem('token', JSON.stringify(newToken));
    },
    logOut: (state) => {
      state.brand = null;
      state.token = null;
      localStorage.removeItem('brand');
      localStorage.removeItem('token');
      window.location.reload();
    },
    setBrandData: (state, action) => {
      state.brand = action.payload.brand;
      localStorage.setItem('brand', JSON.stringify(state.brand));
    },
  },
});

export const { setCredentials, logOut, setBrandData } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentBrand = (state) => state.auth.brand;
export const selectCurrentToken = (state) => state.auth.token;
