import React from 'react';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Heading,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { FaEuroSign } from 'react-icons/fa';

const StepCampaignBudget = ({ formData, setFormData, errors }) => {
  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <Box>
      <Heading textAlign="center" as="h3" size="lg" mb={4}>
        Presupuesto
      </Heading>

      <Box maxWidth="600px" m="0 auto">
        <Text mb={3}>
          ¡Último paso! Una vez pulses en "Crear briefing", revisaremos
          personalmente el briefing y, una vez aprobado, lo publicaremos en la
          plataforma. Desde ese momento empezarán a postular nuestros creadores
          UGC. Pasados 5 días laborales, podrás ver en tu panel vídeos de los
          creadores que han aplicado y su nivel y precio (incluyendo el fee de
          CRUWI).
        </Text>

        <Text mb={5}>
          En función de tu presupuesto, mostraremos el briefing a unos creadores
          u otros. Elige un presupuesto que incentive a los creadores a
          interesarse por tu oferta de colaboración. El presupuesto recomendado
          es de 100€.
        </Text>
      </Box>

      <Box m={'0 auto'} maxWidth="600px">
        {/* Link to document */}
        <FormControl isInvalid={errors.budget} mb={4}>
          <FormLabel htmlFor="budget">Budget</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaEuroSign />
            </InputLeftElement>
            <Input
              id="budget"
              type="number"
              min={50}
              placeholder="200€"
              value={formData.budget || ''}
              onChange={e => handleChange('budget', Number(e.target.value))}
            />
          </InputGroup>
          <FormErrorMessage>{errors.budget}</FormErrorMessage>
        </FormControl>
      </Box>
    </Box>
  );
};

StepCampaignBudget.validate = data => {
  const errors = {};
  if (data.budget === 0) {
    errors.budget = 'Añade un presupuesto válido. El mínimo es de 100€';
  }
  return errors;
};

export default StepCampaignBudget;
