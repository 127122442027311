import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useFlows } from '@frigade/react';

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Flex,
  ModalBody,
  Image,
  Button,
} from '@chakra-ui/react';

import ModalChallengeImageExplication from '../../../Assets/Images/imagen-como-funciona-retos.svg';
import ChallengeExampleImage from '../../../Assets/Images/modal-challenge-less-h.png';

const flowId = 'flow_QOD4zSjI39dCxlwB';

export default function ChallengeModal() {
  const navigate = useNavigate();

  const {
    getFlowData,
    getFlowStatus,
    isLoading,
    isFlowAvailableToUser,
    markFlowCompleted,
    getStepStatus,
    markStepCompleted,
  } = useFlows();

  if (isLoading) {
    return null;
  }

  const flow = getFlowData(flowId);
  const isActive = isFlowAvailableToUser(flowId);
  const isDone = getFlowStatus(flowId) === 'COMPLETED_FLOW';

  const handleCloseModalAndMarkAsCompleted = () => {
    markFlowCompleted(flowId);
  };

  const navigateTo = path => {
    handleCloseModalAndMarkAsCompleted();
    navigate(path);
  };

  // Decidimos si hay que mostrar o no el modal
  let isOpen = false;
  if (isActive && !isDone) {
    isOpen = true;
  }

  const markStepAsComplete = stepId => {
    markStepCompleted(flowId, stepId);
  };

  // Mostramos el "step 2" que sería el otro componente con el otro contenido del modal de cómo funciona (pensar en paso 1 y 2..)
  const isFirstStepCompleted =
    getStepStatus(flowId, flow.steps[0].id) === 'COMPLETED_STEP';

  let content = (
    <Box>
      <Text
        textAlign="left"
        lineHeight={1.3}
        p={5}
        pb={0}
        fontSize="22px"
        fontWeight="bold"
      >
        {flow && flow.title}{' '}
        <span style={{ fontStyle: 'italic' }}>{flow && flow.subtitle}</span>
      </Text>
      <Flex p={5} justifyContent="center" py={6}>
        <Box maxW="280px" mr={3} mt="14px">
          <Text mb={5} lineHeight={1.3} fontSize="18px" fontWeight="bold">
            Una campaña con cientos de microinfluencers solía requerir mucho
            trabajo. Nosotros lo hemos arreglado.
          </Text>
          <Text mb={2} lineHeight={1.3} fontSize="16px">
            Los retos de CRUWI permiten a las marcas colaborar al instante con
            docenas o cientos de nano y micro influencers a través de TikTok con
            el clic de un botón.
          </Text>
          <Text lineHeight={1.3} fontSize="16px">
            Empieza con un sencillo resumen creativo y un vídeo de ejemplo.
          </Text>
        </Box>
        <Box>
          <Image width="200px" src={ChallengeExampleImage} />
        </Box>
      </Flex>
      <Flex p={5}>
        <Button
          onClick={() => markStepAsComplete(flow.steps[0].id)}
          color="black"
          bg="#e2e8f0"
          width="100%"
          mr={5}
        >
          Ver cómo funciona
        </Button>
        <Button
          onClick={() => markStepAsComplete(flow.steps[0].id)}
          color="white"
          bg="black"
          _hover={{ bg: 'black', color: 'white' }}
          width="100%"
        >
          Empezar ahora
        </Button>
      </Flex>
    </Box>
  );

  if (isFirstStepCompleted) {
    content = (
      <Box p={8}>
        <Text fontSize="22px" fontWeight="bold" textAlign="center">
          {flow && flow.steps[1].title}
        </Text>
        <Image mt={8} src={ModalChallengeImageExplication} mb={5} />

        <Button
          onClick={() => navigateTo('/dashboard/challenges/new')}
          color="white"
          bg="black"
          _hover={{ bg: 'black', color: 'white' }}
          width="100%"
        >
          {flow && flow.steps[1].button}
        </Button>
      </Box>
    );
  }

  return (
    <Modal size="xl" closeOnOverlayClick={false} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={5}>{content}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
