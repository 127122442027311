import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';

import { ArrowDownIcon } from '@chakra-ui/icons';

export default function VideoDownloaderButton({ videoUrl, filename, isSmall, isFullWith }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = async event => {
    event.preventDefault();
    try {
      setIsDownloading(true);
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${filename}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object to release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading video:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Button
      width={isFullWith ? '100%': ''}
      leftIcon={<ArrowDownIcon />}
      size={isSmall ? 'xs' : 'md'}
      onClick={handleDownload}
      isLoading={isDownloading}
    >
      Descargar vídeo
    </Button>
  );
}
