import React from 'react';
import { Box, Icon, Flex, useClipboard, useToast } from '@chakra-ui/react';
import { FaRegClipboard } from 'react-icons/fa';

const CopyToClipboardButton = ({ value }) => {
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();
  
  const handleCopy = () => {
    onCopy();
    toast({
      title: 'Copiado!',
      description: `Applicant ID ${value} copied to clipboard.`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box cursor="pointer" mt="5px" className={hasCopied ? 'e' : 'a'}>
      <Flex fontSize="10px" alignItems="center" onClick={handleCopy}>
        AID: <u style={{marginLeft: '3px'}}>{value}</u> <Icon ml={1} as={FaRegClipboard} boxSize="12px" /> 
      </Flex>
    </Box>
  );
};

export default CopyToClipboardButton;
