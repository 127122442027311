import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './TextEditor.css';

export default function TextEditor({ value, onChange, placeholderContent }) {
  return (
    <ReactQuill
      style={{ border: '1px solid #e2e8f0', borderRadius: '6px', maxWidth: '100%' }}
      className="cruwi-editor"
      theme="snow"
      value={value}
      onChange={onChange}
      placeholder={placeholderContent}
    />
  );
}
