import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useFinishChallengeMutation } from '../../Redux/features/challenges/challengesApiSlice';

export default function FinishChallengeModal({ isOpen, onClose, challengeId }) {
  const [finish, { isLoading }] = useFinishChallengeMutation();
  const toast = useToast();

  const handleFinishButtonClicked = async () => {
    try {
      await finish({ challengeId }).unwrap();
      onClose();
      toast({
        title: 'Challenge has been concluded',
        status: 'success',
        isClosable: true,
        duration: 2000,
      });
    } catch (error) {
      onClose();
      toast({
        title: 'Challenge could not be concluded',
        status: 'error',
        isClosable: true,
        duration: 2000,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Finalizar reto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              ¿Estás seguro de que quieres finalizar este reto? <br /> Los creadores
              dejarán de participar en él.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <Button
              isLoading={isLoading}
              onClick={handleFinishButtonClicked}
              colorScheme="red"
            >
              Finalizar reto
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
