import React, { useState } from 'react';

import {
  Box,
  SimpleGrid,
  Button,
  Text,
  Heading,
  VStack,
  Input,
  Flex,
} from '@chakra-ui/react';

import { useBuyPlanMutation } from '../../../../../Redux/features/plans/plansApiSlice';

import isLocalhost from '../../../../../Helpers/isLocalhost';

// poner !isLocalhost() para probar producción en local
const plans = [
  {
    name: 'Start',
    price: '100€',
    features: ['15 videos by 15 different creators', '1 Spark code included', 'Brand setup',],
    stripeId: isLocalhost() ? 'prod_OrhsUk8xs2gaL1' : 'prod_Ori50zc5q7qb6g',
  },
  {
    name: 'Growth',
    price: '190€',
    features: [
      '30 videos by 30 different creators',
      '2 Spark code included',
      'Brand setup',
      'Chat support',
    ],
    stripeId: isLocalhost() ? 'prod_Org7DbAl983TgL' : 'prod_Ori8ZpkQZ4ctEV',
  },
  {
    name: 'Hyper-Growth',
    price: '290€',
    features: [
      '∞ unlimited videos',
      '3 Spark code included',
      'Brand setup',
      'Premium support',
      'Account manager',
    ],
    stripeId: isLocalhost() ? 'prod_OtJHwDA2y08A23' : 'prod_Ori99Pr2iVEgth',
  },
];

const QuantitySelector = ({
  value,
  onIncrement,
  onDecrement,
  handleProceed,
}) => {
  const freeChallenges = Math.floor(value / 4);
  const totalChallenges = value + freeChallenges;

  return (
    <Box>
      <Flex justifyContent="space-between" mt={4}>
        <Flex>
          <Button mr={1} onClick={onDecrement} disabled={value <= 1}>
            -
          </Button>
          <Input
            mr={1}
            value={value}
            readOnly
            style={{ textAlign: 'center', width: '50px' }}
          />
          <Button onClick={onIncrement}>+</Button>
        </Flex>
        <Button
          bg="black"
          color="white"
          _hover={{ bg: '#000', opacity: 0.75 }}
          ml={5}
          w="100%"
          onClick={handleProceed}
        >
          Buy
        </Button>
      </Flex>
      <Box p={2} borderRadius="4px" mt={2} bg="#ebd0ff">
        <Text fontSize="14px" fontWeight="bold">
          You will pay for {value} challenges.
        </Text>
        {freeChallenges > 0 && (
          <Text fontSize="14px" color="green.500">
            Get {freeChallenges} challenge(s) for free! Total challenges:{' '}
            {totalChallenges}.
          </Text>
        )}
      </Box>
    </Box>
  );
};

const PricingCard = ({ plan, selected, onClick }) => (
  <Box
    border="2px solid"
    borderColor={selected ? 'blue.500' : 'gray.200'}
    borderRadius="8px"
    p={5}
    _hover={{ bg: 'gray.50' }}
    cursor="pointer"
    onClick={onClick}
    boxShadow={selected ? '0 0 10px rgba(0,0,255,0.5)' : 'none'}
    minHeight="350px"
  >
    <VStack align="start" spacing={5}>
      <Heading size="lg">{plan.name}</Heading>
      <Text fontSize="2xl">
        {plan.price} <span style={{ fontSize: '16px' }}> / per challenge</span>
      </Text>
      <VStack align="start" spacing={2}>
        {plan.features.map(feature => (
          <Text key={feature}>✅ {feature}</Text>
        ))}
      </VStack>
    </VStack>
  </Box>
);

export default function PricingTable() {
  const [buyPlan] = useBuyPlanMutation();

  const [selectedPlan, setSelectedPlan] = useState(plans[1]); // Default to first plan
  const [quantity, setQuantity] = useState(1);

  const handlePlanSelect = plan => {
    setSelectedPlan(plan);
    setQuantity(1); // Reset quantity to 1 when a new plan is selected
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleProceed = async () => {
    try {
      if (selectedPlan) {
        const data = {
          products: {
            [selectedPlan['stripeId']]: quantity,
          },
          type: 'challenges'
        };
        const buyPlanResult = await buyPlan(data).unwrap();
        window.location.replace(buyPlanResult);
      } else {
        console.error('Please select a plan');
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <>
      <Box borderRadius={4} p={5} bg="#ebd0ff">
        <Text>
          <span style={{ fontWeight: 'bold' }}>Limited time offer: </span>
          buy 4 credits of any type and get the fifth one FREE
        </Text>
      </Box>
      <Box maxWidth="900px" mt={6} ml={3}>
        <Heading size="lg" mb={6}>
          Choose your credit
        </Heading>
        <Text mb={2}>
          {selectedPlan
            ? `You have selected: ${selectedPlan.name} credit, quantity: ${quantity}`
            : ''}
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {plans.map(plan => (
            <Box key={plan.name} height="100%">
              <PricingCard
                plan={plan}
                selected={selectedPlan?.name === plan.name}
                onClick={() => handlePlanSelect(plan)}
              />
              {selectedPlan?.name === plan.name && (
                <QuantitySelector
                  handleProceed={handleProceed}
                  value={quantity}
                  onIncrement={handleIncrement}
                  onDecrement={handleDecrement}
                />
              )}
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
}
