import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import UGCForm from '../../UGCForm/UGCForm';

export default function DashboardUGCNew() {
  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            Nuevo briefing
          </Text>
        </Flex>
      </Box>
      <Box p="5">
        <UGCForm />
      </Box>
    </>
  );
}
