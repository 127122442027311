import React from 'react';

import {
  Text,
  Stack,
  Box,
  Heading,
  RadioGroup,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';

// CustomRadio para el género
function CustomRadio(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor="#e2e8f0"
        borderRadius="lg"
        _checked={{
          bg: 'black',
          color: 'white',
          borderColor: 'black',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

// Custom Checkbox para las edades
const CustomCheckbox = ({ value, isChecked, onChange }) => {
  return (
    <Box
      as="button"
      onClick={() => onChange(value)}
      borderWidth="1px"
      borderColor={isChecked ? 'black' : '#e2e8f0'}
      borderRadius="lg"
      bg={isChecked ? 'black' : 'white'}
      color={isChecked ? 'white' : 'black'}
      boxShadow={isChecked ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : ''}
      p={4}
      m={2}
    >
      {value}
    </Box>
  );
};

// Vista 1
const StepCreator = ({ formData, setFormData, errors }) => {
  // SELECCIÓN DE GÉNERO REQUERIDO
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'creator',
    defaultValue: formData.creatorGenderRequired,
    onChange: newValue => {
      setFormData({ ...formData, creatorGenderRequired: newValue });
    },
  });
  const group = getRootProps();

  const handleCheckboxChange = value => {
    const isAlreadySelected = formData.ageRanges.includes(value);

    const newAgeRanges = isAlreadySelected
      ? formData.ageRanges.filter(ageRange => ageRange !== value)
      : [...formData.ageRanges, value];

    setFormData({ ...formData, ageRanges: newAgeRanges });
  };

  return (
    <Box>
      <Heading textAlign="center" as="h3" size="lg" mb={4}>
        Sobre el creador
      </Heading>

      {/* CONTENT CREATOR */}
      <Box m={'40px auto 0 auto'} maxWidth="900px">
        <Heading textAlign="center" as="h3" size="20px" mb={4}>
          Género
        </Heading>
        <RadioGroup display="flex" justifyContent="center" {...group}>
          <Stack direction="row" spacing={5}>
            {['Hombre', 'Mujer', 'Cualquiera / Otro'].map(val => (
              <CustomRadio key={val} {...getRadioProps({ value: val })}>
                {val}
              </CustomRadio>
            ))}
          </Stack>
        </RadioGroup>
      </Box>

      {/* EDADES */}
      <Box m={'40px auto 0 auto'} maxWidth="900px">
        <Heading textAlign="center" as="h3" size="20px" mb={4}>
          Edad
        </Heading>
        <Stack
          display="flex"
          justifyContent="center"
          spacing={[1, 5]}
          direction={['column', 'row']}
        >
          {['18-24', '25-31', '32-44', '45+', 'Cualquier edad'].map(range => (
            <CustomCheckbox
              key={range}
              value={range}
              isChecked={formData.ageRanges.includes(range)}
              onChange={handleCheckboxChange}
            />
          ))}
        </Stack>
      </Box>

      <Box m="10px 0 20px 0">
        {errors.creatorGenderRequired && (
          <Text color="red" textAlign="center" fontSize="14px">
            {errors.creatorGenderRequired}
          </Text>
        )}

        {errors.creatorAgeRequired && (
          <Text color="red" textAlign="center" fontSize="14px">
            {errors.creatorAgeRequired}
          </Text>
        )}
      </Box>
    </Box>
  );
};

StepCreator.validate = data => {
  const errors = {};
  if (data.creatorGenderRequired === '') {
    errors.creatorGenderRequired =
      'Tienes que seleccionar un género del creador';
  }

  if (data.ageRanges.length === 0) {
    errors.creatorAgeRequired = 'Selecciona uno o varios rangos de edades';
  }

  return errors;
};

export default StepCreator;
