import React, { useState } from 'react';

import {
  Box,
  Button,
  useToast,
  Flex,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from '@chakra-ui/react';

import { useCreateBriefingMutation } from '../../Redux/features/briefings/briefingsApiSlice';

// Vistas
import StepContent from './Steps/StepContent';
import StepCreator from './Steps/StepCreator';
import StepCampaignData from './Steps/StepCampaignData';
import StepCampaignBriefing from './Steps/StepCampaignBriefing';
import StepCampaignBudget from './Steps/StepCampaignBudget';
import StepCampaignSuccess from './Steps/StepCampaignSuccess';

// Pasos del briefing (3 bloques grandes subdivididos)
const stepperSteps = [
  { title: 'Tipo de vídeo' },
  { title: 'Perfil del creador' },
  { title: 'Datos del briefing' },
];

// Formulario completo UGC
const UGCForm = () => {
  // Redux
  const [createBriefing, { isLoading }] = useCreateBriefingMutation();

  // Step activo
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: stepperSteps.length,
  });

  // Step actual
  const [currentStep, setCurrentStep] = useState(0);

  // Datos del briefing
  const [formData, setFormData] = useState({
    selectedVideoNeeds: '',
    creatorGenderRequired: '',
    ageRanges: [],
    campaignName: '',
    productLink: '',
    productValue: 0,
    productCategories: [],
    campaignBenefits: '',
    budget: 0,
    briefingDocument: null,
  });

  // New state for the uploaded document
  const handleDocumentUpload = document => {
    setFormData({ ...formData, briefingDocument: document });
  };

  // Errores para mostrar
  const [errors, setErrors] = useState({});

  // Vistas del formulario
  const steps = [
    <StepContent formData={formData} setFormData={setFormData} />,
    <StepCreator formData={formData} setFormData={setFormData} />,
    <StepCampaignData formData={formData} setFormData={setFormData} />,
    <StepCampaignBriefing
      formData={formData}
      setFormData={setFormData}
      handleDocumentUpload={handleDocumentUpload}
    />,
    <StepCampaignBudget formData={formData} setFormData={setFormData} />,
    <StepCampaignSuccess />,
  ];

  // Notifications
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  // Función para validación de los campos de cualquier step
  const validateStep = () => {
    switch (currentStep) {
      case 0:
        return StepContent.validate(formData);
      case 1:
        return StepCreator.validate(formData);
      case 2:
        return StepCampaignData.validate(formData);
      case 3:
        return StepCampaignBriefing.validate(formData);
      case 4:
        return StepCampaignBudget.validate(formData);
      default:
        return {};
    }
  };

  // Ir al siguiente paso + validación de campos
  const nextStep = () => {
    const newErrors = validateStep();
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      if (currentStep < steps.length - 1) {
        if (currentStep === 0) {
          setActiveStep(1);
        }
        if (currentStep === 1) {
          setActiveStep(2);
        }

        if (currentStep === 3) {
          setActiveStep(3);
        }

        setCurrentStep(currentStep + 1);
      }
    }
  };

  // Ir al paso anterior + validación de campos
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Ir al paso anterior + validación de campos
  const sendData = async () => {
    if (formData.budget < 100) {
      setErrors({ budget: 'Minimum budget is 100€' });
      return;
    }

    // Enviamos todo al backend
    const newBriefingData = {
      selectedVideoNeeds: {
        [formData.selectedVideoNeeds]: 1,
      },

      creatorGender: formData.creatorGenderRequired,
      creatorAge: formData.ageRanges,

      title: formData.campaignName,

      productLink: formData.productLink,
      productValue: formData.productValue,
      productCategories: formData.productCategories,

      benefits: formData.campaignBenefits,
      toneAndStyle: formData.campaignToneAndStyle
        ? formData.campaignToneAndStyle
        : '',
      environment: formData.campaignEnvironment
        ? formData.campaignEnvironment
        : '',
      cta: formData.campaignCTA ? formData.campaignCTA : '',
      freeText: formData.productBriefing ? formData.productBriefing : '',

      budget: formData.budget,
    };

    const briefingFormData = new FormData();
    briefingFormData.append('data', JSON.stringify(newBriefingData));
    briefingFormData.append('document', formData.briefingDocument);

    try {
      const brandBriefingCreationResult = await createBriefing(
        briefingFormData
      ).unwrap();
      console.log(brandBriefingCreationResult);
      showToast('Briefing creado con éxito', 'success', 3000);
      // siguien paso ya es el success
      nextStep();
    } catch (error) {
      const message =
        error.originalStatus === 409
          ? 'Error creating the briefing'
          : 'Error creating the briefing';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Box m="50px auto 50px auto" maxWidth="800px">
        {currentStep !== 5 && (
          <Stepper
            color="#1d1d1f"
            flexWrap="wrap"
            width="100%"
            index={activeStep}
          >
            {stepperSteps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
      <Box>{React.cloneElement(steps[currentStep], { errors })}</Box>
      {currentStep !== 5 && (
        <Flex justifyContent="center" maxWidth="500px" m={'60px auto 0 auto'}>
          <Button
            variant="outline"
            onClick={prevStep}
            isDisabled={currentStep === 0}
            mr={3}
          >
            Ir al paso anterior
          </Button>
          <Button
            isLoading={isLoading}
            color="white"
            bg="black"
            _hover={{ bg: 'black', color: 'white' }}
            onClick={currentStep === 4 ? sendData : nextStep}
          >
            {currentStep === 4 ? 'Crear briefing' : 'Siguiente'}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default UGCForm;
