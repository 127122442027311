import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useFlows } from '@frigade/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Text,
  Image,
  Flex,
  Button,
} from '@chakra-ui/react';

import UGCModalGif from '../../../Assets/Images/ugc-modal-gif.gif';

const flowId = 'flow_cLvA7OQIgIRK2AUI';

export default function UGCModal() {
  const navigate = useNavigate();

  const {
    getFlowData,
    isLoading,
    markFlowCompleted,
    isFlowAvailableToUser,
    getFlowStatus,
  } = useFlows();

  if (isLoading) {
    return null;
  }

  const flow = getFlowData(flowId);
  const isActive = isFlowAvailableToUser(flowId);
  const isDone = getFlowStatus(flowId) === 'COMPLETED_FLOW';

  const handleCloseModalAndMarkAsCompleted = () => {
    markFlowCompleted(flowId);
  };

  const navigateTo = path => {
    handleCloseModalAndMarkAsCompleted();
    navigate(path);
  };

  // Decidimos si hay que mostrar o no el modal
  let isOpen = false;
  if (isActive && !isDone) {
    isOpen = true;
  }

  return (
    <Modal isCentered size="xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box p={[0, 0, 0, 5]}>
            <Text
              textAlign="center"
              fontSize="28px"
              lineHeight={1.3}
              mb={2}
              fontWeight="bold"
              py={5}
            >
              Encuentra tu creador UGC
            </Text>
            <Flex p={5} py={6}>
              <Box width="350px" mr={5}>
                <Text fontSize="22px" lineHeight={1.3} mb={2} fontWeight="bold">
                  {flow && flow.title}
                </Text>
                <Text
                  mb={5}
                  lineHeight={1.3}
                  fontSize="16px"
                  fontWeight="normal"
                >
                  Los anuncios que funcionan ya no parecen anuncios. Apóyate en
                  nuestros creadores de UGC para crear anuncios que emocionen,
                  enganchen y conviertan las visitas en ventas. Lanza un
                  briefing gratuito y contrata a los creadores que más te
                  gusten.
                </Text>
              </Box>
              <Box>
                <Image width="200px" borderRadius="8px" src={UGCModalGif} />
              </Box>
            </Flex>
            <Flex p={5}>
              <Button
                onClick={() => navigateTo('/dashboard/ugc/new')}
                color="white"
                bg="black"
                _hover={{ bg: 'black', color: 'white' }}
                width="100%"
              >
                Crear un briefing
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
