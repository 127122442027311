import React from 'react';

import {
  Box,
  Flex,
  Text,
  Progress,
  Button,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { FaHeart, FaComment, FaShare, FaPlay } from 'react-icons/fa';

import CustomLink from '../CustomLink/CustomLink';

import ChallengeOrMissionDetailsModal from '../ChallengeOrMissionDetailsModal/ChallengeOrMissionDetailsModal';

import formatNumbers from '../../Helpers/formatNumbers';
import formatCentsToEurs from '../../Helpers/formatCentsToEurs';
import { formatDate } from '../../Helpers/date.js';
import { CRUWI_API_URL } from '../../Redux/api/apiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../../Redux/features/auth/authSlice';

// Function to create a deep copy of an object
function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// Function to get top 3 objects with most viewTotalCount
function getTop3ByViewTotalCount(data) {
  // Create a deep copy of the data array
  const dataCopy = deepCopy(data);

  // Sort the copied array
  // test
  const sortedData = dataCopy
    .slice()
    .filter(
      item =>
        item.challenges.status === 'approved' ||
        item.challenges.status === 'claimed'
    )
    .sort(
      (a, b) =>
        b.challenges.video.viewTotalCount - a.challenges.video.viewTotalCount
    );

  // Slice the first 3 elements
  return sortedData.slice(0, 3);
}

export default function BrandChallengeItem({
  challengeData,
  brandData,
  isMission,
}) {
  const {
    isOpen: isOpenDetailsModal,
    onOpen: onOpenDetailsModal,
    onClose: onCloseDetailsModal,
  } = useDisclosure();

  const {
    _id,
    title,
    launchDate,
    userChallenges,
    budget,
    spentBudget,
    description,
    hashtags,
    mentions,
    requirements,
    reward,
    isFerran,
  } = challengeData;

  const hasPendingVideos =
    userChallenges.filter(
      userChallenge =>
        userChallenge.challenges.status === 'active' ||
        userChallenge.challenges.status === 'revision'
    ).length > 0;

  const budgetProgress = spentBudget / budget;

  // Vemos si es un reto de instagram o no
  const isInstagram = challengeData?.isInstagram;

  // Información del reto
  const challengeName = title;
  const challengeLaunchedDate = launchDate;
  const challengeTotalBudget = budget;
  const challengeBudgedSpentPercentage = isFerran
    ? (budget / budget) * 100
    : budgetProgress * 100;

  // Métricas
  const totalVideos = userChallenges.length;

  const totalLikes =
    userChallenges?.length > 0
      ? userChallenges.reduce((sum, item) => {
          const likeTotalCount = item.challenges?.video?.likeTotalCount ?? 0;
          return (
            sum + (typeof likeTotalCount === 'number' ? likeTotalCount : 0)
          );
        }, 0)
      : 0;

  const totalImpacts =
    userChallenges?.length > 0
      ? userChallenges.reduce((sum, item) => {
          const viewTotalCount = item.challenges?.video?.viewTotalCount ?? 0;
          return (
            sum + (typeof viewTotalCount === 'number' ? viewTotalCount : 0)
          );
        }, 0)
      : 0;

  const totalComments =
    userChallenges?.length > 0
      ? userChallenges.reduce((sum, item) => {
          const commentTotalCount =
            item.challenges?.video?.commentTotalCount ?? 0;
          return (
            sum +
            (typeof commentTotalCount === 'number' ? commentTotalCount : 0)
          );
        }, 0)
      : 0;

  const totalShares =
    userChallenges?.length > 0
      ? userChallenges.reduce((sum, item) => {
          const shareTotalCount = item.challenges?.video?.shareTotalCount ?? 0;
          return (
            sum + (typeof shareTotalCount === 'number' ? shareTotalCount : 0)
          );
        }, 0)
      : 0;

  const totalFollowersReached =
    userChallenges?.length > 0
      ? userChallenges.reduce((sum, item) => {
          let followerCount = isInstagram ? item.facebookInfo?.followersCount : item.tikTokInfo?.followerCount;
          const videoStatus = item.challenges?.status;
          const validStatuses = ['approved', 'claimed', 'declined'];
          if (validStatuses.includes(videoStatus)) {
            return (
              sum + (typeof followerCount === 'number' ? followerCount : 0)
            );
          }
          return sum;
        }, 0)
      : 0; // poner solo los followers de los creadores con vídeo en uno de los estados siguientes: approved, claimed, declined

  // Sección mejores vídeos
  const top3 = getTop3ByViewTotalCount(userChallenges);

  // LLeva al vídeo top 3
  const goToTikTokVideo = tikTokLink => {
    window.open(tikTokLink);
  };

  const token = useSelector(selectCurrentToken);
  const downloadParticipationList = async () => {
    try {
      const response = await fetch(
        `${CRUWI_API_URL}brands/challenges/${challengeData._id}/participations/export`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${challengeData._id}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object to release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading export:', error);
    }
  };

  return (
    <Box mb={5} border="1px solid #e2e8f0" borderRadius="8px">
      <Flex>
        <Flex
          borderLeftRadius="8px"
          borderBottomLeftRadius="8px"
          position="relative"
          height="400px"
          minW="300px"
          maxW="300px"
          flexDir="column"
          bgGradient="linear-gradient(180deg, #EBD0FF 1%, rgba(235, 208, 255, 0.8) 22.5%, rgba(235, 208, 255, 0.6) 48%, rgba(0, 0, 0, 0.5) 57.5%, #000000 100%);"
        >
          <Box
            position="absolute"
            left="20px"
            top="50px"
            height="80px"
            width="80px"
            borderRadius="50%"
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundImage={brandData?.data?.logoUrl}
          />

          <Box color="white" marginTop="auto">
            <Text fontSize="20px" fontWeight="bold" pt={5} pl={5} pr={5}>
              {challengeName}
            </Text>
            <Box p={5}>
              <Text mb="5px" fontWeight="bold" fontSize="14px">
                Presupuesto total
              </Text>
              <Progress
                mb="3px"
                bg="white"
                borderRadius="50px"
                value={challengeBudgedSpentPercentage}
                size="sm"
                sx={{
                  '& div': {
                    backgroundColor: '#EBD0FF',
                  },
                }}
              />
              <Text fontSize="14px" fontWeight="bold">
                {formatCentsToEurs(challengeTotalBudget)}{' '}
                <span style={{ fontSize: '10px', fontWeight: 'normal' }}>
                  (
                  {formatNumbers(Math.min(challengeBudgedSpentPercentage, 100))}
                  % del presupuesto invertido)
                </span>
              </Text>
            </Box>
          </Box>
        </Flex>
        <Flex flexDir="column" width="100%">
          <Box p={4}>
            <Text opacity={0.7} fontSize="14px">
              Fecha de lanzamiento: {formatDate(challengeLaunchedDate)}
            </Text>
          </Box>
          <Flex height="100%" justifyContent="space-between">
            <Flex>
              <Flex flexDir="column">
                <Flex
                  minW="150px"
                  justifyContent="start"
                  p={4}
                  flexDir="column"
                  fontWeight="bold"
                >
                  <Text mb={2} lineHeight={1} fontSize="14px">
                    Vídeos totales
                  </Text>
                  <Text lineHeight={1} fontWeight="bold" fontSize="24px">
                    {totalVideos}
                  </Text>
                </Flex>
                <Flex
                  minW="150px"
                  justifyContent="start"
                  p={4}
                  flexDir="column"
                  fontWeight="bold"
                >
                  <Text mb={2} lineHeight={1} fontSize="14px">
                    Impactos
                  </Text>
                  <Text lineHeight={1} fontWeight="bold" fontSize="24px">
                    {formatNumbers(totalImpacts)}
                  </Text>
                </Flex>
                <Flex
                  minW="150px"
                  justifyContent="start"
                  flexDir="column"
                  p={4}
                  fontWeight="bold"
                >
                  <Text mb={2} lineHeight={1} fontSize="14px">
                    Seguidores alcanzados
                  </Text>
                  <Text lineHeight={1} fontWeight="bold" fontSize="24px">
                    {formatNumbers(totalFollowersReached)}
                  </Text>
                </Flex>
              </Flex>

              <Flex flexDir="column">
                <Flex
                  minW="150px"
                  justifyContent="start"
                  flexDir="column"
                  p={4}
                  fontWeight="bold"
                >
                  <Text mb={2} lineHeight={1} fontSize="14px">
                    Me gustas
                  </Text>
                  <Text lineHeight={1} fontWeight="bold" fontSize="24px">
                    <Icon as={FaHeart} w={5} h={5} mr={2} />
                    {formatNumbers(totalLikes)}
                  </Text>
                </Flex>
                <Flex
                  minW="150px"
                  justifyContent="start"
                  flexDir="column"
                  p={4}
                  fontWeight="bold"
                >
                  <Text mb={2} lineHeight={1} fontSize="14px">
                    Comentarios
                  </Text>
                  <Text lineHeight={1} fontWeight="bold" fontSize="24px">
                    <Icon as={FaComment} w={5} h={5} mr={2} />
                    {formatNumbers(totalComments)}
                  </Text>
                </Flex>
                <Flex
                  minW="150px"
                  justifyContent="start"
                  flexDir="column"
                  p={4}
                  fontWeight="bold"
                >
                  <Text mb={2} lineHeight={1} fontSize="14px">
                    Compartidos
                  </Text>
                  <Text lineHeight={1} fontWeight="bold" fontSize="24px">
                    {' '}
                    <Icon as={FaShare} w={5} h={5} mr={2} />
                    {formatNumbers(totalShares)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Box minW="200px">
              <Text fontWeight="bold" fontSize="14px" pr={4} pb={4} pt={4}>
                Mejores vídeos
              </Text>

              <Flex flexDir="column">
                {top3 &&
                  top3.length > 0 &&
                  top3.map(t => (
                    <Flex
                      key={t._id}
                      cursor="pointer"
                      mb={4}
                      onClick={() =>
                        goToTikTokVideo(t?.challenges?.video?.shareUrl)
                      }
                    >
                      <Flex
                        height="45px"
                        width="45px"
                        backgroundPosition="center"
                        backgroundSize="contain"
                        backgroundRepeat="no-repeat"
                        backgroundImage={t?.tikTokInfo?.avatarUrl}
                        borderRadius="50%"
                        flexDir="column"
                        alignItems="center"
                        position="relative"
                        justifyContent="center"
                      >
                        <Box>
                          <Icon
                            textAlign="center"
                            display="block"
                            as={FaPlay}
                            color="#EBD0FF"
                          />
                        </Box>
                      </Flex>
                      <Flex ml={2} flexDir="column" justifyContent="flex-end">
                        <Text fontWeight="bold" fontSize="14px">
                          {t.tikTokUser}
                        </Text>
                        <Text>
                          {formatNumbers(t.challenges.video.viewTotalCount)}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            </Box>
          </Flex>
          <Flex p={5} flexDir="row" justifyContent="flex-end" marginTop="auto">
            {challengeData.status !== 'active' &&
              new Date(challengeData.finalizationDate) < new Date() && (
                <Button
                  onClick={downloadParticipationList}
                  variant="outline"
                  mr={2}
                >
                  Descargar lista de participantes
                </Button>
              )}

            <Button onClick={onOpenDetailsModal} variant="outline" mr={2}>
              Ver detalles {isMission ? 'de la misión' : 'del reto'}
            </Button>
            {hasPendingVideos ? (
              <CustomLink to={`${_id}`}>
                <Button
                  color="white"
                  bg="black"
                  _hover={{ bg: 'black', color: 'white' }}
                  rightIcon={<ArrowForwardIcon />}
                >
                  Revisar vídeos pendientes
                </Button>
              </CustomLink>
            ) : (
              <CustomLink to={`${_id}`}>
                <Button
                  color="white"
                  bg="black"
                  _hover={{ bg: 'black', color: 'white' }}
                  rightIcon={<ArrowForwardIcon />}
                >
                  Vídeos {isMission ? 'de la misión' : 'del reto'}
                </Button>
              </CustomLink>
            )}
          </Flex>
        </Flex>
      </Flex>
      <ChallengeOrMissionDetailsModal
        title={title}
        isOpen={isOpenDetailsModal}
        onClose={onCloseDetailsModal}
        description={description}
        hashtags={hashtags}
        mentions={mentions}
        requirements={requirements}
        reward={reward}
      />
    </Box>
  );
}
