import apiSlice from '../../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation({
      query: registerBrandData => ({
        url: 'brands',
        method: 'POST',
        body: registerBrandData,
      }),
      providesTags: ['BrandData'],
    }),
    login: builder.mutation({
      query: loginBrandData => ({
        url: 'brands/login',
        method: 'POST',
        body: loginBrandData,
      }),
      providesTags: ['BrandData'],
    }),
    registerBrandUser: builder.mutation({
      query: registerBrandUser => ({
        url: 'brands/register',
        method: 'POST',
        body: registerBrandUser,
      }),
      providesTags: ['BrandData'],
    }),
    update: builder.mutation({
      query: updateBrandData => ({
        url: 'brands',
        method: 'PATCH',
        body: updateBrandData,
      }),
      invalidatesTags: ['BrandData'],
    }),
    getUserData: builder.query({
      query: () => 'brands/users',
      providesTags: ['UserData'],
    }),
    updateUser: builder.mutation({
      query: (updateUserData) => ({
        url: 'brands/users',
        method: 'PATCH',
        body: updateUserData,
      }),
      invalidatesTags: ['UserData'],
    }),
    getBrandData: builder.query({
      query: () => 'brands',
      providesTags: ['BrandData'],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useRegisterBrandUserMutation,
  useUpdateMutation,
  useGetBrandDataQuery, useUpdateUserMutation, useGetUserDataQuery,
} = authApiSlice;
