import apiSlice from '../../api/apiSlice';

export const reportsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getReportById: builder.query({
      query: reportId => `admin/reports/${reportId}`,
      providesTags: ['Report'],
    }),
  }),
});

export const { useGetReportByIdQuery } = reportsApiSlice;
