import apiSlice from '../../api/apiSlice';

export const plansApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: () => 'brands/plans',
    }),
    buyPlan: builder.mutation({
      query: (planData) => ({
        url: 'brands/checkout',
        method: 'POST',
        body: planData,
      }),
      invalidatesTags: ['BrandData'],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useBuyPlanMutation,
} = plansApiSlice;
