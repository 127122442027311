import apiSlice from '../../api/apiSlice';

export const briefingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBriefing: builder.mutation({
      query: (briefingData) => ({
        url: 'brands/briefings',
        method: 'POST',
        body: briefingData,
      }),
      invalidatesTags: ['BrandData', 'Briefings'],
    }),
    getBrandBriefings: builder.query({
      query: () => 'brands/briefings',
      providesTags: ["Briefings"],
    }),
    selectApplicant: builder.mutation({
      query: ({ briefingId, applicantId }) => ({
        url: `brands/briefings/${briefingId}/applicants/${applicantId}/select`,
        method: 'POST',
      }),
      invalidatesTags: ['Briefings'],
    }),
  }),
});

export const {
  useCreateBriefingMutation,
  useGetBrandBriefingsQuery,
  useSelectApplicantMutation,
} = briefingsApiSlice;
