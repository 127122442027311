import React from 'react';

import {
  Box,
  Flex,
  Text,
  Card,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';

import UGCBanner from '../../Assets/Images/cruwi-faq-banner.png';

const cruwiFaqs = [
  {
    question: '¿Qué es CRUWI?',
    answer:
      'CRUWI es la mayor plataforma de nano y micro influencers de España. Conectamos marcas y creadores a través de una plataforma única. Permitimos a las marcas lanzar campañas con decenas o centenas de creadores que de otra forma no podrían. ',
  },
  {
    question: '¿Qué es el balance?',
    answer:
      'El balance es el presupuesto no utilizado en retos anteriores. Podrás solicitar su devolución o utilizarlo en próximo reto que crees.',
  },
];

const challengesFaq = [
  {
    question: '¿Qué es un reto?',
    answer:
      'Los retos son el formato que usamos en CRUWI para conectar a las marcas con los creadores. Las marcas lanzan los retos y los creadores los ven y participan. Básicamente el reto contiene una información a modo briefing de lo que una marca quiere que hagan los creadores.',
  },
  {
    question: '¿Cómo se paga un reto?',
    answer:
      'Una vez que hayas creado un reto satisfactoriamente, te llegará un email con la información del pago. Actualmente usamos transferencia bancaria para el pago de los retos y de los briefings de UGC. Toda la información necesaria para el pago te llegará en el email.',
  },
  {
    question: '¿Qué recompensas debo poner en un reto?',
    answer:
      'Desde CRUWI recomendamos poner una recompensa que incentive a los creadores a participar y realizar el reto. El mínimo es de 5€ de recompensa fija (por vídeo aprobado) y 1€ como recompensa variable (por cada 1.000 views en las primeras 48h). Cuanto mayor sea tu recompensa, mayor éxito tendrá tu campaña y más creadores participarán.',
  },
  {
    question: '¿Qué resultados voy a obtener con mi reto?',
    answer:
      'Puedes ver los resultados estimados en la calculadora que aparece en el paso 1 a la hora de crear un reto. ¡Anímate a probarla!',
  },
];

const ugcFaq = [
  {
    question: '¿Qué es un briefing?',
    answer:
      'Un briefing es un proyecto de colaboración con un creador concreto. En un briefing explicas qué tipo de vídeo quieres, cómo lo quieres y haces una explicación mucho más detallada (incliuso subiendo un PDF) de lo que quieres en un vídeo, que generalmente es usado para anuncios.',
  },
];

export default function DashboardFaq() {
  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            FAQ
          </Text>
        </Flex>
      </Box>
      <Box margin="0 auto 40px auto">
        <Box p="20px 20px 20px 20px">
          {/* Banner FAQ */}
          <Box maxW={800} margin="0 auto 20px auto">
            <Card
              borderRadius="8px"
              height={200}
              backgroundSize="cover"
              backgroundImage={UGCBanner}
              mb="20px"
            ></Card>
            {/* Dudas CRUWI */}
            <Box mb="40px">
              <Text fontWeight="bold" fontSize="18px" mb={2}>
                CRUWI
              </Text>
              <Accordion allowToggle>
                {cruwiFaqs.map((faq, index) => (
                  <AccordionItem
                    mb={2}
                    border="1px solid #e2e8f0"
                    borderRadius="8px"
                    key={index}
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          pt={1}
                          pb={1}
                          
                          flex="1"
                          textAlign="left"
                        >
                          {faq.question}
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
            {/* Dudas retos */}
            <Box mb="40px">
              <Text fontWeight="bold" fontSize="18px" mb={2}>
                Retos
              </Text>
              <Accordion allowToggle>
                {challengesFaq.map((faq, index) => (
                  <AccordionItem
                    mb={2}
                    border="1px solid #e2e8f0"
                    borderRadius="8px"
                    key={index}
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          pt={1}
                          pb={1}
                          flex="1"
                          textAlign="left"
                        >
                          {faq.question}
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
            {/* Dudas UGC */}
            <Box>
              <Text fontWeight="bold" fontSize="18px" mb={2}>
                UGC
              </Text>
              <Accordion allowToggle>
                {ugcFaq.map((faq, index) => (
                  <AccordionItem
                    mb={2}
                    border="1px solid #e2e8f0"
                    borderRadius="8px"
                    key={index}
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          pt={1}
                          pb={1}
                          flex="1"
                          textAlign="left"
                        >
                          {faq.question}
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
