import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from '@chakra-ui/react';

import PDFImage from '../../Assets/Images/pdf-file.png';

export default function ViewBriefingModal({ isOpen, onClose, briefingData }) {
  const {
    title,
    selectedVideoNeeds,
    creatorGender,
    creatorAge,
    productLink,
    productValue,
    productCategories,
    benefits = '',
    toneAndStyle = '',
    environment,
    cta,
    freeText,
    documentUrl,
    budget,
  } = briefingData;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center" flexDirection="row">
              <Text>Briefing: {title} </Text>
              <Text ml={2} fontSize="12px" color="black" opacity={0.4}>
                (read only data)
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0 20px 20px 20px">
            <Flex width="full" mb={3}>
              <Box
                flex="1"
                mr={3}
                border="1px solid #e2e8f0"
                p={3}
                borderRadius="6px"
              >
                <Text fontWeight="bold">Type of content</Text>
                <Text>{Object.keys(selectedVideoNeeds)}</Text>
              </Box>
              <Box
                flex="1"
                mr={3}
                border="1px solid #e2e8f0"
                p={3}
                borderRadius="6px"
              >
                <Text fontWeight="bold">Creator gender</Text>
                <Text>{creatorGender}</Text>
              </Box>
              <Box flex="1" border="1px solid #e2e8f0" p={3} borderRadius="6px">
                <Text fontWeight="bold">Creator age</Text>
                <Text>{creatorAge.join(' / ')}</Text>
              </Box>
            </Flex>
            <Flex flexWrap="wrap" width="full" mb={3}>
              <Box
                flex="1"
                mr={3}
                border="1px solid #e2e8f0"
                p={3}
                borderRadius="6px"
              >
                <Text fontWeight="bold">Campaign name</Text>
                <Text>{title}</Text>
              </Box>
              <Box
                flex="1"
                mr={3}
                border="1px solid #e2e8f0"
                p={3}
                borderRadius="6px"
              >
                <Text fontWeight="bold">Product link</Text>
                <a
                  style={{ textDecoration: 'underline', color: 'blue' }}
                  href={productLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {productLink}
                </a>
              </Box>
              <Box flex="1" border="1px solid #e2e8f0" p={3} borderRadius="6px">
                <Text fontWeight="bold">Product value</Text>
                <Text>{productValue}€</Text>
              </Box>
            </Flex>
            <Box
              mb={3}
              flex="1"
              border="1px solid #e2e8f0"
              p={3}
              borderRadius="6px"
            >
              <Text fontWeight="bold">Product category</Text>
              <Text>{productCategories.join(' / ')}</Text>
            </Box>
            {benefits && (
              <Box
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                borderRadius="6px"
              >
                <Accordion allowMultiple>
                  <AccordionItem border="none">
                    <AccordionButton border="none">
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontWeight="bold">Benefits</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{benefits}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
            {toneAndStyle && (
              <Box
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                borderRadius="6px"
              >
                <Accordion allowMultiple>
                  <AccordionItem border="none">
                    <AccordionButton border="none">
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontWeight="bold">Tone and style</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{toneAndStyle}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
            {environment && (
              <Box
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                borderRadius="6px"
              >
                <Accordion allowMultiple>
                  <AccordionItem border="none">
                    <AccordionButton border="none">
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontWeight="bold">Environment</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{environment}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
            {cta && (
              <Box
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                borderRadius="6px"
              >
                <Accordion allowMultiple>
                  <AccordionItem border="none">
                    <AccordionButton border="none">
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontWeight="bold">Call to action</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{cta}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
            {freeText && (
              <Box
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                borderRadius="6px"
              >
                <Accordion allowMultiple>
                  <AccordionItem border="none">
                    <AccordionButton border="none">
                      <Box as="span" flex="1" textAlign="left">
                        <Text fontWeight="bold">Free text briefing</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>{freeText}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
            {documentUrl && (
              <Box
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                borderRadius="6px"
                p={5}
              >
                <Flex>
                  <Image
                    height={'auto'}
                    width="25px"
                    src={PDFImage}
                    alt="Your pdf document link"
                  />
                  <a target="_blank" rel="noreferrer" href={documentUrl}>
                    Briefing document uploaded
                  </a>
                </Flex>
              </Box>
            )}
            {budget && (
              <Box
                bg="#e2e8f0"
                mb={3}
                flex="1"
                border="1px solid #e2e8f0"
                p={3}
                borderRadius="6px"
              >
                <Text fontWeight="bold">Budget</Text>
                <Text>{budget}€</Text>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
