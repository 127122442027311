import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Checkbox, Flex, Image, Text, useMediaQuery, useToast } from '@chakra-ui/react';

import ReactPlayer from 'react-player';

import isLocalhost from '../../../Helpers/isLocalhost';

import CopyToClipboardButton from '../../CopyToClipBoardButton/CopyToClipboardButton';

import { FaCompass, FaShoppingBag } from 'react-icons/fa';

import DefaultImageProfile from '../../../Assets/Images/default-profile-image.png';

import './YourStyleSheet.css';

import { useBuyPlanMutation } from '../../../Redux/features/plans/plansApiSlice';

// Planes UGC con sus respectivos IDs según el entorno
const ugcPlans = [
  {
    name: 'UGC Creator Bronze',
    price: '50',
    stripeId: isLocalhost() ? 'prod_P2uh0NsCSBuaaJ' : 'prod_P2ue4S9FffetRq',
  },
  {
    name: 'UGC Creator Silver',
    price: '100',
    stripeId: isLocalhost() ? 'prod_P33TNN7o0WUgCc' : 'prod_P2ueUtOfTEFw4a',
  },
  {
    name: 'UGC Creator Gold',
    price: '200',
    stripeId: isLocalhost() ? 'prod_P33UDqYmAbci7v' : 'prod_P2uegxVhH28INb',
  },
];

const BriefingApplicantSelector = ({ briefingId, applicants }) => {
  // Redux
  const [buyPlan] = useBuyPlanMutation();

  // Notifications
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Iniciamos el estado con los pre-elegidos de otra sesión o en reload, los guardamos hasta el pago
  const [selectedUserIds, setSelectedUserIds] = useState(() => {
    const savedData = localStorage.getItem(`selectedUserIds-${briefingId}`);
    return savedData ? JSON.parse(savedData) : [];
  });

  // Estado con todos los UGC que la marca quiere para el briefing (los elegidos realmente)
  const [finalSelectedApplicants, setFinalSelectedApplicants] = useState([]);
  const handleSelectApplicant = userId => {
    setFinalSelectedApplicants(prevFinalSelectedApplicants => {
      return prevFinalSelectedApplicants.includes(userId)
        ? prevFinalSelectedApplicants.filter(id => id !== userId)
        : [...prevFinalSelectedApplicants, userId];
    });
  };

  // Pantalla mostrada según clic en el menú..
  const [currentScreen, setCurrentScreen] = useState('discover');

  const videoContainerRef = useRef(null);
  const videoRefs = useRef([]);

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Modal creador pre seleccionado
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState('');

  // Gestión de vídeos cargados
  const [playedVideos, setPlayedVideos] = useState(new Set());
  const handlePlayVideo = videoIndex => {
    setPlayedVideos(
      prevPlayedVideos => new Set([...prevPlayedVideos, videoIndex])
    );
  };

  const handleVideoInView = () => {
    // Ensure the container ref is not null
    if (!videoContainerRef.current) return;

    const containerRect = videoContainerRef.current.getBoundingClientRect();

    videoRefs.current.forEach((ref, index) => {
      // Ensure the current ref is not null
      if (!ref) return;

      const videoRect = ref.getBoundingClientRect();
      if (
        videoRect.top >= containerRect.top &&
        videoRect.bottom <= containerRect.bottom
      ) {
        setCurrentVideoIndex(index);
      }
    });
  };

  // Formateamos y mostramos todos los "videos"
  const allVideos = applicants.flatMap(applicant =>
    applicant.videos.map(video => ({
      applicantId: applicant._id,
      userId: applicant.user._id,
      avatarUrl: applicant.user.tikTokInfo?.avatarUrl || DefaultImageProfile,
      creatorLevel: applicant.user.creatorLevel || 'bronze',
      videoUrl: video.url,
      name: adjustName(applicant.user.name),
    }))
  );

  // Cada vez que se pre-seleccione un candidato, lo guardamos
  useEffect(() => {
    localStorage.setItem(`selectedUserIds-${briefingId}`, JSON.stringify(selectedUserIds));
  }, [briefingId, selectedUserIds]);

  useEffect(() => {
    const handleScroll = () => {
      handleVideoInView();
    };

    const handleKeyPress = e => {
      if (e.keyCode === 40 || e.keyCode === 38) {
        e.preventDefault();
        let newVideoIndex = currentVideoIndex;
        if (e.keyCode === 40) {
          // Down arrow key
          newVideoIndex = Math.min(currentVideoIndex + 1, allVideos.length - 1);
        } else if (e.keyCode === 38) {
          // Up arrow key
          newVideoIndex = Math.max(currentVideoIndex - 1, 0);
        }
        setCurrentVideoIndex(newVideoIndex);
      }
    };

    const container = videoContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        window.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [currentVideoIndex, allVideos.length]);

  // Manea la pre-selección
  const handlePreSelect = (userId, userName) => {
    setSelectedUserIds(prevSelectedUserIds => {
      const updatedSelectedUserIds = prevSelectedUserIds.includes(userId)
        ? prevSelectedUserIds.filter(id => id !== userId)
        : [...prevSelectedUserIds, userId];

      if (!prevSelectedUserIds.includes(userId)) {
        setSelectedUserName(userName);
        setShowCustomModal(true);
      }

      return updatedSelectedUserIds;
    });
  };

  const closeModal = () => {
    setShowCustomModal(false);
  };

  const handleClearSelection = () => {
    setSelectedUserIds([]);
    localStorage.removeItem(`selectedUserIds-${briefingId}`);
    setFinalSelectedApplicants([]);
  };

  if (!applicants || applicants.length === 0) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="200px"
        textAlign="center"
      >
        <Text>No applicants to review yet :(</Text>
      </Flex>
    );
  }

  const handleGoToPayment = async () => {

    let bronzeAndSilverCreators = 0;
    let goldCreators = 0;

    if (applicants.length === 0) return;

    if (applicants.length > 0) {
      for (let index = 0; index < finalSelectedApplicants.length; index++) {
        const applidantId = finalSelectedApplicants[index];
        const applicant = allVideos.find(a => a.userId === applidantId);
        // si es bronze o plata, vale 100 fijo
        if (
          applicant.creatorLevel === 'bronze' ||
          applicant.creatorLevel === 'silver'
        ) {
          bronzeAndSilverCreators = bronzeAndSilverCreators + 1;
          // sino es oro y vale 120
        } else {
          goldCreators = goldCreators + 1;
        }
      }
    }

    let stripeProductObject = {};
    if (bronzeAndSilverCreators > 0) {
      // ponemos silver porque es 100 siempre..
      stripeProductObject[ugcPlans[1].stripeId] = bronzeAndSilverCreators;
    }
    if (goldCreators > 0) {
      // menos si es oro
      stripeProductObject[ugcPlans[2].stripeId] = goldCreators;
    }

    try {
      const paymentObject = {
        products: stripeProductObject,
        type: 'ugc',
        metadata: {
          briefingId: briefingId,
          applicantsIds: finalSelectedApplicants.join(', '),
        },
      };

      const buyPlanResult = await buyPlan(paymentObject).unwrap();
      handleClearSelection();
      window.location.replace(buyPlanResult);

    } catch (error) {
      showToast(
        'There was a problem selecting the creator. Contact us.',
        'error',
        3000
      );
    }
  };

  // Calculamos el checkout de la pantalla 2
  const calculateCheckout = applicants => {
    let subtotal = 0;
    if (applicants.length > 0) {
      for (let index = 0; index < applicants.length; index++) {
        const applidantId = applicants[index];
        const applicant = allVideos.find(a => a.userId === applidantId);
        if (
          applicant.creatorLevel === 'bronze' ||
          applicant.creatorLevel === 'silver'
        ) {
          subtotal = subtotal + 100;
        } else {
          subtotal = subtotal + 120;
        }
      }
      return {
        subtotal: Number(subtotal).toFixed(2),
        iva: Number(subtotal * 0.21).toFixed(2),
        total: Number(subtotal + 0.21 * subtotal).toFixed(2),
      };
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" w="100%" mt={5}>
      <Box
        maxW={isLargerThan768 ? '375px' : '100%'}
        h={isLargerThan768 ? '720px' : '100vh'}
        bg="black"
        borderRadius={isLargerThan768 ? '20px' : '0'}
        position="relative"
        className="iphone-frame"
      >
        <Box
          ref={videoContainerRef}
          overflowY="scroll"
          h={currentScreen === 'discover' ? 'calc(100% - 60px)' : '100%'}
          className="scroll-container"
          bg="black"
          borderTopRadius="20px"
          borderBottomRadius="20px"
        >
          {currentScreen === 'discover' &&
            allVideos.map((creator, index) => (
              <Box
                key={`${creator.userId}-${index}`}
                ref={el => (videoRefs.current[index] = el)}
                className="video-box"
              >
                <ReactPlayer
                  url={creator.videoUrl}
                  playing={
                    index === currentVideoIndex && playedVideos.has(index)
                  }
                  width="100%"
                  height="100%"
                  controls
                  light={!playedVideos.has(index)}
                  onClick={() => handlePlayVideo(index)}
                />
                <Box
                  position="absolute"
                  bottom="20px"
                  left="0"
                  right="0"
                  bg="transparent"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  width="min-content"
                  margin="0 auto"
                >
                  <Button
                    colorScheme="blue"
                    onClick={() =>
                      handlePreSelect(creator.userId, creator.name)
                    }
                    bg="rgb(230, 209, 252)"
                    color="black"
                    border="2px solid black"
                    _hover={{
                      border: '2px solid black',
                      bg: '#e2e8f0',
                    }}
                  >
                    Pre seleccionar
                  </Button>
                </Box>
                <Flex
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                  height="100px"
                  bg="transparent"
                  border="0px solid"
                  position="absolute"
                  bottom="120px"
                  right="15px"
                >
                  <Box textAlign="center" mb={2}>
                    <Image
                      border="1px solid white"
                      src={creator.avatarUrl}
                      borderRadius="full"
                      boxSize="40px"
                    />
                    <Text color="white" fontSize="10px">
                      {creator.name}
                    </Text>
                  </Box>

                  <Box textAlign="center">
                    <Text lineHeight={1} fontSize="30px">
                      {getBadgeColor(creator.creatorLevel)}
                    </Text>
                    <Text color="white" fontSize="10px">
                      {creator.creatorLevel}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}

          {currentScreen === 'selection' && (
            <Flex borderBottomRadius="20px" bg="black" p={2} h="100%">
              <Box position="relative" borderRadius="10px" w="100%" bg="white">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  minHeight="60px"
                  borderTopRadius="8px"
                  bg="white"
                  borderBottom="2px solid black"
                  flexDirection="column"
                >
                  <Text fontWeight="bold" fontSize="16px" color="black">
                    Tu equipo UGC
                  </Text>
                  <Text fontWeight="medium" fontSize="12px" color="black">
                    Elige los mejores candidatos para este briefing
                  </Text>
                </Flex>
                <Flex flexDirection="column">
                  {selectedUserIds.length === 0 && (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      height="300px"
                    >
                      <Text fontSize="14px" fontWeight="normal">
                        Debes seleccionar al menos a 1 creador
                      </Text>
                    </Flex>
                  )}
                  <Box height="400px" overflowY="auto">
                    {selectedUserIds.map((userId, index) => {
                      // get full user from id selected..
                      const applicant = allVideos.find(
                        a => a.userId === userId
                      );

                      return (
                        <Flex
                          key={userId}
                          align="center"
                          justifyContent="space-between"
                          p={3}
                          borderBottom="1px solid gray"
                          _hover={{
                            bg: '#e6d1fc',
                          }}
                          minHeight="80px"
                        >
                          <Flex alignItems="center">
                            <Box
                              width="40px"
                              borderRadius="50%"
                              height="40px"
                              marginRight="10px"
                              backgroundPosition="center"
                              backgroundSize="contain"
                              backgroundRepeat="no-repeat"
                              backgroundImage={DefaultImageProfile}
                            ></Box>
                            <Box>
                              <Text
                                fontWeight="bold"
                                fontSize="16px"
                                lineHeight={1}
                              >{`Creator ${applicant.name}`}</Text>
                              <Text fontSize="12px">{`1 vídeo ${getBadgeColor(
                                applicant.creatorLevel
                              )}`}</Text>
                              <CopyToClipboardButton
                                value={applicant.applicantId}
                              />
                            </Box>
                          </Flex>
                          <Flex alignItems="center">
                            <Text mr={3} fontSize="12px">
                              {getPriceOfCreator(applicant.creatorLevel)}€
                            </Text>
                            <Checkbox
                              size="lg"
                              onChange={() => handleSelectApplicant(userId)}
                            />
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Box>
                </Flex>
                {finalSelectedApplicants.length > 0 && (
                  <Flex
                    borderTop="2px solid black"
                    position="absolute"
                    bottom="50px"
                    width="100%"
                    flexDir="column"
                  >
                    <Box pt={3} width="100%">
                      <Flex py={1} px={3} justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="bold">
                          Subtotal
                        </Text>
                        <Text>
                          {calculateCheckout(finalSelectedApplicants).subtotal}€
                        </Text>
                      </Flex>
                      <Flex py={1} px={3} justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="bold">
                          IVA (21%)
                        </Text>
                        <Text>
                          {calculateCheckout(finalSelectedApplicants).iva}€
                        </Text>
                      </Flex>
                      <Flex py={1} px={3} justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="bold">
                          Total a pagar
                        </Text>
                        <Text fontWeight="bold">
                          {calculateCheckout(finalSelectedApplicants).total}€
                        </Text>
                      </Flex>
                    </Box>
                    <Flex p={3}>
                      <Button
                        w="100%"
                        variant="ghost"
                        onClick={handleClearSelection}
                        mr={5}
                        border="2px solid white"
                      >
                        Reiniciar
                      </Button>
                      <Button
                        w="100%"
                        onClick={handleGoToPayment}
                        bg="rgb(230, 209, 252)"
                        color="black"
                        border="2px solid black"
                      >
                        Ir al pago
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </Flex>
          )}
        </Box>

        {/* Footer menu tiktok style */}
        <Flex
          justifyContent="space-around"
          alignItems="center"
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          h="60px"
          bg="black"
          zIndex="10"
          className="footer-menu"
          borderBottomRadius="20px"
        >
          <Box
            onClick={() => setCurrentScreen('discover')}
            cursor="pointer"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FaCompass size="20px" color="white" />
            <Text color="white" fontSize="10px">
              Creadores y vídeos
            </Text>
          </Box>
          <Box
            onClick={() => setCurrentScreen('selection')}
            cursor="pointer"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <FaShoppingBag size="20px" color="white" />
            <Text color="white" fontSize="10px">
              Tu selección
            </Text>
          </Box>
        </Flex>

        {/* Custom Modal */}
        {showCustomModal && currentScreen !== 'selection' && (
          <Flex
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="200px" // Adjust width as needed
            mx="auto"
            p={4}
            bg="#ffffffe0"
            borderRadius="10px"
            flexDirection="column"
            zIndex="20"
            boxShadow="5px 5px 10px #00000052"
          >
            <Box>
              <Text textAlign="center" lineHeight={1.2} fontSize="14px" mb={1}>
                <strong>{selectedUserName}</strong> ha sido añadido a tu
                selección.
              </Text>
              <Text textAlign="center" lineHeight={1.2} fontSize="14px">
                ¿Quieres ver más candidatos?
              </Text>
            </Box>
            <Flex mt={5} flexDir="column">
              <Button
                color="black"
                border="1px solid black"
                size="sm"
                bg="rgb(230, 209, 252)"
                mb={3}
                onClick={() => {
                  closeModal();
                  setCurrentScreen('selection');
                }}
              >
                No, elijo este
              </Button>
              <Button
                size="xs"
                color="black"
                variant="link"
                onClick={() => {
                  closeModal();
                }}
              >
                Ver más candidatos
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

function adjustName(name) {
  if (name.length > 4) {
    return name.substring(0, 4);
  } else if (name.length < 4) {
    return name.padEnd(4, 'x');
  }
  return name;
}

const getBadgeColor = status => {
  switch (status) {
    case 'gold':
      return '🥇';
    case 'silver':
      return '🥈';
    case 'bronze':
      return '🥉';
    default:
      return '🥉';
  }
};

const getPriceOfCreator = status => {
  switch (status) {
    case 'gold':
      return 120;
    case 'silver':
      return 100;
    case 'bronze':
      return 100;
    default:
      return 100;
  }
};

export default BriefingApplicantSelector;
