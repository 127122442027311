import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Box,
  Heading,
  Input,
  Button,
  Image,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

import { setCredentials } from '../../Redux/features/auth/authSlice';
import { useLoginMutation } from '../../Redux/features/auth/authApiSlice';

export default function LoginForm() {
  // Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const [loginBrand, { isLoading }] = useLoginMutation();

  // Brand login data
  const [email, setEmail] = useState('');
  const handleChangeEmail = event => setEmail(event.target.value);

  const [password, setPassword] = useState('');
  const handleChangePassword = event => setPassword(event.target.value);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // Notifications
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  // Login form submit
  const handleSubmit = async e => {
    e.preventDefault();

    const brandData = {
      email: email.toLowerCase(),
      password,
    };

    try {
      const userLoginData = await loginBrand(brandData).unwrap();
      dispatch(setCredentials(userLoginData));
      navigate('/dashboard');
    } catch (error) {
      showToast('Las credenciales introducidas son incorrectas', 'error');
      console.log(error);
    }
  };

  return (
    <Box
      width="100%"
      height="100vh"
      position="relative"
      background="linear-gradient(105.32deg, #FFFFF2 0%, #EBD0FF 0.01%, #FFFFF2 16.67%)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box m="0 20px" width="100%" maxWidth="400px">
        <Image
          m="0 auto 10px auto"
          src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI.com"
        />
        <Box textAlign="center" mb="30px">
          <Heading
            fontWeight="medium"
            fontFamily="DM Sans', sans-serif"
            as="h3"
            fontSize="20px"
          >
            Bienvenido de nuevo
          </Heading>
        </Box>
        <Box
          boxShadow=" rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"
          borderRadius={6}
          padding={5}
          mb="30px"
        >
          <Input
            onChange={handleChangeEmail}
            value={email}
            color="#000"
            _hover={{ borderColor: '#000000' }}
            border="1px solid"
            mb="10px"
            placeholder="Email"
            size="md"
            inputMode="email"
          />
          <InputGroup>
            <Input
              pr="4.5rem"
              _hover={{ borderColor: '#000000' }}
              border="1px solid"
              mb="10px"
              placeholder="Contraseña"
              size="md"
              type={show ? 'text' : 'password'}
              onChange={handleChangePassword}
              value={password}
            />
            <InputRightElement width="4.5rem" mr="10px">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? 'Ocultar' : 'Mostrar'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            bg="#000000"
            _hover={{ borderColor: '#000000' }}
            width="100%"
            colorScheme="green"
          >
            Entrar
          </Button>
        </Box>
        <Text
          color="black"
          fontSize="14px"
          textAlign="center"
          fontFamily="DM Sans"
        >
          ¿No tienes cuenta?{' '}
          <CustomLink to="/register">
            <strong>Regístrate</strong>
          </CustomLink>
        </Text>
      </Box>
    </Box>
  );
}
