import {
  Box, Button, FormLabel, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Select,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useBuyPlanMutation } from '../Redux/features/plans/plansApiSlice';

const SPARK_CODE_RANGES = {
  7: 'prod_PrV7FJ8ZgNixYT',
  30: 'prod_QW8bGW3VPBCmyK',
  60: 'prod_QW8czB35MZSnHx',
  80: 'prod_QYhDVMyZpZQ8H2',
  365: 'prod_QW8c6DhWW7Laew',
};

const SPARK_CODE_PRICES = {
  7: 20,
  30: 40,
  60: 60,
  80: 80,
  365: 100,
};

export default function BuySparkCodeModal({ isOpen, onClose, videoId, challengeId }) {
  const [buyPlan] = useBuyPlanMutation();
  const ranges = Object.keys(SPARK_CODE_RANGES);
  const [selectedRange, setSelectedRange] = useState(30);

  const requestSparkCode = async () => {
    const selectedStripeProduct = SPARK_CODE_RANGES[selectedRange];
    const stripeProductObject = { [selectedStripeProduct]: 1 };

    const paymentObject = {
      products: stripeProductObject,
      type: 'sparkcode',
      metadata: {
        externalId: videoId,
        challengeId: challengeId,
      },
    };

    try {
      const buyPlanResult = await buyPlan(paymentObject).unwrap();
      window.location.replace(buyPlanResult);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Solicitar Spark Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text mb={2}>
              La solicitud del spark code tiene un coste adicional que depende de la duración del mismo. Del cual
              repartimos el 50% con el creador.
            </Text>
            <Text>
              Una vez realizado el pago, realizamos la gestión con el
              creador y recibirás el Code en menos de 48 horas.
            </Text>
          </Box>

          <FormLabel marginTop={3}>
            <Text marginRight={1}>Duración del Spark Code</Text>
            <Select
              size="sm"
              borderRadius="md"
              width="200px"
              value={selectedRange}
              onChange={(event) => setSelectedRange(event.target.value)}
            >
              {ranges.map(range => <option key={range} value={range}>{range} días
                ({SPARK_CODE_PRICES[range]}€)</option>)}
            </Select>
          </FormLabel>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            bg="black"
            color="white"
            _hover={{ bg: '#000', opacity: 0.75 }}
            onClick={requestSparkCode}
          >
            Pagar y solicitar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
