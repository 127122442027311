import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Box,
  Heading,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Checkbox,
  Text,
  useToast,
} from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

import { setCredentials } from '../../Redux/features/auth/authSlice';
import { useRegisterBrandUserMutation } from '../../Redux/features/auth/authApiSlice';

export default function InvitationForm() {
  // Router
  const navigate = useNavigate();

  // Params
  const [searchParams] = useSearchParams();
  const brandId = searchParams.get('bi');
  const brandName = searchParams.get('bn');
  const collaboratorEmail = searchParams.get('ce');

  console.log(brandId);

  // Redux
  const dispatch = useDispatch();
  const [registerBrandUser, { isLoading }] = useRegisterBrandUserMutation();

  // Collaborator data
  const [firstname, setFirstName] = useState('');
  const handleChangeFirstName = event => setFirstName(event.target.value);

  const [lastName, setLastName] = useState('');
  const handleChangeLastName = event => setLastName(event.target.value);

  const [email, setEmail] = useState(collaboratorEmail ?? '');
  const handleChangeEmail = event => setEmail(event.target.value);

  const [password, setPassword] = useState('');
  const handleChangePassword = event => setPassword(event.target.value);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [gdprAccepted, setGdprAccepted] = useState(false);

  // Notifications
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  // Reset all fields
  const resetAllFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setGdprAccepted(false);
    setPassword('');
  };

  // Registration form submit and analytics
  const handleSubmit = async e => {
    e.preventDefault();

    if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
      showToast('Email field contains errors', 'error');
      return;
    }

    if (password.length < 4) {
      showToast(
        'La contraseña es demasiado corta. Introduce al menos 4 caracteres',
        'error'
      );
      return;
    }

    const collaboratorData = {
      brandId: brandId,
      firstName: firstname.charAt(0).toUpperCase() + firstname.slice(1),
      lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      email: email.toLowerCase(),
      password,
    };

    try {
      const brandUserData = await registerBrandUser(collaboratorData).unwrap();
      console.log(brandUserData);
      resetAllFields();
      dispatch(setCredentials(brandUserData));
      navigate('/dashboard');
    } catch (error) {
      const message =
        error.originalStatus === 409
          ? 'Ya existe un usuario con este email'
          : 'Hubo un problema con el registro';
      showToast(message, 'error');
      console.log(error);
    }
  };

  return (
    <Box
      width="100%"
      height="100vh"
      position="relative"
      background="linear-gradient(105.32deg, #FFFFF2 0%, #EBD0FF 0.01%, #FFFFF2 16.67%)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box m="0 20px" width="100%" maxWidth="400px">
        <Box textAlign="center" mb="5px">
          <Text lineHeight={1.1} fontWeight="bold" fontSize="30px">
            Crea tu cuenta
          </Text>
        </Box>
        <Box textAlign="center" mb="30px">
          <Heading
            fontWeight="medium"
            fontFamily="DM Sans', sans-serif"
            as="h3"
            fontSize="20px"
          >
            y colabora con{' '}
            {brandName ? brandName : 'la marca que te envío el enlace'}
          </Heading>
        </Box>
        <Box
          boxShadow=" rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"
          borderRadius={6}
          padding={5}
          mb="30px"
        >
          <Input
            onChange={handleChangeFirstName}
            value={firstname}
            color="#000"
            _hover={{ borderColor: '#000000' }}
            border="1px solid"
            mb="10px"
            placeholder="Nombre"
            size="md"
            inputMode="text"
          />

          <Input
            onChange={handleChangeLastName}
            value={lastName}
            color="#000"
            _hover={{ borderColor: '#000000' }}
            border="1px solid"
            mb="10px"
            placeholder="Apellido"
            size="md"
            inputMode="text"
          />

          <Input
            onChange={handleChangeEmail}
            value={email}
            color="#000"
            _hover={{ borderColor: '#000000' }}
            border="1px solid"
            mb="10px"
            placeholder="Email"
            size="md"
            inputMode="email"
          />

          <InputGroup>
            <Input
              pr="4.5rem"
              _hover={{ borderColor: '#000000' }}
              border="1px solid"
              mb="10px"
              placeholder="Contraseña"
              size="md"
              type={show ? 'text' : 'password'}
              onChange={handleChangePassword}
              value={password}
            />
            <InputRightElement width="4.5rem" mr="10px">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Checkbox
            isChecked={gdprAccepted}
            onChange={e => setGdprAccepted(e.target.checked)}
            mb="20px"
          >
            Acepto la{' '}
            <a
              target="_blank"
              href="https://www.cruwi.com/privacy-policy"
              rel="noreferrer"
            >
              <u>Política de Privacidad</u>
            </a>
          </Checkbox>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            bg="#000000"
            _hover={{ borderColor: '#000000' }}
            width="100%"
            colorScheme="green"
          >
            Crear cuenta
          </Button>
        </Box>
        <Text
          color="black"
          fontSize="14px"
          textAlign="center"
          fontFamily="DM Sans"
        >
          ¿Ya tienes una cuenta?{' '}
          <CustomLink to="/login">
            <strong>Iniciar sesión</strong>
          </CustomLink>
        </Text>
      </Box>
    </Box>
  );
}
