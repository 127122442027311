import { Link as RouteLink, useResolvedPath, useMatch } from 'react-router-dom';
import './CustomLink.css';

export default function CustomLink({
  to, children, className,
}) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <RouteLink to={to} className={isActive ? `${className}  active-link` : className}>
      {children}
    </RouteLink>
  );
}
