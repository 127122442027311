import React from 'react';
import {
  Box,
  SimpleGrid,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

export default function EmptyOverview({ type, isMission }) {
  if (type === 'noChallengesButCredits') {
    return (
      <>
        <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="bold" fontSize="24px">
              Overview
            </Text>
          </Flex>
        </Box>
        <Box position="relative">
          <Box p={5}>
            <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Challenges Launched</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Videos</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Views</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Nº Creators</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            </SimpleGrid>
          </Box>

          <Box pb={5} px={5}>
            <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Comments</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Approved Videos</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Shares</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Likes</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            </SimpleGrid>
          </Box>

          <Box px={5}>
            <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Rewards</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>CPM</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            </SimpleGrid>
          </Box>

          {/* Overlay */}
          <Box
            top={0}
            bottom={0}
            right={0}
            left={0}
            position="absolute"
            height="100%"
            width="100%"
            bg="rgb(255, 255, 255, 0.6)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              boxShadow="0 0 10px rgba(0, 0, 0, 0.15)"
              border="1px solid #e2e8f0"
              bg="white"
              borderRadius={5}
              p={10}
              textAlign="center"
            >
              <Text fontSize="20px" fontWeight="bold">
                You have no challenges, but you do have credits
              </Text>
              <Text
                opacity={0.7}
                fontSize="14px"
                my={2}
                maxWidth="500px"
                lineHeight={1.1}
                textAlign="center"
              >
                Launch your first challenge and start seeing viral metrics
              </Text>
              <Flex justifyContent="center" mt={5} alignItems="center">
                <CustomLink to="/dashboard/faq">
                  <Button mr={5} color="black" variant="link">
                    Learn more
                  </Button>
                </CustomLink>
                <CustomLink to="/dashboard/challenges/new">
                  <Button
                    color="white"
                    bg="black"
                    _hover={{ bg: 'black', color: 'white' }}
                  >
                    Create challenge
                  </Button>
                </CustomLink>
              </Flex>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontWeight="bold" fontSize="24px">
              Overview
            </Text>
          </Flex>
        </Box>
        <Box position="relative">
          <Box p={5}>
            <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Challenges Launched</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Videos</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Views</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Nº Creators</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            </SimpleGrid>
          </Box>

          <Box pb={5} px={5}>
            <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Comments</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Approved Videos</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Shares</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Likes</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            </SimpleGrid>
          </Box>

          <Box px={5}>
            <SimpleGrid minChildWidth="120px" spacingX="20px" spacingY="20px">
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>Total Rewards</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
              <Card variant="outline">
                <CardBody>
                  <Stat>
                    <StatLabel>CPM</StatLabel>
                    <StatNumber>0</StatNumber>
                  </Stat>
                </CardBody>
              </Card>
            </SimpleGrid>
          </Box>

          {/* Overlay */}
          <Box
            top={0}
            bottom={0}
            right={0}
            left={0}
            position="absolute"
            height="100%"
            width="100%"
            bg="rgb(255, 255, 255, 0.6)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              boxShadow="0 0 10px rgba(0, 0, 0, 0.15)"
              border="1px solid #e2e8f0"
              bg="white"
              borderRadius={5}
              p={10}
              textAlign="center"
            >
              <Text fontSize="20px" fontWeight="bold">
                {isMission ? 'No tienes ninguna misión' : 'No tienes ningún reto'}
              </Text>
              <Text
                opacity={0.7}
                fontSize="14px"
                my={2}
                maxWidth="500px"
                lineHeight={1.1}
                textAlign="center"
              >
                Una vez que tengas {isMission ? 'una misión activa' : 'un reto activo'}, podrás ver aquí un resumen
                del rendimiento {isMission ? 'de la misión' : 'del reto'}.
              </Text>
              <Flex justifyContent="center" mt={5} alignItems="center">
                <CustomLink to="/dashboard/faq">
                  <Button mr={5} color="black" variant="link">
                    Saber más
                  </Button>
                </CustomLink>
                <CustomLink to={isMission ? "/dashboard/missions/new" : "/dashboard/challenges/new"}>
                  <Button
                    color="white"
                    bg="black"
                    _hover={{ bg: 'black', color: 'white' }}
                  >
                    Crear {isMission ? 'misión' : 'reto'}
                  </Button>
                </CustomLink>
              </Flex>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}
