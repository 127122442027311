import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';

import TikTokCard from '../../TikTokCard/TikTokCard';

import { useGetBrandChallengesAndVideosQuery } from '../../../Redux/features/challenges/challengesApiSlice';

import Paginator from '../../Paginator';
import FinishChallengeModal from '../../FinishChallengeModal/FinishChallengeModal';

const ELEMENTS_BY_PAGE = 10;

const getPageRange = page => {
  const lastElement = page * ELEMENTS_BY_PAGE;
  return [lastElement - ELEMENTS_BY_PAGE, lastElement];
};

export default function DashboardMission() {
  const { missionId } = useParams();

  // Cuando conecta el TikTok
  const [searchParams, setSearchParams] = useSearchParams();
  const sparkCodePaymentSuccess = Boolean(
    searchParams.get('sparkCodePaymentSuccess')
  );
  const sparkCodePaymentSuccessValue = searchParams.get(
    'sparkCodePaymentSuccess'
  );

  const { data: brandChallengesAndVideos, isLoading } =
    useGetBrandChallengesAndVideosQuery({
      isMission: true
    });

  const currentChallenge = brandChallengesAndVideos?.data.find(
    challenge => challenge._id === missionId
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const [page, setPage] = useState(1);

  const [isFinishingChallenge, setIsFinishingChallenge] = useState(false);
  const [sortOption, setSortOption] = useState('oldest');

  const sortChallenges = useCallback(
    userChallenges =>
      userChallenges?.sort((a, b) => {
        switch (sortOption) {
          case 'oldest':
            return (
              new Date(a.challenges.video.createTime) -
              new Date(b.challenges.video.createTime)
            );
          case 'mostRecent':
            return (
              new Date(b.challenges.video.createTime) -
              new Date(a.challenges.video.createTime)
            );
          case 'moreViews':
            return (
              b.challenges.video.viewTotalCount -
              a.challenges.video.viewTotalCount
            );
          case 'lessViews':
            return (
              a.challenges.video.viewTotalCount -
              b.challenges.video.viewTotalCount
            );
          case 'bestRated':
            return (
              b.challenges.feedback?.rating - a.challenges.feedback?.rating
            );
          case 'worstRated':
            return (
              a.challenges.feedback?.rating - b.challenges.feedback?.rating
            );
          default:
            return (
              new Date(b.challenges.video.createTime) -
              new Date(a.challenges.video.createTime)
            );
        }
      }),
    [sortOption]
  );

  const pendingChallenges = useMemo(
    () =>
      currentChallenge?.userChallenges.filter(
        userChallenge => userChallenge.challenges.status === 'revision'
      ),
    [currentChallenge?.userChallenges]
  );

  const approvedChallenges = useMemo(
    () =>
      sortChallenges(
        currentChallenge?.userChallenges.filter(
          userChallenge =>
            userChallenge.challenges.status === 'approved' ||
            userChallenge.challenges.status === 'claimed'
        )
      ),
    [currentChallenge?.userChallenges, sortChallenges]
  );

  const declinedChallenges = useMemo(
    () =>
      sortChallenges(
        currentChallenge?.userChallenges.filter(
          userChallenge => userChallenge.challenges.status === 'declined'
        )
      ),
    [currentChallenge?.userChallenges, sortChallenges]
  );

  const favouriteChallenges = useMemo(
    () =>
      sortChallenges(
        currentChallenge?.userChallenges.filter(
          userChallenge =>
            (userChallenge.challenges.status === 'approved' ||
              userChallenge.challenges.status === 'claimed') &&
            userChallenge.challenges.isFavourite === true
        )
      ),
    [currentChallenge?.userChallenges, sortChallenges]
  );

  // Modal of spark code
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (sparkCodePaymentSuccess) {
      setIsModalOpen(true);
    }
  }, [sparkCodePaymentSuccess]);

  const closeModal = () => {
    setIsModalOpen(false);
    setSearchParams([]);
  };

  if (isLoading) {
    return <p>Cargando información..</p>;
  }

  const [firstElement, lastElement] = getPageRange(page);

  const getTabChallengesLength = () => {
    if (selectedTab === 0) {
      return pendingChallenges.length;
    } else if (selectedTab === 1) {
      return approvedChallenges.length;
    } else if (selectedTab === 2) {
      return declinedChallenges.length;
    }
    return favouriteChallenges.length;
  };

  const onChangeTab = tabNumber => {
    setSelectedTab(tabNumber);
    setPage(1);
  };

  return (
    <>
      <Box bg="white" p="5" borderBottom="1px solid #e2e8f0">
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="24px">
            {currentChallenge.title}
          </Text>
          <Flex gap={'8px'} alignItems="center">
            <Text>Ordenar por</Text>
            <Select
              size="sm"
              borderRadius="md"
              width="200px"
              value={sortOption}
              onChange={e => {
                setSortOption(e.target.value);
              }}
            >
              <option value={'oldest'}>Más antiguos</option>
              <option value={'mostRecent'}>Más recientes</option>
              <option value={'moreViews'}>Más impactos</option>
              <option value={'lessViews'}>Menos impactos</option>
              <option value={'bestRated'}>Mejor valorados</option>
              <option value={'worstRated'}>Peor valorados</option>
            </Select>
          </Flex>
          {currentChallenge.isActive && (
            <Button
              onClick={() => setIsFinishingChallenge(true)}
              colorScheme="red"
              size="sm"
              ml={2}
            >
              Finalizar misión
            </Button>
          )}
        </Flex>
      </Box>

      <Box p="5">
        <Tabs onChange={onChangeTab} size="md" variant="enclosed">
          <TabList width="100%">
            <Flex justifyContent="space-between" width="100%">
              <Flex flexGrow={1}>
                <Tab>
                  TikToks pendientes de revisión ({pendingChallenges.length})
                </Tab>
                <Tab>TikToks aprobados ({approvedChallenges.length})</Tab>
                <Tab>TikToks denegados ({declinedChallenges.length})</Tab>
              </Flex>
              <Box>
                <Tab>☆ Favoritos ({favouriteChallenges.length})</Tab>
              </Box>
            </Flex>
          </TabList>
          {getTabChallengesLength() > 0 && (
            <Paginator
              currentPage={page}
              totalPages={Math.ceil(getTabChallengesLength() / 10)}
              onPageChange={selected => setPage(selected)}
            />
          )}
          <TabPanels maxWidth={500}>
            <TabPanel padding={0} paddingTop={5}>
              {pendingChallenges.length === 0 && (
                <Text>No hay vídeos pendientes de revisión</Text>
              )}
              {selectedTab === 0 &&
                pendingChallenges
                  .slice(firstElement, lastElement)
                  .map((userChallenge, index) => {
                    return (
                      <TikTokCard
                        key={userChallenge._id + index}
                        userChallenge={userChallenge}
                        showActionButtons={true}
                        isPending={true}
                        isApproved={false}
                      />
                    );
                  })}
            </TabPanel>

            <TabPanel padding={0} paddingTop={5}>
              {approvedChallenges.length === 0 && (
                <Text>No tienes vídeos aprobados aún</Text>
              )}
              {selectedTab === 1 &&
                approvedChallenges
                  .slice(firstElement, lastElement)
                  .map((userChallenge, index) => {
                    return (
                      <TikTokCard
                        key={userChallenge._id + index}
                        userChallenge={userChallenge}
                        isApproved={true}
                      />
                    );
                  })}
            </TabPanel>

            <TabPanel padding={0} paddingTop={5}>
              {declinedChallenges.length === 0 && (
                <Text>No tienes vídeos denegados</Text>
              )}
              {selectedTab === 2 &&
                declinedChallenges
                  .slice(firstElement, lastElement)
                  .map((userChallenge, index) => {
                    return (
                      <TikTokCard
                        key={userChallenge._id + index}
                        userChallenge={userChallenge}
                        isApproved={false}
                      />
                    );
                  })}
            </TabPanel>

            <TabPanel padding={0} paddingTop={5}>
              {favouriteChallenges.length === 0 && (
                <Text>No tienes vídeos favoritos</Text>
              )}
              {selectedTab === 3 &&
                favouriteChallenges
                  .slice(firstElement, lastElement)
                  .map((userChallenge, index) => {
                    return (
                      <TikTokCard
                        key={userChallenge._id + index}
                        userChallenge={userChallenge}
                        isApproved={true}
                      />
                    );
                  })}
            </TabPanel>
          </TabPanels>
          {getTabChallengesLength() > 0 && (
            <Paginator
              currentPage={page}
              totalPages={Math.ceil(getTabChallengesLength() / 10)}
              onPageChange={selected => setPage(selected)}
            />
          )}
        </Tabs>
      </Box>

      {/* Finalizar reto modal */}
      <FinishChallengeModal
        challengeId={missionId}
        isOpen={isFinishingChallenge}
        onClose={() => {
          setIsFinishingChallenge(false);
        }}
      />

      {/* Modal spark code comprado */}
      <Modal isCentered isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {sparkCodePaymentSuccessValue === 'true'
              ? 'Spark Code Solicitado'
              : 'Solicitud de Spark Code Cancelada'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {sparkCodePaymentSuccessValue === 'true' ? (
                <Text mb={2}>
                  La solicitud del spark code se ha procesado con éxito. Te lo
                  enviaremos por email en las próximas 48 horas.
                </Text>
              ) : (
                <Text mb={2}>
                  Hubo algún problema con el pago. Por favor, inténtalo de nuevo
                  o ponte en contacto con nosotros enviando un email a{' '}
                  <u>hello@cruwi.com</u>. Disculpa las molestias.
                </Text>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: '#000', opacity: 0.75 }}
              onClick={closeModal}
            >
              Entendido
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
