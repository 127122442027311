// Main imports
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

// Public pages
import RegisterPage from './Pages/RegisterPage';
import LoginPage from './Pages/LoginPage';
import CollaborationPage from './Pages/CollaborationPage';
import DashboardPage from './Pages/DashboardPage';

// HOC Routers
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import { GuestRoute } from './Components/GuestRoute/GuestRoute';

// Main protected pages
import DashboardOverview from './Components/Dashboard/DashboardOverview';

// Dashboard Challenges pages
import DashboardChallengesOverview from './Components/Dashboard/DashboardChallenges/DashboardChallengesOverview';
import DashboardChallengesAll from './Components/Dashboard/DashboardChallenges/DashboardChallengesAll';
import DashboardChallengesNew from './Components/Dashboard/DashboardChallenges/DashboardChallengesNew';
import DashboardChallenge from './Components/Dashboard/DashboardChallenges/DashboardChallenge';
import DashboardDashboardChallengesCredits from './Components/Dashboard/DashboardChallenges/DashboardCredits/DashboardCredits';

// Dashboard Missions pages
import DashboardMissionsOverview from './Components/Dashboard/DashboardMissions/DashboardMissionsOverview';
import DashboardMissionsAll from './Components/Dashboard/DashboardMissions/DashboardMissionsAll';
import DashboardMissionsNew from './Components/Dashboard/DashboardMissions/DashboardMissionsNew';
import DashboardMission from './Components/Dashboard/DashboardMissions/DashboardMission';

// Dashboard UGC pages
import DashboardUGC from './Components/Dashboard/DashboardUGC/DashboardUGC';
import DashboardUGCAbout from './Components/Dashboard/DashboardUGC/DashboardUGCAbout';
import DashboardUGCNew from './Components/Dashboard/DashboardUGC/DashboardUGCNew';
import DashboardUGCBriefing from './Components/Dashboard/DashboardUGC/DashboardUGCBriefing';
import DashboardUGCBriefingApplicant from './Components/Dashboard/DashboardUGC/DashboardUGCBriefingApplicant';

// Other pages
import DashboardSettings from './Components/Dashboard/DashboardSettings';
import DashboardFaq from './Components/Dashboard/DashboardFaq';

// Reports
import DashboardReport from './Components/Dashboard/DashboardReports/DashboardReport';

// Error pages
import ErrorPage from './Pages/ErrorPage';

// Main CSS
import './App.css';

// Routing
const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="register" />,
  },
  {
    path: '/register',
    element: (
      <GuestRoute>
        <RegisterPage />
      </GuestRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: (
      <GuestRoute>
        <LoginPage />
      </GuestRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/collaborator',
    element: (
      <GuestRoute>
        <CollaborationPage />
      </GuestRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardOverview />,
      },
      {
        path: '/dashboard/challenges/analytics',
        element: <DashboardChallengesOverview />,
      },
      {
        path: '/dashboard/challenges',
        element: <DashboardChallengesAll />,
      },
      {
        path: '/dashboard/challenges/new',
        element: <DashboardChallengesNew />,
      },
      {
        path: '/dashboard/challenges/:challengeId',
        element: <DashboardChallenge />,
      },
      {
        path: '/dashboard/challenges/credits',
        element: <DashboardDashboardChallengesCredits />,
      },
      {
        path: '/dashboard/missions/analytics',
        element: <DashboardMissionsOverview />,
      },
      {
        path: '/dashboard/missions',
        element: <DashboardMissionsAll />,
      },
      {
        path: '/dashboard/missions/new',
        element: <DashboardMissionsNew />,
      },
      {
        path: '/dashboard/missions/:missionId',
        element: <DashboardMission />,
      },
      {
        path: '/dashboard/ugc',
        element: <DashboardUGCAbout />,
      },
      {
        path: '/dashboard/ugc/briefings',
        element: <DashboardUGC />,
      },
      {
        path: '/dashboard/ugc/briefings/:briefingId',
        element: <DashboardUGCBriefing />,
      },
      {
        path: '/dashboard/ugc/briefings/:briefingId/collabs',
        element: <DashboardUGCBriefingApplicant />,
      },
      {
        path: '/dashboard/ugc/briefings/:briefingId/collabs/:collabId',
        element: <DashboardUGCBriefingApplicant />,
      },
      {
        path: '/dashboard/ugc/new',
        element: <DashboardUGCNew />,
      },
      {
        path: '/dashboard/settings',
        element: <DashboardSettings />,
      },
      {
        path: '/dashboard/faq',
        element: <DashboardFaq />,
      },
    ],
  },
  {
    path: '/reports/:reportId',
    element: <DashboardReport />,
    errorElement: <ErrorPage />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
