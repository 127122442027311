import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

import { FaInstagram } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';

export default function PlatformSelection({
  selectedPlatform,
  handlePlatformSelect,
}) {
  return (
    <Box>
      <Flex direction="column" gap={4}>
        <PlatformOption
          platform="TikTok"
          icon={<TikTokIcon />}
          isSelected={selectedPlatform === 'tiktok'}
          onSelect={() => handlePlatformSelect('tiktok')}
        />
        <PlatformOption
          platform="Instagram"
          icon={<InstagramIcon />}
          isSelected={selectedPlatform === 'instagram'}
          onSelect={() => handlePlatformSelect('instagram')}
        />
      </Flex>
    </Box>
  );
}

function PlatformOption({ platform, icon, isSelected, onSelect }) {
  return (
    <Flex
      alignItems="center"
      p={4}
      borderWidth={2}
      borderColor={isSelected ? 'black' : '#e2e8f0'}
      borderRadius="md"
      cursor="pointer"
      onClick={onSelect}
      _hover={{ borderColor: 'black' }}
    >
      <Box mr={4}>{icon}</Box>
      <Text fontSize="lg" fontWeight="medium">
        {platform}
      </Text>
    </Flex>
  );
}

// You'll need to create these icon components
function TikTokIcon() {
  return <SiTiktok fontSize={16} />;
}

function InstagramIcon() {
  return <FaInstagram fontSize={16} />;
}
