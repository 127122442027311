import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import { useUpdateUserMutation } from '../../Redux/features/auth/authApiSlice';
export default function AccountForm({ userData }) {
  // Redux
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  // Account data
  const [firstName, setFirstName] = useState(userData.firstName || '');
  const handleChangeFirstName = event => setFirstName(event.target.value);

  const [lastName, setLastName] = useState(userData.lastName || '');
  const handleChangeLastName = event => setLastName(event.target.value);

  const [phone, setPhone] = useState(userData.phone || '');
  const handleChangePhone = event => setPhone(event.target.value);

  const handleSubmitUpdate = async () => {
    const updateData = {
      firstName,
      lastName,
      phone,
    };

    try {
       await updateUser(updateData).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box maxWidth="500px">
        <FormControl mb={4}>
          <FormLabel mb={1}>Nombre</FormLabel>
          <Input
            onChange={handleChangeFirstName}
            value={firstName}
            name="firstName"
            placeholder="Firstname"
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel mb={1}>Apellido</FormLabel>
          <Input
            onChange={handleChangeLastName}
            value={lastName}
            name="lastName"
            placeholder="Lastname"
          />
        </FormControl>
        <FormControl mb={8}>
          <FormLabel mb={1}>Teléfono de contacto (móvil)</FormLabel>
          <Input
            onChange={handleChangePhone}
            value={phone}
            name="name"
            placeholder="Phone"
          />
        </FormControl>
        <Button
          isLoading={isLoading}
          onClick={handleSubmitUpdate}
          color="white"
          bg="black"
          _hover={{ bg: 'black', color: 'white' }}
          size="sm"
        >
          Guardar cambios
        </Button>
      </Box>
    </>
  );
}
