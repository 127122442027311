export default function calculateRelativeDateChallengeFinalization(isoDateString) {
  // Parse the string to create a Date object and add 1 month
  const targetDate = new Date(isoDateString);
  targetDate.setMonth(targetDate.getMonth() + 1);

  // Get current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const diffInMs = targetDate - currentDate;

  // Check if the date is in the past
  if (diffInMs < 0) {
    return 'Finalizado';
  }

  // Calculate the time difference in minutes, hours, and days
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  // Formulate human-readable relative date description
  if (diffInDays > 1) {
    return `en ${diffInDays} días`;
  }
  if (diffInDays === 1) {
    return 'mañana';
  }
  if (diffInHours > 1) {
    return `en ${diffInHours} horas`;
  }
  if (diffInHours === 1) {
    return 'en 1 hour';
  }
  if (diffInMinutes > 0) {
    return 'en menos de 1 hora';
  }
  return 'ahora';
}
