import React from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { Box, Text, Flex } from '@chakra-ui/react';

import BriefingApplicantSelector from './BriefingApplicantSelector';

export default function DashboardUGCBriefing() {
  const { briefingId } = useParams();
  const location = useLocation();

  const applicants = location.state?.applicants || [];
  const title = location.state?.title;

  return (
    <Box>
      <Box p={3} borderRadius="8px">
        <Flex flexWrap="wrap">
          {/* Selector de creadores */}
          <Box w={['100%', '100%', '70%', '500px', '800px']}>
            <Text fontSize="20px" fontWeight="bold" textAlign="center">
              Elige a los candidatos
            </Text>
            <Text fontSize="14px" fontWeight="normal" textAlign="center">
              para el briefing: <u>{title}</u>
            </Text>
            <BriefingApplicantSelector
              briefingId={briefingId}
              applicants={applicants}
            />
          </Box>

          {/* Instrucciones */}
          <Box w={['100%', '100%', '30%', '300px', '300px']}>
            <Text fontSize="20px" fontWeight="bold" textAlign="center">
              Cómo funciona
            </Text>
            <Text fontSize="14px" fontWeight="normal" textAlign="center">
              en 4 sencillos pasos
            </Text>
            <Box textAlign="left" maxWidth="300px" m="20px auto 20px auto">
              <span>
                1️⃣ Haz scroll como si estuvieras en TikTok para moverte entre
                vídeos.
              </span>
            </Box>
            <Box textAlign="left" maxWidth="300px" m="20px auto 20px auto">
              <span>
                2️⃣ Haz clic en el botón de play para reproducir/pausar el vídeo
                del creador.
              </span>
            </Box>

            <Box textAlign="left" maxWidth="300px" m="20px auto 20px auto">
              <span>
                <strong>TIP:</strong> ¡Puedes hacer doble clic en el vídeo para
                verlo en pantalla completa!
              </span>
            </Box>

            <Box textAlign="left" maxWidth="300px" m="0 auto 20px auto">
              <Text fontSize="16px">
                3️⃣ Cuando veas un vídeo de un creador que te guste, pulsa en el
                botón "Pre seleccionar". De esta forma se añadirá el creador a
                tus pre seleccionados.
              </Text>
            </Box>
            <Box textAlign="left" maxWidth="300px" m="0 auto">
              <Text fontSize="16px">
                4️⃣ Finalmente, en la pantalla de "Tu selección", deberás elegir
                aquellos que más te gusten pulsando en el cuadrado. Cuando hayas terminado, pulsa en "Ir al pago".
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
