import React from 'react';
import { Flex, Spinner } from "@chakra-ui/react";

const FullScreenLoader = () => {
  return (
    <Flex
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      justifyContent="center"
      alignItems="center"
      backgroundColor="whiteAlpha.800"
      zIndex="9999"
    >
      <Spinner size="xl" />
    </Flex>
  );
};

export default FullScreenLoader;
