import React from 'react';
import { useSelector } from 'react-redux';

// Frigade
import { FrigadeProvider } from '@frigade/react';

import { selectCurrentBrand } from '../../Redux/features/auth/authSlice';

export default function FrigadeCustomProvider({ children }) {
  // Obtenemos los datos de la marca
  const brand = useSelector(selectCurrentBrand);

  return (
    <FrigadeProvider
      publicApiKey="api_public_AA3kSTpXBYOEcfOLumeey5MVZRGDwUIvSfCk94qD8kz1iTQz2NT7A8nNWhFThbiE"
      userId={brand && brand._id}
    >
      {children}
    </FrigadeProvider>
  );
}
