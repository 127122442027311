import React from 'react';

import './Reason.css';

export default function Reason({ text, id, onCheck }) {

  const handleChange = (event) => {
    onCheck(text, event.target.checked);
  };

  return (
      <>
        <li>
          <input type="checkbox" id={id} value={text} onChange={handleChange} />
            <label htmlFor={id}>
              {text}
            </label>
        </li>
      </>
  )
}