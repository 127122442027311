import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useFlows } from '@frigade/react';

import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Flex,
  ModalBody,
  Image,
  Button,
} from '@chakra-ui/react';

import InfluencerIcon from '../../../Assets/Images/influencer.png';
import UGCIcon from '../../../Assets/Images/ugc-creator.png';
import CRUWIHeader from '../../../Assets/Images/welcome-modal-white-gradient.png';

const flowId = 'flow_nNy9KyPYO8DgerpB';

export default function WelcomeModal() {
  const navigate = useNavigate();

  const {
    getFlowData,
    getFlowStatus,
    isLoading,
    isFlowAvailableToUser,
    markFlowCompleted,
  } = useFlows();

  if (isLoading) {
    return null;
  }

  const flow = getFlowData(flowId);
  const isActive = isFlowAvailableToUser(flowId);
  const isDone = getFlowStatus(flowId) === 'COMPLETED_FLOW';

  const handleCloseModalAndMarkAsCompleted = () => {
    markFlowCompleted(flowId);
  };

  const navigateTo = path => {
    handleCloseModalAndMarkAsCompleted();
    navigate(path);
  };

  // Decidimos si hay que mostrar o no el modal
  let isOpen = false;
  if (isActive && !isDone) {
    isOpen = true;
  }

  return (
    <Modal size="xl" closeOnOverlayClick={false} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Box>
            <Image src={CRUWIHeader} />
          </Box>
          <Box p={12} marginTop="-100px" textAlign="center">
            <Text mb={2} fontSize="18px" fontWeight="bold">
              {flow && flow.title}
            </Text>
            <Text maxW="400px" m="0 auto" fontSize="14px" lineHeight={1.3}>
              {flow && flow.subtitle}
            </Text>
            <Flex py={6}>
              <Box
                w="100%"
                onClick={() => navigateTo('/dashboard/challenges')}
                mr={5}
                textAlign="center"
                border="1px solid #e2e8f0"
                _hover={{
                  border: '1px solid #e2e8f0',
                  cursor: 'pointer',
                  bg: '#fffff2',
                }}
                borderRadius="10px"
                p={3}
              >
                <Image
                  margin="0 auto 10px auto"
                  height="50px"
                  src={InfluencerIcon}
                />
                <Text lineHeight={1.3} fontSize="14px">
                  Empieza una campaña con más de 50 influencers
                </Text>
                <Button
                  my={2}
                  color="white"
                  bg="black"
                  _hover={{ bg: 'black', color: 'white' }}
                  size="xs"
                >
                  Empezar ahora
                </Button>
              </Box>
              <Box
                w="100%"
                onClick={() => navigateTo('/dashboard/ugc')}
                borderRadius="10px"
                textAlign="center"
                border="1px solid #e2e8f0"
                _hover={{
                  border: '1px solid #e2e8f0',
                  cursor: 'pointer',
                  bg: '#fffff2',
                }}
                p={3}
              >
                <Image margin="0 auto 10px auto" height="50px" src={UGCIcon} />
                <Text lineHeight={1.3} fontSize="14px">
                  Consigue los mejores vídeos para tus anuncios
                </Text>
                <Button
                  my={2}
                  color="white"
                  bg="black"
                  _hover={{ bg: 'black', color: 'white' }}
                  size="xs"
                >
                  Empezar ahora
                </Button>
              </Box>
            </Flex>
            <Button
              onClick={() => handleCloseModalAndMarkAsCompleted()}
              color="white"
              bg="black"
              _hover={{ bg: 'black', color: 'white' }}
              width="100%"
            >
              Entendido
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
