import apiSlice from '../../api/apiSlice';

export const dataApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBrandDataOverview: builder.query({
      query: ({ challengeIds }) => {
        const params = new URLSearchParams();
        challengeIds.forEach(element => {
          params.append('challengeIds', element);
        });
        return `brands/overview${
          challengeIds.length > 0 ? '?' : ''
        }${params.toString()}`;
      },
    }),
  }),
});

export const { useGetBrandDataOverviewQuery } = dataApiSlice;
